import React, { useState, useRef, useEffect } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../hooks";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { ConfirmationModal } from "../../../elements/v2/modals";

import {
  QuestionnairesContainer,
  ButtonsContainer,
  NewButton,
  QuestionnaireCard,
  QuestionnaireHeader,
  QuestionnaireTitleSection,
  QuestionnaireName,
  QuestionCount,
  QuestionnaireContent,
  QuestionsList,
  QuestionItem,
  MoreOptionsButton,
  MenuContainer,
  MenuItem,
  ExpandIcon,
  TopBar,
  QuestionsTitle,
} from "./QuestionnairesList.styled";

const QuestionnairesList = observer(() => {
  const { questionsStore, authStore } = useStore();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [expandedId, setExpandedId] = useState(null);
  const [menuOpen, setMenuOpen] = useState(null);
  const menuRef = useRef(null);

  useEffect(() => {
    if (authStore.selectedAccount?.id) {
      questionsStore.setCustomer(authStore.selectedAccount.id);
      questionsStore.fetchQuestionnaires();
    }
  }, [authStore.selectedAccount?.id, questionsStore]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleEdit = (index, e) => {
    e.stopPropagation();
    setMenuOpen(null);
    const selectedQuestionnaire = questionsStore.questionnaires[index];
    questionsStore.editQuestionnaire(selectedQuestionnaire);
    navigate("/v2/questions/create");
  };

  const handleDelete = (index) => {
    setSelectedIndex(index);
    setMenuOpen(null);
    setIsModalOpen(true);
  };

  const confirmDelete = () => {
    if (selectedIndex !== null) {
      const questionnaire = questionsStore.questionnaires[selectedIndex];
      if (questionnaire?.id && questionsStore.customer) {
        questionsStore.deleteQuestionnaire(questionnaire.id);
      }
    }
    setIsModalOpen(false);
    setSelectedIndex(null);
  };

  const handleCardClick = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  const toggleMenu = (e, index) => {
    e.stopPropagation();
    setMenuOpen(menuOpen === index ? null : index);
  };

  const handleNewQuestionnaire = () => {
    questionsStore.clearCurrentQuestionnaire();
    navigate("/v2/questions/create");
  };

  return (
    <div>
      <TopBar>
        <ButtonsContainer>
          <NewButton onClick={handleNewQuestionnaire}>New</NewButton>
        </ButtonsContainer>
      </TopBar>
      <QuestionnairesContainer>
        {questionsStore.questionnaires.map((questionnaire, index) => (
          <QuestionnaireCard key={index} onClick={() => handleCardClick(index)}>
            <QuestionnaireHeader>
              <QuestionnaireTitleSection>
                <QuestionnaireName>{questionnaire.name}</QuestionnaireName>
                <QuestionCount>
                  Number of questions: {questionnaire.questions.length} questions
                </QuestionCount>
              </QuestionnaireTitleSection>
              <ExpandIcon>
                {expandedId === index ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowRightIcon />
                )}
              </ExpandIcon>
              <div style={{ position: 'relative' }}>
                <MoreOptionsButton onClick={(e) => toggleMenu(e, index)}>
                  <MoreVertIcon />
                </MoreOptionsButton>
                {menuOpen === index && (
                  <MenuContainer ref={menuRef}>
                    <MenuItem onClick={(e) => handleEdit(index, e)}>
                      <EditIcon /> Edit
                    </MenuItem>
                    <MenuItem isDelete onClick={() => handleDelete(index)}>
                      <DeleteIcon /> Delete
                    </MenuItem>
                  </MenuContainer>
                )}
              </div>
            </QuestionnaireHeader>

            <QuestionnaireContent isExpanded={expandedId === index}>
              <QuestionsTitle>Questions</QuestionsTitle>
              <QuestionsList>
                {questionnaire.questions.map((question, qIndex) => (
                  <QuestionItem key={qIndex}>{question}</QuestionItem>
                ))}
              </QuestionsList>
            </QuestionnaireContent>
          </QuestionnaireCard>
        ))}

        <ConfirmationModal
          isOpen={isModalOpen}
          icon="warning"
          onClose={() => setIsModalOpen(false)}
          onConfirm={confirmDelete}
          title="Confirm Delete"
          message="Are you sure you want to delete this questionnaire?"
          displayCancel={true}
          onConfirmLabel="Delete"
          cancelLabel="Cancel"
        />
      </QuestionnairesContainer>
    </div>
  );
});

export default QuestionnairesList;
