import React, { useState, useRef, useEffect } from "react";
import { ImageSelectorButton, ImageSelectorWrapper, HiddenFileInput } from "./ImageSelector.styled";
import DefaultAvatar from "../../icon/assets/carbon_user_avatar_filled.svg";
import { SuccessfulModal } from "../../../elements";

const ImageSelector = ({ editAgent, customerId }) => {
  const [selectedImage, setSelectedImage] = useState(DefaultAvatar);
  const [previewImage, setPreviewImage] = useState(null);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (editAgent?.photo_url) {
      setSelectedImage(editAgent.photo_url);
    } else {
      setSelectedImage(DefaultAvatar);
    }
  }, [editAgent?.photo_url]);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (!["image/jpeg", "image/png"].includes(file.type)) {
      setError("Incorrect format.");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);

    try {
      setError(null);

      await editAgent.uploadAgentPhoto(file, customerId);

      if (editAgent?.photo_url) {
        setSelectedImage(editAgent.photo_url);
      }
    } catch (err) {
      setError("Error uploading image. Please try again.");
    }
  };

  const handleImageClick = () => {
    fileInputRef.current?.click();
  };

  const imageToShow = previewImage || selectedImage;

  return (
    <>
      <ImageSelectorWrapper>
        <ImageSelectorButton onClick={handleImageClick}>
          <img src={imageToShow} alt="Selected avatar" />
          <HiddenFileInput
            type="file"
            accept=".jpg,.jpeg,.png"
            ref={fileInputRef}
            onChange={handleImageChange}
          />
        </ImageSelectorButton>
      </ImageSelectorWrapper>

      <SuccessfulModal
        isOpen={!!error}
        title="Error"
        subtitle={error}
        onClose={() => setError(null)}
      />
    </>
  );
};

export default ImageSelector;
