import { Switch } from '@mui/material';
import { Action } from "./AgentActions.styled";

const AgentActions = ({ actionsValues, onSelectAction }) => {
    return (
        <>
            <Action>Send SMS<Switch checked={actionsValues.send_sms} onChange={() => onSelectAction('send_sms')} /></Action>
            <Action>Send Email<Switch checked={actionsValues.send_email} onChange={() => onSelectAction('send_email')} /></Action>
            <Action>Appointment Scheduling <Switch checked={actionsValues.appointments_schedule} onChange={() => onSelectAction('appointments_schedule')} /></Action>
            <Action>Inventory <Switch checked={actionsValues.inventory} onChange={() => onSelectAction('get_inventory')} /></Action>
        </>
    );
}

export default AgentActions;