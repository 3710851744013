import React from 'react';
import { ModalWrapper, ModalBox, CloseButtonWrapper, CloseButton, IconWrapper, HeaderWrapper, CheckMark, CheckIcon, Title, Message  } from './SuccessModal.styled';
import { ReactComponent as CheckIconBlue } from '../../icon/assets/check_modal.svg';


const Modal = ({ isOpen, onClose, title, message, className }) => {
  return (
    <ModalWrapper isOpen={isOpen} className={className}>
      <ModalBox>
        <CloseButtonWrapper>
          <CloseButton onClick={onClose}>✕</CloseButton>
        </CloseButtonWrapper>
        <HeaderWrapper>
          <IconWrapper>
            <CheckMark>
              <CheckIconBlue />
              </CheckMark>
          </IconWrapper>
          <Title>{title}</Title>
        </HeaderWrapper>
        <Message>{message}</Message>
      </ModalBox>
    </ModalWrapper>
  );
};

export default Modal;
