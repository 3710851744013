import styled, { createGlobalStyle } from "styled-components";

const ChatContainer = styled.div`
  width: 100%;
`;

const ChatHeader = styled.div`
  height: 6.375rem;
  background: ${(props) => props.theme.colors.activeBlue};
  border-radius: 8px 8px 0 0;
  padding: 24px 24px 24px 32px;
`;

const CustomGenericListStyle = createGlobalStyle`
  #root > div:nth-child(1){
    display: grid;
    grid-template-columns: 0px 1fr;
    grid-template-rows: 0 0px 1fr 50px;
    font-family: "Inter", sans-serif;
  }

	#root > div > div:nth-child(1){
			display: none;
	}
  
  .sc-egpspN.iKqVsp {
    display: none;
  }
  
  .sc-gKbtt.gQubQS {
    display: none;
  }
  
`;

const AgentInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 16px;

  & > div:first-child {
    position: relative;
  }
`;

const AgentLogo = styled.div`
  max-height: 48px;
  min-height: 48px;
  cursor: pointer;
`;

const AgentData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  gap: 8px;
`;

const AgentTitle = styled.div`
  font-size: 1.375rem;
  font-weight: 600;
  color: ${(props) => props.theme.colors.white};
  max-width: 12.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const AgentRole = styled.div`
  font-size: 1rem;
  font-weight: 400;
  color: ${(props) => props.theme.colors.white};
`;

const DropdownContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.625rem rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 12.5rem;
`;

const DropdownItem = styled.div`
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
`;

const ChatMessages = styled.div`
  max-height: 600px;
  overflow-y: auto;
  
  & > div:first-child{
    height: 515px;
    max-height: 515px;
    background-color: ${(props) => props.theme.colors.white}
  }
  
  & > div:first-child > div {
    width: 100%;
  }
`;

const ChatInputContainer = styled.div`
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 72px;
  background: ${(props) => props.theme.colors.primaryWhite};
  border-radius: 0 0 8px 8px;

  & > div {
    width: 100%;
  }

  & > div > div {
    height: ${(props) => props.resize ? "2.5rem" : "fit-content" };
    align-items: center;
    padding: 0 8px;
    max-height: 200px;
  }

  & > div > div > div {
    margin: 0;
  }

  & > div > div > div:last-child {
    background: ${(props) => props.theme.colors.activeBlue};
    width: 28px;
    height: 28px;
    border-radius: 8px;
  }
  
  & > div > div > div > label:nth-child(2), & > div > div > div > label:nth-child(3){
    display:flex;
  }
	
  &  > div > div > div:nth-child(3) > button {		  
	background: none !important;
  }
`;

export {
	ChatContainer,
	ChatHeader,
	CustomGenericListStyle,
	AgentInfoWrapper,
	AgentLogo,
	AgentData,
	AgentTitle,
	AgentRole,
	DropdownContainer,
	DropdownItem,
	ChatMessages,
	ChatInputContainer,
};
