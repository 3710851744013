import React, { useState, useEffect, useMemo } from 'react';
import {
	SideBar1,
	CollapseContainer,
	ListOfAgentContainer,
	AgentListContainer,
	AgentListCard,
	AgentAvatar,
	AgentData,
	AgentFavorites,
	AgentName,
	AgentWelcome,
	AgentDateTime,
	InputField,
	InputWrapper,
	SearchIcon,
	Sidebar1Header,
	AgentWrapper,
	AgentListMainWrapper,
	AgentListCollapsedHeader,
	SliceButton
} from './SidebarAgents.styled';
import Tabs from "../../../../elements/v2/Tabs/Tabs";
import { Icon } from "../../../../elements";
import { useStore } from "../../../../hooks";
import { observer } from "mobx-react";
import { Avatar } from '@mui/material';

const SidebarAgents = observer(React.forwardRef(({ selectedDefault, handleAgentSelect, ...props }, ref) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const { authStore } = useStore();
	const { agents } = authStore;

	useEffect(() => {
		const mobileMediaQuery = window.matchMedia('(max-width: 1024px)');
		const tabletMediaQuery = window.matchMedia('(max-width: 792px)');

		const handleMediaQueryChange = () => {
			const isMobileSize = mobileMediaQuery.matches;
			const isTabletSize = tabletMediaQuery.matches;

			setIsMobile(isMobileSize);
			setIsExpanded(!isMobileSize && !isTabletSize);
		};

		// Set initial values
		handleMediaQueryChange();

		// Add listeners for subsequent changes
		mobileMediaQuery.addListener(handleMediaQueryChange);
		tabletMediaQuery.addListener(handleMediaQueryChange);

		// Cleanup
		return () => {
			mobileMediaQuery.removeListener(handleMediaQueryChange);
			tabletMediaQuery.removeListener(handleMediaQueryChange);
		};
	}, []);

	const toggleSidebar = () => {
		setIsExpanded(!isExpanded);
	};

	const listSorted = useMemo(() =>
		//Creates a shallow copy of the agent list to avoid modifying the original array
		agents.slice().sort((a, b) => {
			//Convert agent names to uppercase for case-insensitive comparison
			const nameA = a.name.toUpperCase();
			const nameB = b.name.toUpperCase();
			//Compare names to sort alphabetically
			if (nameA < nameB) { return -1; }
			if (nameA > nameB) { return 1; }
			return 0;
			//Filters agents based on search term
		}).filter(option => (new RegExp(searchTerm, "i")).test(option.name)),
		[searchTerm, agents.length]) //Dependencies for the useMemo, runs when searchTerm or agent size changes

	const renderAgentList = (showDetails = true) => {
		return listSorted.map(option => (
			<AgentListCard className={"agentListCard"} onClick={() => handleAgentSelect(option.name, option.id)} key={Math.random()}>
				<AgentAvatar title={option.name} isExpanded={isExpanded} >
					{option.photo_url ?
						<Avatar src={option.photo_url} alt={`${option.id}`} sx={{ width: 32, height: 32 }} /> :
						<Icon name={"carbonUserAvatarFilled"} height={'32px'} width={'32px'} />

					}
				</AgentAvatar>
				{showDetails && (
					<>
						<AgentData>
							<AgentName title={option.name}>{option.name}</AgentName>
							<AgentWelcome>{/*"How are you today"*/}</AgentWelcome>
						</AgentData>

						<AgentFavorites>
							{
								/*
								* <Icon name={"pinOutlined"} height={'24px'} width={'24px !important'} />
								* <AgentDateTime>2 min ago</AgentDateTime>
								* */
							}
						</AgentFavorites>
					</>
				)}
			</AgentListCard>
		));
	};

	const tabs = [
		{
			label: 'Agents',
			content: (
				<ListOfAgentContainer className="list-agents">
					<AgentListContainer isExpanded={isExpanded}>
						{renderAgentList(true)}
					</AgentListContainer>
				</ListOfAgentContainer>
			)
		}
	];

	return (
		<>
			{isMobile && (
				<SliceButton onClick={toggleSidebar}>
					<Icon name={isExpanded ? "collapseSidebar" : "expandSidebar"} height={'20px'} width={'20px'} />
				</SliceButton>
			)}
			<SideBar1 id={"styledd"} ref={ref} {...props} isExpanded={isExpanded}>
				<CollapseContainer isExpanded={isExpanded} onClick={toggleSidebar}>
					{isExpanded ?
						<Sidebar1Header>
							<InputWrapper>
								<InputField type="text" value={searchTerm}
									onChange={e => setSearchTerm(e.target.value)}
									onClick={e => e.stopPropagation()}
									placeholder="Search" />
								<SearchIcon>
									<Icon name={"magnifier"} height={'40px'} width={'20px'}></Icon>
								</SearchIcon>
							</InputWrapper>
							<Icon name={"expandSidebar"} height={'20px'} width={'20px'} onClick={toggleSidebar} />
						</Sidebar1Header> :

						<AgentListMainWrapper>
							<AgentListCollapsedHeader>
								<Icon name={"collapseSidebar"} height={'20px'} width={'20px'} margin={"auto"} onClick={toggleSidebar} />
							</AgentListCollapsedHeader>
							<AgentListContainer id={'agent-list-container'}>
								{renderAgentList(false)}
							</AgentListContainer>
						</AgentListMainWrapper>
					}
				</CollapseContainer>
				<AgentWrapper>
					{isExpanded && (<Tabs tabs={tabs} />)}
					{isMobile && (
						<Tabs tabs={tabs} />
					)}
				</AgentWrapper>
			</SideBar1>
		</>
	);
}));

export default SidebarAgents;