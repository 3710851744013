import React from 'react';
import { observer } from "mobx-react";
import { useStore } from "../../../hooks";
import { RoundedButton, SuccessfulModal } from '../../../elements';
import { LoadingBox } from '../../../components/loading/Loading.styled';
import {
  PlaygroundTabs,
  ChatComponent,
  AgentTeamSelector
} from './components';
import {
  LoadngContainer,
  PageContainer,
  Column,
  VerticalLine,
  ContainerColumns,
  Actions,
  ActionsButtonWrapper,
  ChatToggleButton,
  MobileChatWrapper,
  MobileChatHeader,
  MobileChatCloseButton,
  MobileChatContent,
  DropdownMenu,
  DropdownItem,
  MenuButtonWrapper,
  MenuButton
} from './PlaygroundView.styled';
import AdmirationSign from '../../../elements/icon/assets/admiration_sign.svg';
import AgentCompletedModal from "../../NewAgentPage/components/AgentCompletedModal";
import {useCallback, useEffect, useState} from "react";
import {useSearchParams, useNavigate, createSearchParams} from "react-router-dom";
import { ConfirmationModal } from '../../../elements/v2/modals';
import { useMediaQuery } from 'react-responsive';
import ShareAgentModal from './components/ShareAgentModal/ShareAgentModal';

const PlaygroundView = observer(() => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { changeTitle, editAgent, agentStore, authStore, listUserStore } = useStore();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ agentID, setAgentID ] = useState(null);
  const [ onConfirmChangeAgent, setOnConfirmChangeAgent ] = useState(false);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 791 });
  const [currentAccount, setCurrentAccount] = useState(null);
  const [ showShareModal, setShowShareModal ] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState("")

  changeTitle('Playground');

  useEffect(() => {
    setIsLoading(true);
    const customerParam = searchParams.get('customer');
    const agentId = searchParams.get('agent');
    setSelectedAgent(agentId);
    const selectedCustomerId = authStore.selectedAccount.id;

    const handleAgentLogic = async (customer) => {
      editAgent.clear();

      if (!agentId || agentId === 'undefined') {
        navigate('/assistants');
      } else {
        agentStore.onChangeAccount(customer);
        agentStore.onSelectedAgentById(agentId);
        editAgent.getAgents(customer, agentId);
        editAgent.getAgentPrompter(customer);
        editAgent.onChangeAccount(customer);
        setIsLoading(false);
        setCurrentAccount(customer);
      }
    };
    
    const loadResources = async () => {
      try {
        listUserStore.onFetchUsers(selectedCustomerId);
        editAgent.fetchResources(selectedCustomerId, selectedAgent);
      } catch (error) {
        console.error('Error loading resources:', error);
      }
    }
    loadResources();
    
    if (!customerParam) {
      handleAgentLogic(selectedCustomerId);
    } else if (selectedCustomerId === customerParam) {
      handleAgentLogic(customerParam);
    } else {
      navigate('/v2/assistants');
    }
 
    
  }, [agentStore, editAgent, authStore, searchParams, navigate]);



  useEffect(() => {
    async function switchAccount(){
      setIsLoading(true)

      await editAgent.getAgents(authStore.selectedAccount.id);
      if(!editAgent.agents.length) return navigate('/v2/assistants')
      navigate({
        pathname: "/v2/assistants/playground",
        search: createSearchParams({
          agent: editAgent.agents[0]?.id
        }).toString(),
      });
    }

    if(currentAccount && currentAccount !== authStore.selectedAccount.id){
      switchAccount();
    }
  }, [authStore.selectedAccount])

  const onHandleDraft = () => {
    console.log('Draft');
  };

  const onHandlePublish = () => {
    editAgent.updateAgent();
  };

  const onChangeAgent = useCallback((agent) => {
    setAgentID(agent);
    setOnConfirmChangeAgent(true);
  }, [setAgentID, setOnConfirmChangeAgent]);

  const onCancelChangeAgentModal = useCallback(() => {
    setAgentID(null);
    setOnConfirmChangeAgent(false);
  }, [setAgentID, setOnConfirmChangeAgent]);

  const onConfirmChangeAgentModal = () => {
    setOnConfirmChangeAgent(false);
    navigate({
      pathname: "/v2/assistants/playground",
      search: createSearchParams({
        agent: agentID
      }).toString(),
    });
  }

  const toggleChat = () => {
    setIsChatVisible(!isChatVisible);
  };

  const handleCloseShareModal = () => {
    setShowShareModal(false);
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setShowDropdown(prev => !prev);
  };

  const handleShare = () => {
    setShowDropdown(false);
    setShowShareModal(true);
  };

  const handleDuplicate = () => {
    setShowDropdown(false);
  };

  const handleRemove = () => {
    setShowDropdown(false);
  };

  useEffect(() => {
    const handleClickOutside = () => {
      setShowDropdown(false);
    };

    if (showDropdown) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showDropdown]);

  return (
    <>
      {isLoading && <LoadngContainer><LoadingBox /></LoadngContainer>}
      {!isLoading && <PageContainer>
        <Actions>
          <AgentTeamSelector
            agentName={editAgent.name}
            status={true}
            company={editAgent.account}
            agents={editAgent.agents}
            onChange={onChangeAgent}
            agentImageUrl={editAgent?.photo_url}
          />
          <ActionsButtonWrapper>
            {!editAgent.isConcierge && (
              <>
                <MenuButtonWrapper>
                  <MenuButton
                    onClick={handleMenuClick}
                    type="button"
                  >
                    ⋮
                  </MenuButton>
                  {showDropdown && (
                    <DropdownMenu>
                      <DropdownItem onClick={handleShare}>Share</DropdownItem>
                      <DropdownItem onClick={handleDuplicate}>Duplicate</DropdownItem>
                      <DropdownItem onClick={handleRemove}>Remove</DropdownItem>
                    </DropdownMenu>
                  )}
                </MenuButtonWrapper>
                <RoundedButton
                  width={"81px"}
                  height={"40px"}
                  fontSize={"14px"}
                  onClick={onHandlePublish}
                  kind={"confirm"}
                  disabled={editAgent.isLoading}
                >
                  {editAgent.isLoading ? 'Loading...' : 'Update'}
                </RoundedButton>
              </>
            )}
          </ActionsButtonWrapper>
        </Actions>
        <ContainerColumns>
          <Column>
            <PlaygroundTabs toggleChat={toggleChat} isChatVisible={isChatVisible} />
          </Column>
          {!isMobile && (
            <>
              <VerticalLine />
              <Column>
                <ChatComponent store={editAgent} />
              </Column>
            </>
          )}
        </ContainerColumns>

        {isMobile && (
          <>
            <MobileChatWrapper isVisible={isChatVisible}>
              <MobileChatHeader>
                <MobileChatCloseButton onClick={toggleChat} />
              </MobileChatHeader>
              <MobileChatContent>
                <ChatComponent store={editAgent} />
              </MobileChatContent>
            </MobileChatWrapper>
          </>
        )}

        {editAgent.updatedAgentSuccessfully && <AgentCompletedModal description="Agent has been successfully edited." redirectToAssistants={false} agentid={editAgent.id} customerid={editAgent.account} displayFooter={false}/>}

        {editAgent.isError && <SuccessfulModal
          isOpen={editAgent.isError}
          onClose={() => editAgent.onClearError()}
          imageSrc={AdmirationSign}
          title="Oops!"
          subtitle="An error occurred."
          subtitle2="Please try again later."
          zIndex={true}
        />}

        <ConfirmationModal
          isOpen={onConfirmChangeAgent}
          title="Warning"
          message="Are you sure you want to discard the changes in this agent? Any changes made to your agent will be lost. Are you sure you want to proceed?"
          icon="deleteFilled"
          onClose={onCancelChangeAgentModal}
          onConfirm={onConfirmChangeAgentModal}
        />

        <ShareAgentModal
          isOpen={showShareModal}
          onClose={handleCloseShareModal}
          agentId={selectedAgent}
        />
      </PageContainer>}
    </>
  );
});

export default PlaygroundView;
