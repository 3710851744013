import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import {
  displayColumnDefOptions,
  muiPaginationProps,
  muiTableBodyRowProps,
  muiTableContainerProps,
  muiTableHeadCellProps,
  muiTablePaperProps,
} from "./CSVTable.style";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import StatusBadge from "../../StatusBadge";
import { useStore } from "../../../hooks";
import { getDensity } from "../../../utils/GetDensity/GetDensity";
import { useMediaQueries } from "../../../utils/mediaQueries";

const defaultValue = ({ cell }) => {
  const getValue = cell?.getValue()?.trim();
  return getValue?.length ? getValue : "-";
};

const cellBadge = ({ renderedCellValue }) => (
  <StatusBadge status={renderedCellValue.toLowerCase()}>
    {renderedCellValue}
  </StatusBadge>
)


const CSVTable = observer(({}) => {
  const { contactSegmentStore } = useStore();
  const [tableHeader, setTableHeader] = useState([]);
  const { isMobile, isTablet, isDesktop } = useMediaQueries;
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([{ id: "contact_name", desc: false }]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const density = useMemo(() => {
    getDensity(isMobile, isTablet, isDesktop);
  }, [isMobile, isTablet, isDesktop]);

  useEffect(() => {
    const newTableHeader = contactSegmentStore?.defaultSystemFields.map((header) => {
      const mapping = header?.value;
      const name = header?.name;
      return {
        accessorKey: mapping,
        header: name,
        Cell: mapping === "contact_status" ? cellBadge : defaultValue
      }
    })
    setTableHeader(newTableHeader);
  }, [contactSegmentStore?.dynamicColumns])

  const tableMainConfiguration = useMaterialReactTable({
    columns: tableHeader,
    data: contactSegmentStore?.dynamicParsedContactList,
    initialState: {
      sorting,
      density,
      columnOrder: tableHeader.map((col) => col.accessorKey)
    },
    state: {
      pagination,
      globalFilter,
      sorting,
      isLoading: contactSegmentStore.isLoading,
    },
    getRowId: (row) => row.id,
    renderTopToolbarCustomActions: () => null,
    muiTableBodyCellProps: ({ cell }) => ({
      sx: {
        "& .MuiCheckbox-root": {
          transform: "scale(0.6)",
        },
      },
    }),
    muiTableHeadCellProps,
    muiTablePaperProps,
    muiTableBodyRowProps,
    muiTableContainerProps,
    muiPaginationProps, 
    displayColumnDefOptions,
    enableColumnOrdering: false,
    manualPagination: true,
    manualSorting: true,
    manualGlobalFilter: true,
    enableRowSelection: false,
    enableSorting: false,
    enableColumnFilters: false,
    showGlobalFilter: false,
    enableHiding: true,
    enableColumnActions: false,
    enableColumnResizing: false,
    enableFullScreenToggle: true,
    enableDensityToggle: true,
    enableTopToolbar: false,
    positionToolbarAlertBanner: "none",
    positionActionsColumn: "last",
    paginationDisplayMode: "pages",
    onGlobalFilterChange: (filter) => {
      setGlobalFilter(filter);
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    },
    onPaginationChange: setPagination,
    onSortingChange: (sorting) => {
      setSorting(sorting);
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    },
    onPageSizeChange: (newPageSize) => {
      setPagination((prev) => ({
        ...prev,
        pageSize: newPageSize,
        pageIndex: 0,
      }));
    },
  });

  return <MaterialReactTable table={tableMainConfiguration} />;
});

export default CSVTable;
