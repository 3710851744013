import { observer } from "mobx-react";
import ChatBox from "./ChatBox";
import { AssistantWrapper, ChatInputWrapper } from "./Assistance.styled";
import { useStore } from "../../../../../hooks";
import { useState, useEffect, useRef } from "react";
import Spinner from "../../../../../components/common/Spinner";
import ChatInput from "../../../../../elements/input/ChatInput";
import UnifiedProfileTabs from "../UnifiedProfileTabs/UnifiedProfileTabs";
import TimelineBox from "./TimelineBox";
import AISuggestionsPopup from "../Cards/AISuggestionsPopup";

const Assistance = observer(() => {
  const {
    authStore,
    unifiedCustomerProfileStore,
    annUnifiedCustomerProfileStore,
    assistanceUCPStore,
    conversationsStore,
  } = useStore();
  
  const {runID, threadID} = assistanceUCPStore;

  const { selectedAccount } = authStore;
  const textAreaRef = useRef();
  const contactInfo = unifiedCustomerProfileStore.contactInfo[0] || {};
  const [activeTab, setActiveTab] = useState(assistanceUCPStore.selectedTab);

  const suggestionsRef = useRef(null);

  useEffect(() => {
    assistanceUCPStore.setSelectedTab(activeTab);
  }, [activeTab, assistanceUCPStore]);

  useEffect(() => {
    if (selectedAccount?.id) {
      assistanceUCPStore.fetchConciergeAgent(selectedAccount.id);
      conversationsStore.setCustomerId(selectedAccount.id);
    }
  }, [selectedAccount, assistanceUCPStore]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
        assistanceUCPStore.setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [assistanceUCPStore]);

  /**
   * Handle tab change event by setting the active tab state and
   * updating the selected tab in the assistance store.
   * @param {string} tabLabel - The label of the tab that was clicked.
   */
  const handleTabChange = (tabLabel) => {
    setActiveTab(tabLabel);
    assistanceUCPStore.setSelectedTab(tabLabel);
  };

  /**
   * Sends a message to the Concierge Chat Assistant.
   * @param {string} message The message to send.
   * @returns {Promise<void>}
   */
  const handleSendMessage = async (message) => {
    const isAnnUCP = authStore.selectedAccount.metadata.domain === "ANN";
    const ucpData = isAnnUCP
      ? annUnifiedCustomerProfileStore
      : unifiedCustomerProfileStore;

    await assistanceUCPStore.handleSendMessage(
      message,
      contactInfo,
      selectedAccount,
      isAnnUCP,
      ucpData,
    );
  };

  /**
   * Stops the agent response.
   */
  const handleStopAgentResponse = () => {
    conversationsStore.setRunId(runID);
    conversationsStore.setThreadId(threadID);
    if (assistanceUCPStore.agentResponseSubscription && (conversationsStore.runId && conversationsStore.threadId && conversationsStore.customerId)) {
      conversationsStore.stopResponse();
      assistanceUCPStore.agentResponseSubscription.unsubscribe();
      assistanceUCPStore.agentResponseSubscription = null;
      assistanceUCPStore.setAgentIsTyping(false);
      assistanceUCPStore.setShowSpinner(false);
      assistanceUCPStore.setIsSendMessageClicked(false);
      assistanceUCPStore.setShowStopButton(false);
    }
  };

  const handleSuggestionSelect = async (suggestion) => {
    assistanceUCPStore.setNewMessage(suggestion);
    assistanceUCPStore.setShowSuggestions(false);
    await handleSendMessage(suggestion);
  };

  const tabs = [
    {
      label: "Chat",
      content: (
        <AssistantWrapper>
          {assistanceUCPStore.showSpinner && (
            <Spinner className="spinner" />
          )}
          <ChatBox
            messages={assistanceUCPStore.conversationHistory}
            agentIsTyping={assistanceUCPStore.agentIsTyping}
            agentAvatar={assistanceUCPStore?.conciergeAgent?.photo_url}
          />
          <ChatInputWrapper id="chat-wrapper">
            {assistanceUCPStore.showSuggestions && (
              <div ref={suggestionsRef} className="suggestions-container">
                <AISuggestionsPopup
                  type={assistanceUCPStore.currentCardType}
                  onSelect={handleSuggestionSelect}
                  onClose={() => assistanceUCPStore.setShowSuggestions(false)}
                />
              </div>
            )}
            <ChatInput
              customerId={selectedAccount.id}
              textAreaRef={textAreaRef}
              onChange={(e) =>
                assistanceUCPStore.setNewMessage(e.target.value)
              }
              onKeyPress={(e, callback) => {
                if (
                  e.key === "Enter" &&
                  !e.shiftKey &&
                  !assistanceUCPStore.showSpinner
                ) {
                  e.preventDefault();
                  handleSendMessage();
                  callback("");
                }
              }}
              showSpinner={assistanceUCPStore.showSpinner}
              newMessage={assistanceUCPStore.newMessage}
              handleSendMessage={handleSendMessage}
              onFileUploaded={(attachment) =>
                assistanceUCPStore.setAttachment(attachment)
              }
              disableSend={
                assistanceUCPStore.isSendMessageClicked ||
                !assistanceUCPStore.conciergeAgent
              }
              onCleanThread={() => assistanceUCPStore.handleCleanThread()}
              stop={assistanceUCPStore.showStopButton}
              onStopClick={handleStopAgentResponse}
            />
          </ChatInputWrapper>
        </AssistantWrapper>
      ),
    },
    {
      label: "Timeline",
      content: (
        <AssistantWrapper>
          <TimelineBox/>
        </AssistantWrapper>
      ),
    },
  ];

  return (
    <UnifiedProfileTabs
      tabs={tabs}
      showBorder={false}
      activeTab={activeTab}
      onTabChange={handleTabChange}
    />
  );
});

export default Assistance;
