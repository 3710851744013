import { action, makeObservable, observable, flow, toJS } from "mobx";
import { API } from "aws-amplify";
import { putAgent, getAgent } from "../../../graphql/queries";

class NewAgentStore {
  name = '';
  title = '';
  tones = [];
  answerExtension = '';
  instructions = '';
  files = [];
  id = "";
  jobTitle = "";
  channel = "";
  scope = "";
  channels = [];
  actions = [];
  addConcierge = true;
  usePrompter = false;
  isLoading = false;
  isError = false;

  constructor() {
    makeObservable(this, {
      name: observable,
      title: observable,
      tones: observable,
      answerExtension: observable,
      instructions: observable,
      files: observable,
      id: observable,
      jobTitle: observable,
      addConcierge: observable,
      usePrompter: observable,
      channel: observable,
      actions: observable,
      isLoading: observable,
      channels: observable,
      scope: observable,
      isError: observable,
      onChangeName: action,
      onChangeScope: action,
      onChangeTones: action,
      onChangeAnswerExtension: action,
      onChangeInstructions: action,
      onChangeAccount: action,
      onChangeChannels: action,
      onChangeFiles: action,
      onChangeActions: action,
      onCompleteCreation: flow,
    });
  }

  onChangeName = (value) => {
    this.name = value;
  }

  onChangeScope = (value) => {
    this.scope = value;
  }

  onChangeTones = (values) => {
    if (values.length) this.tones = values;
  }

  onChangeAnswerExtension = (value) => {
    this.answerExtension = value;
  }

  onChangeInstructions = (value) => {
    this.instructions = value;
  }

  onChangeFiles = (value) => {
    this.files = [...this.files, value]
  }

  onChangeJobTitle = (value) => {
    this.jobTitle = value;
  }

  onChangeChannel = (value) => {
    this.channel = value;
  }

  onChangeActions = (value) => {
    this.actions = value;
  }

  onChangeChannels = (values) => {
    if (values.length) this.channels = values;
  }

  onChangeIncludeConcierge = (value) => {
    this.addConcierge = value === "yes";
  }

  onChangeUsePrompter = (value) => {
    this.usePrompter = value === "yes";
  }

  onChangeAccount = (value) => {
    this.account = value;
  }

  onValidateTextField = (field) => {
    return this[field].length;
  }

  onValidateObjectField = (field) => {
    return Object.keys(this[field]).length;
  }

  *onCompleteCreation(successCallback) {
    this.isLoading = true;
    const completeInstructions = this.instructions;

    try {
      const input = {
        channels: toJS(this.channels),
        customer_id: this.account,
        human_instructions: this.instructions,
        knowledge_base: JSON.parse(JSON.stringify(this.files)),
        name: this.name,
        prompt: completeInstructions,
        id: "",
        job_title: this.jobTitle,
        persona: JSON.stringify({ name: '' }),
        tone: toJS(this.tones),
        extended_answer: this.answerExtension.toLowerCase() === 'extensive',
        scope: this.scope,
        actions: this.actions,
        add_to_concierge: this.addConcierge,
        use_prompter: this.usePrompter,
        conversation_starters: [],
        domain: "",
        photo_url: "",
      };

      yield API.graphql({
        query: putAgent,
        variables: { input: input },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

    } catch (err) {
      this.isLoading = false;
      this.isError = true;
      successCallback(null);
      return;
    }

    try {
      const queryAgents = yield API.graphql({
        query: getAgent,
        variables: {
          input: {
            customer_id: this.account
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

      const retrievedAgents = JSON.parse(queryAgents.data?.getAgent?.body);
      const newlyCreatedAgent = retrievedAgents.filter(agent => agent.name === this.name);
      this.id = newlyCreatedAgent[0]?.id;
      
      successCallback(this.id ?? null);
    } catch (err) {
      this.isLoading = false;
      this.isError = true;
      successCallback(null);
    } finally {
      this.isLoading = false;
    }
  }

  onClearNewAgent() {
    this.name = '';
    this.title = '';
    this.tones = [];
    this.answerExtension = '';
    this.instructions = '';
    this.account = '';
    this.files = [];
    this.id = "";
    this.jobTitle = "";
    this.channel = "";
    this.scope = "";
    this.actions = [];
    this.channels = [];
    this.usePrompter = false;
    this.isLoading = false;
    this.isError = false;
  }
}

export default NewAgentStore;
