import React from 'react';
import { ReactComponent as AiSuggestionsIcon } from '../../../../../elements/icon/assets/ai_icon_suggestions.svg';
import {
  PopupContainer,
  SuggestionList,
  SuggestionItem,
  IconWrapper
} from './AISuggestionsPopup.styled';

const getContextualSuggestions = (type) => {
  switch (type) {
    case 'garage':
      return [
        'Notify them about the repair progress and provide an estimated completion timeframe.',
        'Update the client about the status to ensure clarity.',
        'Follow up to ensure satisfaction and discuss any recommendations for future maintenance.'
      ];
    case 'appointments':
      return [
        'Send a reminder to schedule their next maintenance appointment.',
        'Confirm the details and proceed with booking a time for a seamless experience.',
        'Schedule a recall appointment and inform the client on how to proceed.',
        'Offer to assist in booking a recall appointment for prompt service.',
        'Initiate a test drive booking conversation to engage further.'
      ];
    case 'alerts':
      return [
        'Send a reminder and mention any relevant upgrade options they could explore during their visit.',
        'Notify them about the repair progress and provide an estimated completion timeframe.',
        'Schedule a recall appointment and inform the client on how to proceed.',
        'Offer to assist in booking a recall appointment for prompt service.'
      ];
    default:
      return [
        'Give me a summary of this customer Alerts',
        'What is the replacement vehicle for this customers',
        'Suggest Emails that I can send to this Customer',
        'Draft an email for this Customer',
        'Draft an SMS for this customer'
      ];
  }
};

const AISuggestionsPopup = ({ type, onSelect, onClose }) => {
  const suggestions = getContextualSuggestions(type);

  const handleSelect = (suggestion) => {
    onSelect(suggestion);
    onClose();
  };

  return (
    <PopupContainer>
      <SuggestionList>
        {suggestions.map((suggestion, index) => (
          <SuggestionItem
            key={index}
            onClick={() => handleSelect(suggestion)}
          >
            <IconWrapper>
              <AiSuggestionsIcon width="20px" height="23px" />
            </IconWrapper>
            {suggestion}
          </SuggestionItem>
        ))}
      </SuggestionList>
    </PopupContainer>
  );
};

export default AISuggestionsPopup; 