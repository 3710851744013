import { action, flow, makeObservable, observable } from "mobx";
import { API } from "aws-amplify";
import { delInterview, getInterview, putInterview } from "../../graphql/queries";

class QuestionsStore {
  questions = [];
  questionnaires = [];
  currentQuestionnaire = null;
  customer = "";
  isLoading = false;
  isSaving = false;
  isError = false;
  errorMessage = "";

  constructor() {
    makeObservable(this, {
      questions: observable,
      questionnaires: observable,
      currentQuestionnaire: observable,
      customer: observable,
      isLoading: observable,
      isSaving: observable,
      isError: observable,
      errorMessage: observable,
      fetchQuestionnaires: flow,
      saveQuestionnaire: flow,
      deleteQuestionnaire: flow,
      resetErrorState: action,
      setCustomer: action,
      updateQuestion: action,
      addQuestion: action,
      removeQuestion: action,
      editQuestionnaire: action,
      setQuestions: action,
    });
    this.questions = [{ text: "" }];
  }

  setCustomer(customerId) {
    this.customer = customerId;
  }

  resetErrorState() {
    this.isError = false;
    this.errorMessage = "";
  }

  addQuestion() {
    this.questions.push({ text: "" });
  }

  removeQuestion(index) {
    this.resetErrorState();
    if (this.questions.length > 1) {
      this.questions.splice(index, 1);
    } else {
      this.isError = true;
      this.errorMessage = "At least one question is required.";
    }
  }

  updateQuestion(index, text) {
    if (index >= 0 && index < this.questions.length) {
      this.questions[index].text = text;
    } else {
      this.isError = true;
      this.errorMessage = "Invalid question index.";
    }
  }

  *fetchQuestionnaires(interview = "") {
    this.resetErrorState();
    this.isLoading = true;
    try {
      const response = yield API.graphql({
        query: getInterview,
        variables: { input: { customer: this.customer, interview } },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const bodyData = JSON.parse(response.data.getInterview.body);
  
      if (bodyData?.data?.items) {
        this.questionnaires = bodyData.data.items;
      } else {
        this.questionnaires = [];
      }
    } catch (error) {
      this.isError = true;
      this.errorMessage = "Failed to fetch questionnaires.";
    } finally {
      this.isLoading = false;
    }
  }
  
  *saveQuestionnaire(name, questions, id = "", description = "") {
    this.resetErrorState();
    this.isSaving = true;
  
    try {
      const input = {
        customer: this.customer,
        description: description || "",
        name: name.trim(),
        questions: questions.map((q) => q.trim()).filter(Boolean),
        id: id || "",
      };
  
      yield API.graphql({
        query: putInterview,
        variables: { input },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
  
      if (id) {
        const index = this.questionnaires.findIndex((q) => q.id === id);
        if (index !== -1) {
          this.questionnaires[index] = { ...this.questionnaires[index], name, questions, description };
        }
      } else {
        yield this.fetchQuestionnaires();
      }
    } catch (error) {
      this.isError = true;
      this.errorMessage = "Failed to save questionnaire. Please try again.";
    } finally {
      this.isSaving = false;
    }
  }

  *deleteQuestionnaire(interview) {
    this.resetErrorState();
    this.isLoading = true;
  
    try {
      yield API.graphql({
        query: delInterview,
        variables: { input: { customer: this.customer, interview } },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      this.questionnaires = this.questionnaires.filter((q) => q.id !== interview);
    } catch (error) {
      this.isError = true;
      this.errorMessage = "Failed to delete questionnaire.";
    } finally {
      this.isLoading = false;
    }
  }
  
  editQuestionnaire(questionnaire) {
    this.resetErrorState();
  
    if (!questionnaire) {
      this.isError = true;
      this.errorMessage = "Invalid questionnaire data.";
      return;
    }
  
    this.currentQuestionnaire = questionnaire;
  
    this.questions = Array.isArray(questionnaire.questions)
      ? questionnaire.questions.map((q) => ({
          text: typeof q === "string" ? q : q.text || "",
        }))
      : [{ text: "" }];
  }
  
  clearCurrentQuestionnaire() {
    this.currentQuestionnaire = null;
    this.questions = [{ text: "" }];
  }
  
  setQuestions(questions) {
    this.questions = Array.isArray(questions)
      ? questions.map((q) => ({
          text: typeof q === "string" ? q : q.text || "",
        }))
      : [{ text: "" }];
  }
  
  
    
}

export default QuestionsStore;
