import React, { useEffect, useRef, useState } from "react";
import {
  ChatWindowComponentPaper,
  MessageContainer,
  ChatWindowAvatar,
} from "../styles/ChatWindowComponent.styled";
import Message from "./Message";
import AnnAvatar from "../../../images/AnnAvatar.svg";
import { MessageWrapper } from "../styles/ChatWindowComponent.styled";
import ShineThinking from "../../../elements/ShineThinking";
import { toJS } from "mobx";

const ChatWindowComponent = ({
  messages,
  bottomRef,
  className,
  displayButtons = true,
  width,
  height,
  agentIsTyping,
  boxShadow,
  showImage,
  selectedAgent,
}) => {
  // Filter out consecutive messages of the same type, keeping only the last received message
  let filteredMessages = [];
  let lastReceivedIndex = -1;
  const chatWindowRef = useRef(null);
  const [agentImg, setAgentImg] = useState(AnnAvatar)

  useEffect(() => {
    const agent = toJS(selectedAgent)
    if (agent?.photo_url) {
      setAgentImg(agent.photo_url)
    }
  }, [selectedAgent])

  useEffect(() => {
    const handleLinkClick = (event) => {
      const link = event.target.closest("a"); // Encuentra el enlace más cercano
      if (link && link.tagName === "A" && link.target !== "_blank") {
        event.preventDefault();
        window.open(link.href, "_blank");
        event.stopPropagation();
      }
    };

    const chatWindow = chatWindowRef.current;
    if (chatWindow) {
      chatWindow.addEventListener("click", handleLinkClick);
    }

    return () => {
      if (chatWindow) {
        chatWindow.removeEventListener("click", handleLinkClick);
      }
    };
  }, []);

  messages.forEach((message, index) => {
    if (message.type !== "received") {
      filteredMessages.push(message);
    } else {
      // Check if this received message is consecutive with the previous one
      if (index === 0 || messages[index - 1].type !== "received") {
        lastReceivedIndex = filteredMessages.length;
        filteredMessages.push(message);
      } else {
        filteredMessages[lastReceivedIndex] = message;
      }
    }
  });

  return (
    <ChatWindowComponentPaper
      ref={chatWindowRef}
      elevation={3}
      className={className}
      $height={height}
      boxShadow={boxShadow}
    >
      {showImage && !messages.length && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <img alt="Concierge Agent" />
        </div>
      )}

      {filteredMessages.map((item, index) => (
        <Message
          key={index}
          messageItem={item}
          displayButtons={displayButtons}
          width={width}
          selectedAgent={selectedAgent}
        />
      ))}

      {agentIsTyping && messages.type !== "sent" && (
        <MessageContainer width={width}>
          <ChatWindowAvatar src={agentImg} alt="Agent Avatar" />
          <MessageWrapper flex>
            <ShineThinking />
          </MessageWrapper>
        </MessageContainer>
      )}

      <div ref={bottomRef} />
    </ChatWindowComponentPaper>
  );
};

export default ChatWindowComponent;
