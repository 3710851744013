import React, { useEffect, useState, useMemo, useRef } from "react";
import {
    MaterialReactTable,
    useMaterialReactTable,
} from "material-react-table";

import {
    Header,
    Title,
    AddButton,
    IconContainer,
    ButtonsContainer,
    DocumentCell
} from "./KnowledgeBaseTable.styled";
import { IconButton, RoundedButton } from "../..";
import DetailModal from "./components/DetailModal";
import { format } from "date-fns";
import { observer } from "mobx-react";
import CircleAddIcon from '../../icon/assets/circle_add_icon.svg';
import ConfirmationDialog from "../../../components/common/CommonConfirmationDialog";
import { useStore } from "../../../hooks";
import { Icon } from "@mui/material";
import { AttachFile, Check } from "@mui/icons-material";

const KnowledgeBaseTable = observer(({ fileNames = [], vaultName = "", ownerName = "", onFilesChange, onError, customer = "", fileRoute = "", editAgentStore, extensions = {}, showAddButton = true, showDeleteIcon = true, dataTestId }) => {
    const {authStore} = useStore()
    const [listItems, setListItems] = useState([]);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const fileInputRef = useRef(null);
    const isInitialized = useRef(false);

    useEffect(() => {
        if (!isInitialized.current) {
            const initialFiles = fileNames.map((file, idx) => ({
                id: `uploaded-${idx}`,
                document: file,
                date: format(new Date(), 'MM/dd/yyyy'),
                vault: vaultName,
                owner: ownerName,
                isNew: false,
                status: 'uploaded'
            }));
            setListItems(initialFiles);
            if (editAgentStore) {
                editAgentStore.filesWithStatus = initialFiles;
            }
            isInitialized.current = true;
        }
    }, [fileNames, vaultName, ownerName, editAgentStore]);

    const columns = useMemo(
        () => {
            const baseColumns = [
                { 
                    accessorKey: "document", 
                    header: "Document",
                    size: 200,
                    Cell: ({ row }) => {
                        const status = row.original.status;
                        const isUploaded = status === "uploaded";
                        const isUploading = status === "pending"
                        return <DocumentCell>
                            {isUploaded && <Icon component={Check} color="success" fontSize="8" />}
                            {isUploading && <Icon component={AttachFile} color="disabled" fontSize="8" />}
                            <span>{ row.original.document }</span>
                        </DocumentCell>
                    }
                },
                { 
                    accessorKey: "vault", 
                    header: "Vault",
                    grow: true,
                },
                { 
                    accessorKey: "date", 
                    header: "Date of creation",
                    grow: true,
                },
            ];
            return baseColumns;
        },
    );

    const table = useMaterialReactTable({
        columns,
        data: listItems,
        positionActionsColumn: 'last',
        displayColumnDefOptions: {
            'mrt-row-actions': {
                header: '',
            },
        },
        enableColumnFilters: false,
        enableHiding: false,
        enableDensityToggle: false,
        enableFilters: false,
        enableFullScreenToggle: false,  
        enableMultiSort: false,
        enableSorting: false,
        enableColumnActions: false,
        enableTopToolbar: false,
        enableRowActions: true,
        muiPaginationProps: {
            rowsPerPageOptions: [10],
        },
        muiTablePaperProps: {
            elevation: 0,
            sx: {
                borderRadius: '0',
            },
        },
        muiTableHeadCellProps: {
            sx: {
                backgroundColor: '#F2F2F2',
                fontSize: '18px',
                fontWeight: '500',
                padding: "7px"
            }
        },
        muiTableBodyCellProps: {
            sx: {
                fontSize: '14px',
                fontWeight: '400',
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "150px",
                maxWidth: "200px",
                overflow: "hidden",
                padding: "7px"
            }
        },
        getRowId: (row) => row.id,
        renderRowActions: (params) => {
            const staticRowId = params.staticRowIndex;
            const row = params.row;
            return (
                <IconContainer>
                    {row.original.isNew ? (
                        <>
                            {!editAgentStore.isUploadingFile && <IconButton icon="paperUpload" width="20px" height="20px" dataTestId="paper-upload-icon" onClick={() => handleUpload(row.original, staticRowId)} />}
                            {showDeleteIcon && (
                                <IconButton icon="deleteGray" width="18px" height="18px" top="2px" dataTestId="delete-new-icon" onClick={() => handleDeleteNew(row.original)} />
                            )}
                        </>
                    ) : (
                        <>
                            {(authStore.isGroupSuperAdmin || authStore.isGroupAdmin) && <IconButton icon="visibility" width="20px" height="20px" onClick={() => setSelectedDoc(row.original)} />}
                            {showDeleteIcon && (
                                <IconButton icon="deleteGray" width="18px" height="18px" top="2px" dataTestId="delete-uploaded-icon" onClick={() => handleDeleteUploaded(row.original)} />
                            )}
                        </>
                    )}
                </IconContainer>                
            )

        },
    });

    const handleAddButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        const allowedExtensions = extensions ? Object.values(extensions).flat() : [];

        const newFiles = files
            .filter(file => allowedExtensions.includes(`.${file.name.split('.').pop()}`))
            .map(file => ({
                id: `new-${listItems.length + Math.random()}`,
                document: file.name,
                date: format(new Date(), 'MM/dd/yyyy'),
                vault: vaultName,
                owner: ownerName,
                isNew: true,
                file: file,
                status: 'pending'
            }));


        if (newFiles.length !== files.length) {
            editAgentStore.isError = true;
        }

        const updatedListItems = [...listItems, ...newFiles];
        setListItems(updatedListItems);
        if (editAgentStore) {
            editAgentStore.filesWithStatus = updatedListItems;
        }
    };

    const getAcceptedExtensions = (extensions) => {
        return extensions ? Object.values(extensions).flat().join(',') : '';
    };

    const handleUpload = (file = null, itemId = null) => {
        let filesToUpload = []
        if(file){
            filesToUpload = [file];
        }else{
            filesToUpload = listItems.filter(item => item.status !== 'uploaded')
        }
        
        const currentUploadedFilesLength = listItems.filter(item => item.status === 'uploaded').length;
        const totalFilesThreshold = 20;

        editAgentStore.uploadFile(
            filesToUpload.map(item => item.file), 
            currentUploadedFilesLength, 
            totalFilesThreshold, 
            customer, 
            fileRoute, 
            (uploadInfo) => {
                
                let updatedListItems = [...listItems]
                
                if (!itemId) {
                    const nextItemKey = updatedListItems.findIndex((item) => item.status === 'pending');
                    updatedListItems[nextItemKey].status = "uploaded";  
                }
                else {
                    updatedListItems[itemId].status = "uploaded"
                }
                
                
                for (let file of filesToUpload) {
                    updatedListItems = updatedListItems.map(item =>
                        item.id === file.id ? { ...item, isNew: false } : item
                    );
                }

                editAgentStore.setFilesWithStatus(updatedListItems);
                setListItems(updatedListItems)
                if (onFilesChange) onFilesChange(uploadInfo.uploadUrl);
            },
            onError
        );
    };

    const handleDeleteNew = (file) => {
        const updatedListItems = listItems.filter(item => item.id !== file.id);
        setListItems(updatedListItems);
        if (editAgentStore) {
            editAgentStore.filesWithStatus = updatedListItems;
        }
    };

    const handleDeleteUploaded = (file) => {
        if (editAgentStore) {
            editAgentStore.displayDeleteFileDialog(file.document);
        }
    };

    const handleConfirmDelete = () => {
        editAgentStore.confirmRemoveFile().then(() => {
            const updatedListItems = editAgentStore.filesWithStatus;
            setListItems(updatedListItems);
        }).catch(onError);
    };

    return (
        <>
            <Header data-testid={dataTestId}>
                <Title>Knowledge base</Title>
                {showAddButton && (
                    <ButtonsContainer>
                       {listItems.filter(item => item.status !== 'uploaded').length > 1 && 
                            <RoundedButton width='100px' height='40px' onClick={() => handleUpload()} disabled={editAgentStore.isUploadingFile} dataTestId="upload-all-button">Upload all</RoundedButton>
                        }
                        <AddButton onClick={handleAddButtonClick}>
                            <img src={CircleAddIcon} width={24} height={24} alt="Add" />
                        </AddButton>
                    </ButtonsContainer>
                )}
                <input
                    type="file"
                    ref={fileInputRef}
                    data-testid="file-input"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    accept={getAcceptedExtensions(extensions)}
                    multiple
                />
            </Header>
            <MaterialReactTable table={table} />
            <DetailModal selectedDocument={selectedDoc} isOpen={!!selectedDoc} onClose={() => setSelectedDoc(null)} />
            <ConfirmationDialog
                open={Boolean(editAgentStore?.deleteFile)}
                onClose={() => editAgentStore?.displayDeleteFileDialog(false)}
                message="Are you sure you want to delete this file from this assistant?"
                onConfirm={handleConfirmDelete}
                maxWidth="xl"
                fullWidth={false}
            />
        </>
    );
});

export default KnowledgeBaseTable;
