import React, {useEffect, useState} from 'react'
import {observer} from "mobx-react";
import Tabs2 from "../../../elements/v2/Tabs2/Tabs2";
import {useTabs} from "./hook/useTabs";
import {useStore} from "../../../hooks";
import {
	ProfileContainer,
	CustomGenericListStyle,
	ActionButtonsContainer,
	UserActions,
	SuccessModal,
	TabContainer
} from "./ProfilePage.styled";
import ProfileTab from "./components/ProfileTab";
import UserPersonaTab from "./components/UserPersonaTab";
import {RoundedButton} from "../../../elements";
import {useMediaQueries} from "../../../utils/mediaQueries";
import {PermissionCodes, PermissionLevels} from "../../../utils/enums";


const ProfilePage = observer(() => {
	const {changeTitle, authStore, agentStore, profileStore} = useStore();
	const tabsProfile = useTabs();
	const {selectedTab} = profileStore;
	changeTitle('Profile');
	const {selectedAccount} = authStore;
	const [successModalContent, setSuccessModalContent] = useState({ isOpen: false, title: '', message: '' });
	const {isMobile, isTablet} = useMediaQueries();

	useEffect(() => {
		agentStore.fetchConciergeAgent(selectedAccount.id);
	}, [selectedAccount, agentStore]);

	const onToggleTab = (index) => {
		profileStore.setSelectedTab(index);
	}

	const showSuccessMessage = (title, message) => {
		setSuccessModalContent({
			isOpen: true,
			title,
			message,
		});

		setTimeout(() => {
			setSuccessModalContent({ isOpen: false, title: '', message: '' });
		}, 5000);
	};

	const showErrorMessage = (message) => {
		setSuccessModalContent({
			isOpen: true,
			title: 'Error',
			message,
		});

		setTimeout(() => {
			setSuccessModalContent({ isOpen: false, title: '', message: '' });
		}, 5000);
	};

	const handleSave = async () => {
		try {
			const resp = await profileStore.updateUser();
			if (resp) {
				showSuccessMessage(
					'Great',
					'Your changes were successfully saved!'
				)
			}
		} catch (error) {
			showErrorMessage("Error updating user data");
		}
	}

	return (
		<ProfileContainer>
			<TabContainer isMobile={isMobile} isTablet={isTablet}>
				<Tabs2 tabs={tabsProfile} defaultTab={selectedTab} onToggleTab={onToggleTab}/>
				<UserActions>
					<ActionButtonsContainer>
						<RoundedButton width='60px' fontSize='15px' outline={true} onClick={() => { }}>
							Cancel
						</RoundedButton>
						<RoundedButton width='80px' fontSize='15px' color='#2173FF' onClick={handleSave}>
							Save
						</RoundedButton>
					</ActionButtonsContainer>
				</UserActions>
			</TabContainer>
			<Tabs2.Outlet show={selectedTab === 0}>
				<ProfileTab/>
			</Tabs2.Outlet>
			{authStore.hasPermission(PermissionCodes['USER_PERSONA_TAB'], PermissionLevels['VIEW']) && (
				<Tabs2.Outlet show={selectedTab === 1}>
					<UserPersonaTab/>
				</Tabs2.Outlet>
			)}
			<CustomGenericListStyle/>
			<SuccessModal
				isOpen={successModalContent.isOpen}
				onClose={() => setSuccessModalContent({ isOpen: false, title: '', message: '' })}
				title={successModalContent.title}
				message={successModalContent.message}
			/>
		</ProfileContainer>
	)
})

export default ProfilePage
