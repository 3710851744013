import React, { useMemo } from "react";
import { observer } from "mobx-react";
import {
  FilterDivisor,
  FilterLabel,
  FiltersVisualization,
  Label,
  RuleSeparator,
} from "./RulesView.styled";
import { LogicalOp, RulesGroup } from "./RulesViewVisualization.styled";

const PostRulesView = observer(({ rules }) => {
  const hasRulesGroups = rules?.rulesGroups && Object.keys(rules.rulesGroups).length > 0;

  const groups = useMemo(() => {
    if (!hasRulesGroups) return [];
    return Object.values(rules.rulesGroups);
  }, [rules?.rulesGroups]);

  if (!hasRulesGroups) {
    return (
      <FiltersVisualization>
        <Label>
          No advanced filters available yet. <br /> Select a filter to get
          started.
        </Label>
      </FiltersVisualization>
    );
  }

  return (
    <FiltersVisualization>
      {groups.map((group, groupIndex) => {
        const validConditions = group?.conditions?.filter(
          (condition) =>
            condition.source_data?.length &&
            condition.source_field?.length &&
            condition.operator?.length &&
            condition.target_value?.length
        );

        if (!validConditions.length) return null;

        return (
          <div key={groupIndex}>
            <FilterDivisor>
              <FilterLabel>Group {groupIndex + 1}</FilterLabel>

              {validConditions.map((condition, index) => (
                <RulesGroup key={index}>
                  {index >= 1 && <LogicalOp>And</LogicalOp>}
                  <RulesGroup>
                    In <b>{condition.source_data}</b>, if{" "}
                    <b>{condition.source_field}</b> is{" "}
                    <b>{condition.operator}</b> to{" "}
                    <b>
                      {Array.isArray(condition.target_value)
                        ? condition.target_value.join(", ")
                        : condition.target_value}
                    </b>
                  </RulesGroup>
                </RulesGroup>
              ))}
            </FilterDivisor>
            {groupIndex < groups.length - 1 && (
              <RuleSeparator>
                <span></span>
                <div> OR </div>
                <span></span>
              </RuleSeparator>
            )}
          </div>
        );
      })}
    </FiltersVisualization>
  );
});

export default PostRulesView;
