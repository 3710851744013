import styled from 'styled-components';

export const DescriptionText = styled.span`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  max-height: 4.5em;
`;

