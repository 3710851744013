import { DropDownContainer, DropDownHeader, DropDownList, ListItem, ListInput, DropDownBody, SimpleSelectContainer } from "./SimpleSelect.styled";
import { useState, useEffect } from "react"
import ArrowDownIcon from "../../icon/assets/arrow_down_icon.svg"
import { Icon } from "@mui/material";
import { Clear } from "@mui/icons-material";

const defaultOptions = [{name: 'Option 1', value: 'option1'}]

const SimpleSelect = ({ optionCleaner = false, onClear, placeholder = 'Choose', selectedDefault = null, options={defaultOptions}, handleSelect, textTransform, hideOnBlur=true, searchBar=false }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(selectedDefault);
    const [searchTerm, setSearchTerm] = useState('');
  
    const toggling = () => setIsOpen(!isOpen);
  
    const onOptionClicked = option => () => {
        setSelectedOption(option.name);
        setIsOpen(false);
        handleSelect(option.name, option.value, selectedOption);
        setIsOpen(false);
    };


    useEffect(() => {
        selectedDefault && setSelectedOption(options.filter(option => option.value === selectedDefault)[0]?.name);
    }, [selectedDefault, isOpen])

    const handleBlur = () => { 
        hideOnBlur && setIsOpen(false) 
    }; 

    const onPrevClear = () => {
        onClear(selectedOption)
        setSelectedOption(null);
    }

    const listSorted = options.filter(option => (new RegExp(searchTerm, "i")).test(option.name));

    return (
    <SimpleSelectContainer>
        <DropDownContainer onBlur={handleBlur} tabIndex={0}>
            <DropDownHeader $textTransform={textTransform} onClick={toggling} arrow={ArrowDownIcon}>{selectedOption || placeholder}</DropDownHeader>
            {isOpen && options.length > 0 && (
                <DropDownBody $searchBar={searchBar}>
                    {searchBar && <ListInput value={searchTerm} onChange={e => setSearchTerm(e.target.value)} placeholder="Search..."/>}
                    <DropDownList>
                        {listSorted.map(option => 
                            <ListItem onClick={onOptionClicked(option)} key={Math.random()}><span>{option.name}</span></ListItem>
                        )}
                    </DropDownList>
                </DropDownBody>
            )}
        </DropDownContainer>
        {optionCleaner && selectedOption ? <Icon onClick={onPrevClear} component={Clear} width="10px" height="10px" />  : null}
    </SimpleSelectContainer>
  );
}

export default SimpleSelect;