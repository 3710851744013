import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IconButton } from "../../../../../elements";
import SearchInput from "./SearchInput";

import {
  RuleSelectWrapper,
  RuleSelectedOptionWrapper,
  RuleSelectorIconWrapper,
  RuleSelectorOptionsWrapper,
  RuleSelectorOptionsList,
} from "./RuleSelector.styled";

const RuleSelector = ({ suffix = "", defaultValue, options, onSelect, dataTestId }) => {
  const [openOptions, setOpenOptions] = useState(false);
  const [value, setValue] = useState("");
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const onHandleSelect = useCallback(
    (option) => {
      setValue(option.value);
      setOpenOptions(false);
      onSelect && onSelect(option);
    },
    [onSelect]
  );

  const onHandleClick = useCallback(() => {
    setOpenOptions(!openOptions);
  }, [openOptions]);

  const onHandleSearch = useCallback(
    (value) => {
      setSearchValue(value);
    },
    [setSearchValue]
  );

  const optionsFiltered = useMemo(() => {
    return options?.filter((option) =>
      option.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [options, searchValue]);

  return (
    <RuleSelectWrapper data-testid={dataTestId}>
      <RuleSelectedOptionWrapper>
        {suffix && suffix}
        <span>
          {options?.find((option) => value === option.value)?.name || ""}
        </span>
      </RuleSelectedOptionWrapper>
      <RuleSelectorIconWrapper openDropdown={openOptions}>
        <IconButton
          icon="expandMoreOutlined"
          width="24px"
          height="24px"
          onClick={onHandleClick}
        />
      </RuleSelectorIconWrapper>
      <RuleSelectorOptionsWrapper openDropdown={openOptions}>
        {options.length === 0 && <p>No options available</p>}
        {options.length > 8 && (
          <>
            <SearchInput defaultValue={searchValue} onChange={onHandleSearch} />
            <RuleSelectorOptionsList>
              {optionsFiltered.map((option, index) => (
                <p onClick={() => onHandleSelect(option)} key={index}>
                  {option.name}
                </p>
              ))}
            </RuleSelectorOptionsList>
          </>
        )}
        {options.length <= 8 && options.length > 0 && (
          <RuleSelectorOptionsList>
            {optionsFiltered.map((option, index) => (
              <p onClick={() => onHandleSelect(option)} key={index}>
                {option.name}
              </p>
            ))}
          </RuleSelectorOptionsList>
        )}
      </RuleSelectorOptionsWrapper>
    </RuleSelectWrapper>
  );
};

export default RuleSelector;
