import styled, { css } from 'styled-components';
// TODO: remove material dependency and instead create our custom components
import { Avatar, Paper } from '@mui/material';

const ChatWindowComponentPaper = styled(Paper)`
    overflow-y: auto;
    padding: 16px;
    height: ${props => props.$height || '480px'};
    max-height: ${props => props.$height || '480px'};
    box-shadow: ${props => props.boxShadow || 'none'} !important;
    display: flex;
    flex-direction: column;
`;

const ChatWindowAvatar = styled(Avatar)`
    margin-right: 12px;
`;

const MessageContainer = styled.div`
    margin-bottom: 12px;
    display: flex;
    width: ${props => props.width || '60%'};
`;

const flexWrapperStyles = css`
    display: flex;
`;

const normalWrapperStyles = css`
    display: block;
`;

const MessageWrapper = styled.div`
    padding: 12px;
    border-radius: 12px;
    width: 100%;
    background-color: ${props => props.itemType === 'sent' ? props.theme.colors.greySix : 'transparent'};
    color: ${props => props.theme.colors.black};
    margin-bottom: 5px;

    > * {
        margin: 0;
        font-size: 1rem;
        font-family: "Inter", sans-serif;
    }

    ${props => props.flex ? flexWrapperStyles : normalWrapperStyles}
`;


const MessageButtonsWrapper = styled.div`
    display: flex;
    gap: 10px;
    margin-top: ${props => props.mt ||'20px'};
    align-items: center;

    a {
        cursor: pointer;
    }
`;

const EditMessageWrapper = styled.div`
    padding: 12px;
    border-radius: 12px;
    width: 100%;
    color: ${props => props.theme.colors.primary};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    > * {
        margin: 0;
    }
`;

const EditMessageText = styled.div`
    ${props => props.theme.fonts.variant.light};
    font-size: ${props => props.theme.fonts.size.medium};
    color: ${props => props.theme.colors.black};
    text-align: center;
`;

const EditMessageInput = styled.textarea`
    resize: none;
    padding: 5px 10px;
    border-radius: 10px;

    &:focus {
        outline: none;
    }
`
const EditMessageInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    color: ${props => props.theme.colors.primary};
`;

const SpinnerImage = styled.img`
    height: 30px;
    margin-left:5px;
    opacity: 0.5;
`;

const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;



export {
    ChatWindowComponentPaper,
    ChatWindowAvatar,
    MessageContainer,
    MessageWrapper,
    MessageButtonsWrapper,
    EditMessageWrapper,
    EditMessageText,
    EditMessageInput,
    EditMessageInputWrapper,
    SpinnerImage,
    ImageWrapper
}
