import AddCircleAddIcon from "../../../elements/icon/assets/circle_add_icon.svg";
import { AddButton } from "../KnowledgeBaseTable/KnowledgeBaseTable.styled";
import { ClearIcon } from "@mui/x-date-pickers";
import { Modal } from "@mui/material";
import { useStore } from "../../../hooks";
import { useEffect, useState } from "react";

import {
  CreateContactModalContiner,
  CreateContactModal,
  EmailAddressContainer,
  AddMoreContainer,
  AddressContainer,
  PhoneDivision,
  ContactModalFooter,
  IconDeleteButton,
} from "./SaveSingleContactModal.styled";
import { InputText } from "../../input";
import { RoundedButton } from "../../button";
import { SuccessfulModal } from "../../modals";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const validatePhoneNumber = (phone) => {
  const phoneRegex = /^[0-9]{10}$/;
  return phoneRegex.test(phone);
};

const INITIAL_FORM_STATE = {
  parameters: {
    full_name: "",
    company_name: "",
    email_addresses: [],
    phone_numbers: [],
    addresses: [],
  },
};

const SaveSingleContactModal = observer(({
  createContactModal,
  setCreateContactModal,
  initialData,
}) => {
  const navigate = useNavigate();
  const [internalIsLoading, setInternalIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [emailErrors, setEmailErrors] = useState([]);
  const [phoneErrors, setPhoneErrors] = useState([]);
  const [emails, setEmails] = useState([{ email: "", description: "" }]);
  const [phones, setPhones] = useState([{ phone_number: "" }]);
  const [addresses, setAddresses] = useState([
    { address_1: "", state: "", city: "", postal_code: "" },
  ]);
  const { authStore, contactStore } = useStore();
  const { isLoading, isError } = contactStore;
  const { selectedAccount } = authStore;
  
  useEffect(() => {
    if (!initialData?.parameters) return;
    setFormData(initialData);
    const isPhoneNumbersString = typeof initialData.parameters.phone_numbers === "string";
    const isEmailAddressesString = typeof initialData.parameters.email_addresses === "string";
    const isAddressesString = typeof initialData.parameters.addresses === "string";

    if (isPhoneNumbersString && isEmailAddressesString && isAddressesString) {
      setPhones(JSON.parse(initialData.parameters.phone_numbers));
      setAddresses(JSON.parse(initialData.parameters.addresses));
      setEmails(JSON.parse(initialData.parameters.email_addresses));
    }
    else {
      setPhones(initialData.parameters.phone_numbers);
      setAddresses(initialData.parameters.addresses);
      setEmails(initialData.parameters.email_addresses);
    }

  }, [initialData]);

  const reloadContactTable = async () => {
    await contactStore.getContacts({
      customer: selectedAccount,
      pageIndex: 0,
      pageSize: 10,
      sortBy: [{ id: "contact_name", desc: false }],
      search: "",
    });
  };

  const saveSingleContact = async () => {
    const isCreateProcess = !initialData?.parameters;
    setInternalIsLoading(true);
    const formattedData = {
      ...formData,
      parameters: {
        contact_id: initialData ? initialData.parameters.contact_id : "",
        ...formData?.parameters,
        email_addresses: emails,
        phone_numbers: phones,
        addresses,
      },
    };

    const response = await contactStore.putSingleContact(selectedAccount?.id, formattedData);
    await reloadContactTable();
    resetFormAndCloseModal();
    setIsDone(true);
    setInternalIsLoading(false);
    if (isCreateProcess) {
      const body = JSON.parse(response.data.putSingleContact.body);
      navigate(`/unified-profile?id=${body.ann_contact_id}`)
    }
  };

  const onHandleCloseModal = () => {
    setCreateContactModal(false);
  };

  const resetFormAndCloseModal = () => {
    setEmails([{ email: "", description: "" }]);
    setPhones([{ phone_number: "" }]);
    setAddresses([{ address_1: "", state: "", city: "", postal_code: "" }]);
    setFormData({
      parameters: {
        full_name: "",
        company_name: "",
        email_addresses: [],
        phone_numbers: [],
        addresses: [],
      },
    });

    setCreateContactModal(false);
  };

  const removeItem = (array, setArray, index) => {
    const newArray = array.filter((_, i) => i !== index);
    setArray(newArray);
  };

  const validateForm = () => {
    const { full_name } = formData.parameters;
    const isNameValid = full_name?.trim() !== "";
    const hasValidEmail = emails.some((email) => validateEmail(email.email));
    const hasValidPhone = phones.some((phone) =>
      validatePhoneNumber(phone.phone_number)
    );
    return isNameValid && (hasValidEmail || hasValidPhone);
  };

  const writeNewEmail = (e, index, field) => {
    const value = e.target.value;
    const newEmails = [...emails];
    newEmails[index] = { ...newEmails[index], [field]: value };

    if (field === "email") {
      const isValid = validateEmail(value);
      const updatedErrors = [...emailErrors];
      updatedErrors[index] = !isValid;
      setEmailErrors(updatedErrors);
    }

    setEmails(newEmails);
  };

  const writeNewPhone = (e, index) => {
    const value = e.target.value;
    const newPhones = [...phones];
    newPhones[index] = { ...newPhones[index], phone_number: value };

    const isValid = validatePhoneNumber(value);
    const updatedErrors = [...phoneErrors];
    updatedErrors[index] = !isValid;
    setPhoneErrors(updatedErrors);

    setPhones(newPhones);
  };

  const addNewEmail = () => {
    setEmails([...emails, { email: "", description: "" }]);
  };

  const addNewPhones = () => {
    setPhones([...phones, { phone_number: "" }]);
  };

  const writeNewAddress = (e, index, field) => {
    const value = e.target.value;
    const newAddresses = [...addresses];
    newAddresses[index] = { ...newAddresses[index], [field]: value };
    setAddresses(newAddresses);
  };

  const addNewAddress = () => {
    setAddresses([
      ...addresses,
      { address_1: "", state: "", city: "", postal_code: "" },
    ]);
  };

  const handleFieldChange = (e, field) => {
    const value = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      parameters: {
        ...prevData.parameters,
        [field]: value,
      },
    }));
  };

  return (
    <>
    
      <SuccessfulModal
        isOpen={isError}
        onClose={() => contactStore.onClearError()}
        title={`Oops!`}
        subtitle="An error occurred."
      />

      <SuccessfulModal
        isOpen={isDone}
        onClose={() => setIsDone(false)}
        title="Great!"
        subtitle="Your contact has been successfully added"
      />

      <Modal
        open={createContactModal}
        onClose={onHandleCloseModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        style={{ zIndex: 2000 }}
      >
        <CreateContactModalContiner>
          <CreateContactModal>
            <p>{!initialData ? "New" : "Update"}</p>
            <span>Full Name *</span>
            <InputText
              placeholder="Full Name"
              onChange={(e) => handleFieldChange(e, "full_name")}
              disabled={isLoading}
              value={formData?.parameters?.full_name}
            />
            <span>Email Address</span>
            {emails?.map((email, index) => (
              <div key={index}>
                <EmailAddressContainer key={index}>
                  {index >= 1 && (
                    <IconDeleteButton
                      aria-label="Delete Email"
                      onClick={() => removeItem(emails, setEmails, index)}
                    >
                      <ClearIcon fontSize={"small"} />
                    </IconDeleteButton>
                  )}
                  <InputText
                    placeholder="Enter email"
                    value={email?.email}
                    onChange={(e) => writeNewEmail(e, index, "email")}
                    disabled={isLoading}
                    displayError={emailErrors[index]}
                    validationError={
                      emailErrors[index] ? "Invalid email format" : ""
                    }
                  />
                  <InputText
                    placeholder="Enter Description"
                    value={email?.description}
                    onChange={(e) => writeNewEmail(e, index, "description")}
                    disabled={isLoading}
                  />
                </EmailAddressContainer>
              </div>
            ))}
            <AddMoreContainer>
              <AddButton onClick={addNewEmail}>
                <img src={AddCircleAddIcon} width={24} height={24} alt="Add" />
              </AddButton>
            </AddMoreContainer>
            <hr />
            <span>Company name</span>
            <InputText
              placeholder="Company name"
              value={formData.parameters.company_name}
              onChange={(e) => handleFieldChange(e, "company_name")}
              disabled={isLoading}
            />
            <hr />
            <span>Addresses</span>
            {addresses?.map((address, index) => (
              <AddressContainer key={index}>
                {index >= 1 && (
                  <IconDeleteButton
                    aria-label="Delete Email"
                    onClick={() => removeItem(addresses, setAddresses, index)}
                  >
                    <ClearIcon fontSize={"small"} />
                  </IconDeleteButton>
                )}
                <InputText
                  placeholder="Enter Address"
                  value={address[`address_${index}`]}
                  onChange={(e) => writeNewAddress(e, index, "address_1")}
                  disabled={isLoading}
                />
                <InputText
                  placeholder="Enter City"
                  value={address.city}
                  onChange={(e) => writeNewAddress(e, index, "city")}
                  disabled={isLoading}
                />
                <InputText
                  placeholder="Enter State"
                  value={address.state}
                  onChange={(e) => writeNewAddress(e, index, "state")}
                  disabled={isLoading}
                />
                <InputText
                  placeholder="Enter Postal Code"
                  value={address.postal_code}
                  onChange={(e) => writeNewAddress(e, index, "postal_code")}
                  disabled={isLoading}
                />
              </AddressContainer>
            ))}
            <AddMoreContainer>
              <AddButton onClick={addNewAddress}>
                <img src={AddCircleAddIcon} width={24} height={24} alt="Add" />
              </AddButton>
            </AddMoreContainer>
            <hr />
            <span>Phone Number</span>
            {phones?.map((phone, index) => (
              <div>
                {index >= 1 && (
                  <IconDeleteButton
                    aria-label="Delete Email"
                    onClick={() => removeItem(phones, setPhones, index)}
                  >
                    <ClearIcon fontSize={"small"} />
                  </IconDeleteButton>
                )}
                <PhoneDivision key={index}>
                  <InputText
                    placeholder={"+1"}
                    value={phone?.phone_extension}
                    disabled
                  />
                  <InputText
                    placeholder="Phone number"
                    value={phone.phone_number}
                    onChange={(e) => writeNewPhone(e, index)}
                    disabled={isLoading}
                    displayError={phoneErrors[index]}
                    validationError={
                      phoneErrors[index] ? "Invalid phone format" : ""
                    }
                  />
                </PhoneDivision>
              </div>
            ))}
            <AddMoreContainer>
              <AddButton onClick={addNewPhones}>
                <img src={AddCircleAddIcon} width={24} height={24} alt="Add" />
              </AddButton>
            </AddMoreContainer>
            <ContactModalFooter>
              <RoundedButton
                outline
                height="40px"
                width="100px"
                onClick={resetFormAndCloseModal}
              >
                Cancel
              </RoundedButton>
              <RoundedButton
                kind="blue"
                disabled={isLoading || !validateForm()}
                height="40px"
                width="100px"
                onClick={saveSingleContact}
              >
                {internalIsLoading ? (initialData ? "Updating ..." : "Saving ...") : initialData ? "Update" : "Save"}
              </RoundedButton>
            </ContactModalFooter>
          </CreateContactModal>
        </CreateContactModalContiner>
      </Modal>
    </>
  );
});

export default SaveSingleContactModal;
