import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  OverviewContainer,
  OverviewCardInfo,
  Label,
  OverviewSegmentInfo,
  ViewDetails,
  AgentTeamSelectorWrapper,
  DescriptionKnowledgeBaseContainer,
  DescriptionWrapper,
  KnowledgeBaseWrapper,
  OverviewKnowledgeBaseContainer,
  CustomGenericListStyle,
} from "./Marketing.styled";
import { AgentTeamSelector } from "../../AsistantManagement/components/AgentTeamSelector";
import { useStore } from "../../../../hooks";
import { observer } from "mobx-react";
import SidebarKnowledgeBaseCard from "../../HomePage/components/SidebarKnowledgeBaseCard";
import { useMediaQueries } from "../../../../utils/mediaQueries";
import { useNavigate } from "react-router-dom";
import { IconButton } from "../../../../elements";
import EditMarketingModal from "./EditMarketingModal";
import { MarketingTab } from "./Marketing";
import StatusBadge from "../../../../elements/StatusBadge";

const Overview = observer(({ setCurrentTab }) => {
  const { authStore, marketingStore } = useStore();
  const { isMobile, isTablet } = useMediaQueries();
  const [currentAccount, setCurrentAccount] = useState(authStore.selectedAccount.id);
  const [openEditMarketingModal, setOpenEditMarketingModal] = useState(false);
  const navigate = useNavigate();

  const formatDate = (date) => date ? date.format('MM/DD/YYYY - HH:mm') : '---';

  const selectedAgent = useMemo(() => {
    return authStore.agents.find((agent) => agent.id === marketingStore.selectedAgent) || {};
  }, [authStore.agents, marketingStore.selectedAgent]);

  const handleAgentSelect = useCallback(
    (agent_id) => {
      marketingStore.setSelectedAgent(agent_id);
    },
    [marketingStore],
  );

  const handleViewDetails = () => {
    setCurrentTab(MarketingTab.Segments)
  };

  useEffect(() => {
    const accountId = authStore.selectedAccount?.id;
    
    if (currentAccount !== accountId) {
      setCurrentAccount(accountId);
      navigate('/v2/conversations');
    }
  }, [authStore.selectedAccount.id, currentAccount, navigate]);

  return (
    <OverviewContainer isMobile={isMobile}>
      <OverviewCardInfo>
        <div><Label>Marketing Information</Label> {!marketingStore.activeCampaign && <IconButton icon="editBlue" width="15px" height="15px" onClick={() => setOpenEditMarketingModal(true)}/>}</div>
        <span>
          <Label>Name: </Label>{marketingStore.nameRead}
        </span>
        <span>
          <Label>Description: </Label> {marketingStore.descriptionRead}
        </span>
        <span>
          <Label>Status:           <StatusBadge status={marketingStore.status.toLowerCase()}>
            {marketingStore.status}
          </StatusBadge></Label>
        </span>
        <span>
          <Label>Start date: </Label> {formatDate(marketingStore.startDateRead)}
        </span>
        <span>
          <Label>End date: </Label> {formatDate(marketingStore.endDateRead)}
        </span>
      </OverviewCardInfo>

      <OverviewSegmentInfo>
        <Label>Segment</Label>
        <ViewDetails onClick={handleViewDetails}>View details</ViewDetails>
      </OverviewSegmentInfo>

      <OverviewCardInfo>
        <Label>Agent</Label>
        <AgentTeamSelectorWrapper isMobile={isMobile}>
          <AgentTeamSelector
            agentName={selectedAgent.name}
            status={selectedAgent.active}
            agents={authStore.agents}
            scope={selectedAgent.scope}
            onChange={handleAgentSelect}
            agentImageUrl={selectedAgent?.photo_url}
          />
        </AgentTeamSelectorWrapper>
        {marketingStore.selectedAgent && (
          <DescriptionKnowledgeBaseContainer>
            {selectedAgent.job_title && (
              <DescriptionWrapper>
                <Label>Description</Label>
                <span>{selectedAgent.job_title}</span>
              </DescriptionWrapper>
            )}
            <KnowledgeBaseWrapper>
              {selectedAgent.knowledge_base &&
                selectedAgent.knowledge_base.length > 0 && (
                  <>
                    <Label>Knowledge base</Label>
                    <OverviewKnowledgeBaseContainer
                      isTablet={isTablet}
                      isMobile={isMobile}
                    >
                      {selectedAgent.knowledge_base.map((item) => (
                        <SidebarKnowledgeBaseCard
                          key={item}
                          knowledgeBase={[item]}
                        />
                      ))}
                    </OverviewKnowledgeBaseContainer>
                  </>
                )}
            </KnowledgeBaseWrapper>
          </DescriptionKnowledgeBaseContainer>
        )}
      </OverviewCardInfo>
      {openEditMarketingModal && <EditMarketingModal isOpen={openEditMarketingModal} setIsOpen={setOpenEditMarketingModal}/>}
      {isMobile && <CustomGenericListStyle />}
    </OverviewContainer>
  );
});

export default Overview;
