import React,  { useMemo, useState, useEffect } from "react";
import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import { Arrow, Pagination } from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/arrow.css";
import "@egjs/flicking-plugins/dist/pagination.css";
import {
  CarouselContainer,
  SliderPrincipalPanel,
  FlickingArrow,
  FlickingPagination,
} from "./DynamicCarousel.styled";

const defaultOptions = {
  circular: true,
  gap: 10,
  align: "center",
  moveType: "snap",
  draggable: true,
  horizontal: true,
  autoResize: true,
};


const DynamicCarousel = ({
  items,
  renderItem,
  options = {},
  navigationType,
  onMoveEnd,
  carouselWidth,
}) => {
  const [plugins, setPlugins] = useState([])
  const carouselOptions = useMemo(
    () => ({ ...defaultOptions, ...options }),
    [options]
  );


  useEffect(() => {
    if (navigationType === "arrow") {
      setPlugins([...plugins, new Arrow()]);
    } else if (navigationType === "pagination") {
      setPlugins([...plugins, new Pagination({ type: "bullet" })])
    }
  }, [navigationType])


  return (
    <CarouselContainer carouselWidth={carouselWidth}>
      <Flicking {...carouselOptions} plugins={plugins} onMoveEnd={onMoveEnd}>
        {items.map((item, index) => (
          <SliderPrincipalPanel key={index}>
            {renderItem(item, index)}
          </SliderPrincipalPanel>
        ))}

        <ViewportSlot>
          {renderItem && navigationType === "arrow" && (
            <>
              <FlickingArrow className="flicking-arrow-prev is-thin" />
              <FlickingArrow className="flicking-arrow-next is-thin" />
            </>
          )}
          {renderItem && navigationType === "pagination" && (
            <FlickingPagination className="flicking-pagination" />
          )}
        </ViewportSlot>
      </Flicking>
    </CarouselContainer>
  );
};

export default DynamicCarousel;
