import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import {
  HomeContainer,
  MainArea,
  ChatContainer,
  BodyChatContainer,
  FooterChatContainer,
  ChatInputWrapper,
  Separator,
  CustomGenericListStyle,
} from "./HomePage.styled";
import ChatInput from "../../../elements/input/ChatInput";
import Spinner from "../../../components/common/Spinner";
import { API, graphqlOperation } from "aws-amplify";
import { getConversationChatAssistant } from "../../../graphql/queries";
import _ from "lodash";
import { onChatAssistantResponse } from "../../../graphql/subscriptions";
import { useStore } from "../../../hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toJS } from "mobx";
import SidebarAgents from "./components/SidebarAgents";
import SidebarDetailsHistory from "./components/SidebarDetailsHistory";
import { observer } from "mobx-react";
import ChatTabs from './components/ChatTabs';
import { BreadCrumbContainer, MainContainer, Title } from "../Conversations/EventName/EventName.styled";
import dayjs from 'dayjs'
import styled from 'styled-components';

const ResponsiveMainArea = styled(MainArea)`
  @media (max-width: 1024px) {
    height: calc(100vh - 60px);
    overflow: hidden;
  }
`;

const ResponsiveChatContainer = styled(ChatContainer)`
  @media (max-width: 768px) {
    height: calc(100vh - 110px);
  }
`;

const ResponsiveBodyChatContainer = styled(BodyChatContainer)`
  @media (max-width: 768px) {
    padding: 0.5rem;
    height: calc(100% - 60px); // Adjust for footer height
  }
`;

const ResponsiveFooterChatContainer = styled(FooterChatContainer)`
  @media (max-width: 768px) {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    padding: 0.5rem;
    background-color: ${props => props.theme.colors.white};
  }
`;

const HomePage = observer(() => {
  const navigate = useNavigate();
  const { authStore, changeTitle, historyAgentStore, conversationsStore } = useStore();
  const [searchParams] = useSearchParams();
  changeTitle(null);
  const [agentIsTyping, setAgentIsTyping] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [chatWindowHeight, setChatWindowHeight] = useState(70);

  const [agent_params, setAgent_params] = useState({
    message: "",
    campaign_id: "",
    campaign_version: "",
    agent: "",
  });
  const [attachment, setAttachment] = useState("");
  const { conciergeAgent } = historyAgentStore;

  const [isSendMessageClicked, setIsSendMessageClicked] = useState(false);
  const [selectedAgentName, setSelectedAgentName] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [SidebarDetailsHistoryActiveTab, setSidebarDetailsHistoryActiveTab] =
    useState(1);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const [isSidebarDetailsHistoryExpanded, setIsSidebarDetailsHistoryExpanded] =
    useState(true);
  const [selectedAgentData, setSelectedAgentData] = useState({});
  const [conversationInProgress, setConversationInProgress] = useState(false);
  const [incompleteMessage, setIncompleteMessage] = useState("");
  const [assistantResponseSub, setAssistantResponseSub] = useState(null);

  const handleSelectQuestion = (selectedQuestion) => {
    setNewMessage(selectedQuestion);
    handleSendMessage(selectedQuestion);
  };

  const handleAgentSelect = (name, agent_id) => {
    if (agent_id === agent_params.agent) {
      return;
    }

    if (assistantResponseSub) {
      assistantResponseSub.unsubscribe();
    }
    const selectedAgent = authStore.agents.find(
      (agent) => agent.id === agent_id,
    );
    setSelectedAgentData(selectedAgent);
    setSelectedAgentName(name);

    setAgent_params((prevParams) => ({
      ...prevParams,
      agent: selectedAgent?.id,
      is_concierge: selectedAgent?.domain === "concierge",
    }));
    historyAgentStore.setConversationsStarters(selectedAgent);
  };

  const bottomRef = useRef(null);
  const textAreaRef = useRef();

  useEffect(() => {
    if (authStore.selectedAccount && authStore.selectedAccount.id) {
      setSelectedCustomer(authStore.selectedAccount.id);
      conversationsStore.setCustomerId(authStore.selectedAccount.id);
      authStore.fetchAgents();
    }
  }, [authStore.selectedAccount]);

  useEffect(() => {
    const agentIdFromUrl = searchParams.get("agent");
    const selectedAgent = authStore.agents.find(
      (agent) => agent.id === agentIdFromUrl,
    );
    const conciergeAgent = authStore.agents.find(
      (agent) => agent.domain === "concierge",
    );
    if (selectedAgent) {
      handleAgentSelect(selectedAgent.name, selectedAgent.id);
    } else if (conciergeAgent) {
      handleAgentSelect(conciergeAgent.name, conciergeAgent.id);
    }
  }, [searchParams, authStore.agents]);

  useEffect(() => {
    if (messages.length && historyAgentStore.bottomRef?.current) {
      scrollToBottom();
    }
  }, [messages]);

  useEffect(() => {
    if (historyAgentStore.messagesData.length) {
      setAgentIsTyping(false);
      historyAgentStore.setIsChatQuestionsVisible(false);
      setMessages(toJS(historyAgentStore.messagesData));
    } else {
      setMessages([]);
      historyAgentStore.setIsChatQuestionsVisible(true);
    }
  }, [historyAgentStore.messagesData]);

  useEffect(() => {
    historyAgentStore.getAgents(selectedCustomer, selectedAgentData.id);
    historyAgentStore.fetchConciergeAgent(selectedCustomer);
    historyAgentStore.setBottomRef(bottomRef);
  }, [selectedCustomer, selectedAgentData.id, historyAgentStore]);

  useEffect(() => {
    const loadConversations = async () => {
      if (selectedAgentData.id) {
        await historyAgentStore.fetchConversations(
          selectedAgentData.id,
          authStore.userId,
        );
      }
    };

    loadConversations();
  }, [selectedAgentData.id, authStore.userId, historyAgentStore]);

  useLayoutEffect(() => {
    const textAreaWrapper = textAreaRef.current;
    if (textAreaWrapper) {
      const textArea = textAreaWrapper.getElementsByTagName("textarea")[0];

      textArea.style.height = "0px";
      const scrollHeight = textArea.scrollHeight;

      setChatWindowHeight(
        Math.max(58, 74 - (100 * scrollHeight) / window.innerHeight),
      );
      textArea.style.height = scrollHeight + "px";
    }
  }, [newMessage]);

  const handleSendMessage = async (message) => {
    const messageToSend = message || newMessage;
    historyAgentStore.setIsChatQuestionsVisible(false);

    if (messageToSend.trim() === "") return;
    setIsSendMessageClicked(true);

    // Add the message to the list of messages before sending it
    setMessages((prevMessages) => [
      ...prevMessages,
      { message: messageToSend, type: "sent", time: dayjs().format("HH:mm") },
    ]);

    setNewMessage("");
    const params = {
      ...agent_params,
      message: messageToSend,
      customer_id: selectedCustomer,
      attachment,
      conversation_id: historyAgentStore.lastConversationID,
    };

    try {
      let openAiAnswer = null;
      let local_msgs = _.union(messages, []);

      let resultConversationOpenAi = null;
      try {
        resultConversationOpenAi = await API.graphql({
          query: getConversationChatAssistant,
          variables: { input: params },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        if (!resultConversationOpenAi.errors?.length) {
          setAgentIsTyping(true);

          const apiResponse = resultConversationOpenAi.data.getConversationChatAssistant;

          const parseResponseBody = (responseBody) => JSON.parse(responseBody);

          let tmpMsg = "";
          let initialMessages = [
            ...messages,
            {
              message: messageToSend,
              type: "sent",
              time: dayjs().format("HH:mm"),
            },
          ];
          let isStreaming = false;
          setMessages(initialMessages);

          setAttachment("");
          // Subscribe to chat assistant response
          const assistantResponseSub = API.graphql({
            ...graphqlOperation(onChatAssistantResponse, { id: apiResponse.id }),
            authMode: "AMAZON_COGNITO_USER_POOLS",
          }).subscribe({
            next: ({ provider, value }) => {
              const subApiResponse = value.data.onChatAssistantResponse;
              const body = parseResponseBody(subApiResponse.body);
              conversationsStore.setThreadId(body.thread_id);
              conversationsStore.setRunId(body.run_id);
              setConversationInProgress(true);
              openAiAnswer = body.answer;
              setShowSpinner(false);
              setAgentIsTyping(false);

              historyAgentStore.setLastConversationID(body.conversation_id);

              if (subApiResponse.status === "done" || subApiResponse.status === "guardrail") {
                if (!isStreaming) {
                  const newMessages = [
                    ...initialMessages,
                    { message: openAiAnswer, type: "received", time: dayjs().format("HH:mm") },
                  ];
                  setMessages(newMessages);
                  initialMessages = [...newMessages];
                }
                assistantResponseSub.unsubscribe();
                tmpMsg = "";
                initialMessages = [...messages];
                isStreaming = false;
                setIsSendMessageClicked(false);
                setConversationInProgress(false);

                // Add the new conversation to the list of conversations
                historyAgentStore.addConversation({
                  id: body.conversation_id,
                  title: messageToSend,
                  last_modified: new Date().toISOString(),
                  agent_id: selectedAgentData.id,
                });
              } else {
                isStreaming = true;
                tmpMsg += openAiAnswer;
                setMessages([
                  ...initialMessages,
                  { message: tmpMsg, type: "received", time: dayjs().format("HH:mm") },
                ]);
              }
            },
            error: (error) => {
              console.warn(error);
              setShowSpinner(false);
              setAgentIsTyping(false);
              setIsSendMessageClicked(false);
              setConversationInProgress(false);
              local_msgs = _.union(local_msgs, [
                {
                  message: "Oops! Something went wrong. Please try again.",
                  type: "received",
                  time: dayjs().format("HH:mm"),
                },
              ]);
              setMessages(local_msgs);
            },
          });

          setAssistantResponseSub(assistantResponseSub);
        } else {
          setIsSendMessageClicked(false);
          setConversationInProgress(false);
          setMessages([
            ...messages,
            {
              message: "Oops! Something went wrong. Please try again.",
              type: "received",
              time: dayjs().format("HH:mm"),
            },
          ]);
        }
      } catch (err) {
        console.log(err);
        setIsSendMessageClicked(false);
        setConversationInProgress(false);
        setMessages([
          ...messages,
          {
            message: "Oops! Something went wrong. Please try again.",
            type: "received",
            time: dayjs().format("HH:mm"),
          },
        ]);
      }
    } catch (error) {
      console.error(error);
      setIsSendMessageClicked(false);
      setConversationInProgress(false);

      if (
        error.errors &&
        error.errors[0].errorType === "Lambda:ExecutionTimeoutException"
      ) {
        setMessages([
          ...messages,
          {
            message:
              "Sorry, I'm taking longer than expected to get your answer. I'll notify my team to teach me more about this topic. Meanwhile, let's continue with other questions you have?",
            type: "received",
            time: dayjs().format("HH:mm"),
          },
        ]);
      }
    }
  };

  const handleCleanThread = () => {
    setMessages([]);
    historyAgentStore.setIsChatQuestionsVisible(true);
    setAgentIsTyping(false);
    historyAgentStore.setLastConversationID("");
    setIsSendMessageClicked(false); // Add this line
    setConversationInProgress(false);
  };

  const scrollToBottom = () => {
    if (historyAgentStore.bottomRef?.current) {
      historyAgentStore.bottomRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };

  const handleToggleSidebar = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };

  const handleInfoTimeButtonClick = (option) => {
    if (
      isSidebarDetailsHistoryExpanded &&
      SidebarDetailsHistoryActiveTab === option
    ) {
      setIsSidebarDetailsHistoryExpanded(false);
    } else {
      setIsSidebarDetailsHistoryExpanded(true);
      setSidebarDetailsHistoryActiveTab(option);
    }
  };

  /**
   * Handle stopping the conversation.
   *
   * This function unsubscribes from the assistant's response subscription
   * if it exists, stops the conversation in progress, stops the agent from typing,
   */
  const handleStopConversation = async () => {
    if (assistantResponseSub && (conversationsStore.runId && conversationsStore.threadId && conversationsStore.customerId)) {
      conversationsStore.stopResponse();
      assistantResponseSub.unsubscribe();
    }
    setConversationInProgress(false);
    setAgentIsTyping(false);
    setIsSendMessageClicked(false);
  };

  return (
    <>
      <MainContainer>
        <BreadCrumbContainer>
          <Title>{`Home - ${toJS(authStore?.selectedAccount)?.name}`}</Title>
        </BreadCrumbContainer>
      </MainContainer>
      <HomeContainer>
        <SidebarAgents
          isExpanded={isSidebarExpanded}
          onClick={handleToggleSidebar}
          selectedDefault={selectedAgentName}
          handleAgentSelect={handleAgentSelect}
        />
        <ResponsiveMainArea>
          <ResponsiveChatContainer className="tabs">
            <ResponsiveBodyChatContainer>
              <ChatTabs
                selectedAgent={selectedAgentData}
                messages={messages}
                agentIsTyping={agentIsTyping}
                handleSelectQuestion={handleSelectQuestion}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                handleInfoTimeButtonClick={handleInfoTimeButtonClick}
                isSidebarDetailsHistoryExpanded={
                  isSidebarDetailsHistoryExpanded
                }
                SidebarDetailsHistoryActiveTab={SidebarDetailsHistoryActiveTab}
              />
            </ResponsiveBodyChatContainer>

            <ResponsiveFooterChatContainer className="footer-chat-container">
              <ChatInputWrapper>
                <>
                  <Separator>
                    {showSpinner && <Spinner className="spinner" />}
                  </Separator>
                  <ChatInput
                    customerId={authStore.selectedAccount.id}
                    textAreaRef={textAreaRef}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyPress={(e, callback) => {
                      if (e.key === "Enter" && !e.shiftKey && !showSpinner) {
                        e.preventDefault();
                        handleSendMessage();
                        callback("");
                      }
                    }}
                    showSpinner={showSpinner}
                    newMessage={newMessage}
                    handleSendMessage={handleSendMessage}
                    onFileUploaded={setAttachment}
                    disableSend={isSendMessageClicked}
                    onCleanThread={handleCleanThread}
                    stop={conversationInProgress}
                    onStopClick={handleStopConversation}
                  />
                </>
              </ChatInputWrapper>
            </ResponsiveFooterChatContainer>
          </ResponsiveChatContainer>
        </ResponsiveMainArea>
        {isSidebarDetailsHistoryExpanded && (
          <SidebarDetailsHistory
            selectedDefault={selectedAgentData}
            activeTab={SidebarDetailsHistoryActiveTab}
            onTabChange={setSidebarDetailsHistoryActiveTab}
          />
        )}
      </HomeContainer>
      <CustomGenericListStyle />
    </>
  );
});

export default HomePage;
