import styled from "styled-components";

const getButtonVariant = (props) => {
    const { kind, $outline, theme, color } = props;
    const buttonColor = color || (kind && theme.colors[kind]);

    switch (kind) {
        case 'primary':
            return !$outline ? `
                background-color: ${buttonColor || theme.colors.primary};
                color: ${theme.colors.white};
                border: 0;
            ` : `
                background-color: inherit;
                color: ${buttonColor || theme.colors.primary};  
                border: 1px solid ${buttonColor || theme.colors.primary};
            `;
        case 'secondary':
            return !$outline ? `
                background-color: ${buttonColor || theme.colors.secondary};
                color: ${theme.colors.greyThree};
                border: 0;
            ` : `
                background-color: inherit;
                color: ${buttonColor || theme.colors.secondary};  
                border: 1px solid ${buttonColor || theme.colors.primary};
            `;
        case 'tertiary':
            return !$outline ? `
                background-color: ${buttonColor || theme.colors.lightBlue};
                color: ${theme.colors.black};
                border: 0;
            ` : `
                background-color: inherit;
                color: ${buttonColor || theme.colors.secondary};  
                border: 1px solid ${buttonColor || theme.colors.primary};
            `;
        case 'blue':
            return `
                background-color: ${buttonColor || theme.colors.clearBlue};;
                color: ${buttonColor || theme.colors.white};  
                border: 1px solid ${buttonColor || theme.colors.clearBlue};`;
        case 'success':
            return !$outline ? `
                    background-color: ${buttonColor || theme.colors.darkGreen};
                    color: ${theme.colors.white};
                    border: 0;
                ` : `
                    background-color: inherit;
                    color: ${buttonColor || theme.colors.green};
                    border: 1px solid ${buttonColor || theme.colors.greenPrimary};
                `;
        case 'declined':
            return !$outline ? `
                    background-color: ${buttonColor || theme.colors.white};
                    color: ${theme.colors.black};
                    border: 1px solid ${theme.colors.black};
                ` : `
                    background-color: inherit;
                    color: ${buttonColor || theme.colors.darkGray};
                    border: 1px solid ${buttonColor || theme.colors.white};
                `;
        case 'confirm':
            return !$outline ? `
                background-color: ${buttonColor || theme.colors.activeBlue};
                color: ${theme.colors.white};
                border: 0;
            ` : `
                background-color: inherit;
                color: ${buttonColor || theme.colors.activeBlue};
                border: 1px solid ${buttonColor || theme.colors.activeBlue};
            `;
            case 'cancel':
            return !$outline ? `
                background-color: ${buttonColor || theme.colors.davysGrey};
                color: ${theme.colors.white};
                border: 1px solid ${theme.colors.davysGrey};
            ` : `
                background-color: inherit;
                color: ${buttonColor || theme.colors.secondary};  
                border: 1px solid ${buttonColor || theme.colors.primary};
            `;
        case 'publish':
            return `
                background-color: ${buttonColor || theme.colors.white};;
                color: ${buttonColor || theme.colors.black};
                border: 1px solid ${buttonColor || theme.colors.black};`;
        case 'upload':
            return `
                background-color: ${buttonColor || theme.colors.black};;
                color: ${buttonColor || theme.colors.white};
                border: 1px solid ${buttonColor || theme.colors.black};`;
        case 'actions':
            return `
                background-color: ${buttonColor || theme.colors.highClearGray};;
                color: ${buttonColor || theme.colors.black};
                border: 1px solid ${buttonColor || theme.colors.highClearGray};`;
        default:
            return '';
    }
};

const ButtonWrapper = styled.button`
    height: ${(props) => props.height || "46px"};
    width: ${(props) => props.width || "100%"};
    border-radius: ${props => props.$borderRadius || '10px'};
    border: 0;
    font-size: ${(props) => props.fontSize || props.theme.fonts.size.normal};
    font-family: ${(props) => props.theme.fonts.variant.regular};
    margin: ${(props) => props.margin || "3px 0"};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

    ${(props) => getButtonVariant(props)}
    font-weight: ${(props) => props.fontWeight || 700};

    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }

    &:disabled {
        opacity: 0.5;
    }
`;

export { ButtonWrapper };