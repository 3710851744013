import styled from "styled-components";
import { IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

export const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  background-color: #fff;
  position: relative;
`;

export const ScrollableContent = styled.div`
  flex: 1;
  overflow-y: auto;
  margin-bottom: 16px;
  position: relative;
  padding-right: 60px;
`;

export const TopBar = styled.div`
  top: 0;
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  right: calc((100% - 800px) / 2);
`;

export const HeaderTitle = styled.h1`
  font-size: 20px;
  color: #333;
  margin: 0;
  font-weight: 500;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const BackButton = styled.button`
  padding: 8px 24px;
  background-color: white;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const SaveButton = styled.button`
  padding: 8px 24px;
  background-color: ${(props) => (props.disabled ? "#e0e0e0" : "#2196F3")};
  color: white;
  font-size: 14px;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  &:hover {
    background-color: ${(props) => (props.disabled ? "#e0e0e0" : "#1976D2")};
  }
`;

export const NameInput = styled.input`
  width: 100%;
  height: 48px;
  padding: 12px 16px;
  margin-bottom: 16px;
  border: 2px solid #bababa;
  border-radius: 10px;
  font-size: 14px;
  background-color: #fafafa;
  color: #333;
  box-shadow: none;
  transition: all 0.2s ease;

  &::placeholder {
    color: #757575;
  }

  &:focus {
    outline: none;
    border-color: #2173FF;
    box-shadow: 0 0 0 1px #2173FF15;
  }
`;

export const QuestionInput = styled.textarea`
width: 100%;
  height: 48px;
  padding: 12px 16px;
  margin-bottom: 16px;
  border: 2px solid #bababa;
  border-radius: 10px;
  font-size: 14px;
  background-color: #fafafa;
  color: #333;
  box-shadow: none;
  transition: all 0.2s ease;

  &::placeholder {
    color: #757575;
  }

  &:focus {
    outline: none;
    border-color: #2173FF;
    box-shadow: 0 0 0 1px #2173FF15;
  }
`;

export const AddQuestionButton = styled.button`
  width: 40px;
  height: 40px;
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  border: 3px solid #BABABA;
  border-radius: 8px;
  cursor: pointer;
  padding: 0;
  transition: all 0.2s ease;
  position: absolute;
  right: 16px;
  bottom: -32px;
  z-index: 10;

  &:hover {
    background-color: #F5F5F5;
  }

  span {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2.3px solid #04245c;
    border-radius: 50%;
  }

  svg {
    font-size: 16px;
    color: #2173FF;
    font-weight: bold;
  }
`;

export const QuestionCard = styled.div`
  background-color: transparent;
  border: none;
  padding: 2px 0;
  margin-bottom: 1px;
  position: relative;
`;

export const QuestionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

export const DeleteButton = styled(IconButton)`
  && {
    padding: 4px;
    color: #666;
    position: absolute;
    right: 0;
    top: 12px;
    
    svg {
      font-size: 18px;
    }
    
    &:hover {
      background-color: transparent;
      color: #333;
    }
  }
`;

export const QuestionLabel = styled.label`
  font-size: 14px;
  color: #333;
  margin-bottom: 8px;
  display: block;
  font-weight: 500;
`;

