import { styled, createGlobalStyle } from "styled-components";
import { Box } from "@mui/material";
import { RoundedButton } from "../../elements";

const MainContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  background: ${(props) => props.theme.colors.greyTen};
  padding: 10px 80px;
  border: 1px solid ${(props) => props.theme.colors.borderGray};
`;

const MainTitleContainer = styled.div`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;

  & > h4 {
    margin: 0;
  }
`;

const CustomGrid = styled.div`
  display: flex;
  gap: 10px;
  justify-content: right;
  align-items: center;
  order: 1;
  flex: 0 1 auto;
  align-self: auto;
  margin-right: 20px;
`;

const CustomSearchInput = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  border-radius: 10px;
  opacity: 0.06px;
  angle: -90deg;
  background-color: ${(props) => props.theme.colors.primaryWhite};
  border: 1px solid ${(props) => props.theme.colors.borderGray};
`;

const InputField = styled.input`
  flex: 1;
  height: 100%;
  padding-left: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 1em;
`;

const SearchIcon = styled.div`
  width: 20px;
  height: auto;
  margin-right: 10px;
  margin-left: 10px;
`;

const StyledIconButton = styled.div`
  background-color: ${(props) => props.theme.colors.primaryWhite};
  border: 1px solid ${(props) => props.theme.colors.borderGray};
  color: ${(props) => props.theme.colors.borderGray};
  padding: 8px;
  cursor: pointer;
  border-radius: 7px;
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
`;

const DataContainer = styled.div`
  display: flex;
  padding: 0 80px;
  margin-top: 50px;
`;

const CustomGenericListStyle = createGlobalStyle`
    #user-table-container {
        padding-top: 0;
    }

    #user-table-container > div > div:nth-child(2) > div > div:first-child{
        min-height: 2.5rem
    }

    #user-table-container table thead tr th:last-child div{
        display: none;
    }
    
    #user-table-container table thead tr th{
        vertical-align: middle;
    }

`;

const DividerLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.lighterGray};
  margin-bottom: 20px;
  padding: 0 80px;
`;

const VerticalDividerline = styled.div`
  width: 1px;
  height: 40px;
  background-color: ${(props) => props.theme.colors.silverGrayTwo};
  margin: 0 10px;
`;

const ActionsListToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ActionsListTitle = styled.p`
  font-size: 0.75em;
  margin: 0;
`;

const ActionStyledBox = styled(Box)`
  display: flex;
  gap: 0.5rem;
  min-width: 100px;
`;

const RolesTableContainer = styled.div`
  margin-top: 20px;
`;

export const MobileHiddenRoundedButton = styled.div`
  display: inline-block;

  @media (max-width: 768px) {
    display: none;
  }
`;

const AddButton = styled.span`
    cursor: pointer;
    border: 2px solid ${props => props.theme.colors.clearGray};
    border-radius: 8px;
    gap: 10px;
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export {
  CustomGrid,
  CustomSearchInput,
  InputField,
  SearchIcon,
  StyledIconButton,
  DataContainer,
  CustomGenericListStyle,
  DividerLine,
  VerticalDividerline,
  ActionsListToggleContainer,
  ActionsListTitle,
  MainContainer,
  MainTitleContainer,
  ActionStyledBox,
  RolesTableContainer,
  AddButton
};
