import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { observer } from "mobx-react";
import { getInvokePersona, getInvokeInterview } from "../../graphql/queries";
import ChatWindowComponent from "./ChatWindowComponent";
import { ChatInput } from "../../elements";
import { WarningModal } from "../modals";

import {
  ChatBoxContainer,
  ChatMessagesWrapper,
  ChatInputWrapper,
} from "./ChatBox.styled";
import dayjs from "dayjs";
import { useStore } from "../../hooks";


const PersonaInterviewChat = observer(({onClose}) => {
  const { authStore } = useStore();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [isSendMessageClicked, setIsSendMessageClicked] = useState(false);
  const bottomRef = useRef(null);
  const textAreaRef = useRef();
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [warningSubtitle, setWarningSubtitle] = useState("");
  const [isInterviewComplete, setIsInterviewComplete] = useState(false);

  // Start interview when component mounts
  useEffect(() => {
    startInterview();
  }, []);

  const clearInput = () => {
    setNewMessage("");
  };

  const handleCloseWarningModal = () => {
    setShowWarningModal(false);
  };
  const createPersona = async () => {
    try {
      const params = {
        //todo: check hardcoded fields
        input: {
          applicant_id: authStore.userData.id,
          interview: 'persona_interview_v1',
          user: "ann",
          configuration: [
            {
              prompt_name: "generate_persona_openai",
              model: "gpt-4o",
              key: "generate_persona"
            }
          ]
        }
      };

      const response = await API.graphql({
        query: getInvokePersona,
        variables: params,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      if (response.data) {
        const res =  JSON.parse(response.data?.getInvokePersona?.body);

        setMessages(prevMessages => [...prevMessages, {
          message: res.answer.message,
          type: "received",
          time: dayjs().format("HH:mm"),
        }]);

        res.answer.message && setTimeout(() => {
          onClose();
        }, 4000);

      }
    } catch (error) {
      setWarningSubtitle('Error creating persona profile');
      setShowWarningModal(true);
    }
  };

  const startInterview = async () => {
    //todo: check hardcoded fields
    const params = {
      input: "",
      applicant_id: authStore.userData.id,
      user: "ann",
      interview: "persona_interview_v1",
      restart: true,
    };

    try {
      setIsTyping(true);
      const response = await API.graphql({
        query: getInvokeInterview,
        variables: { input: params },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });


      if (response.data) {

        const res = JSON.parse(response.data?.getInvokeInterview?.body);

        setMessages([
          {
            message: res.answer.output,
            type: "received",
            time: dayjs().format("HH:mm"),
          },
        ]);

        if (res.answer.is_complete === "complete") {
          setIsInterviewComplete(true);
          await createPersona();
        }
      }
    } catch (error) {
      setWarningSubtitle('Error starting interview');
      setShowWarningModal(true);
    } finally {
      setIsTyping(false);
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === "" || isSendMessageClicked || isInterviewComplete) return;

    setIsSendMessageClicked(true);

    // Add user message to chat
    const updatedMessages = [
      ...messages,
      {
        message: newMessage,
        type: "sent",
        time: dayjs().format("HH:mm"),
      },
    ];
    setMessages(updatedMessages);

    //todo: check hardcoded fields
    const params = {
      input: newMessage,
      applicant_id: authStore.userData.id,
      user: "ann",
      interview: "persona_interview_v1",
      restart: false,
    };

    try {
      setIsTyping(true);
      const response = await API.graphql({
        query: getInvokeInterview,
        variables: { input: params },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      if (response.data) {

        const res =  JSON.parse(response.data?.getInvokeInterview?.body);

        setMessages([
          ...updatedMessages,
          {
            message: res.answer.output,
            type: "received",
            time: dayjs().format("HH:mm"),
          },
        ]);

        if (res.answer.is_complete === "complete") {
          setIsInterviewComplete(true);
          await createPersona();
        }
      }
    } catch (error) {
      setWarningSubtitle('Error sending interview');
      setShowWarningModal(true);
    } finally {
      setIsTyping(false);
      setIsSendMessageClicked(false);
      setNewMessage("");
    }
  };

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <ChatBoxContainer>
      <ChatMessagesWrapper>
        <ChatWindowComponent
          messages={messages}
          bottomRef={bottomRef}
          width={"inherit"}
          height={"inherit"}
          agentIsTyping={isTyping}
          backgroundColor={"#FAFAFA"}
          selectedAgent={{name: "Interviewer"}}
        />
      </ChatMessagesWrapper>

      <ChatInputWrapper>
        <ChatInput
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={(e, callback) => {
            if (e.key === "Enter" && !e.shiftKey && !isTyping) {
              e.preventDefault();
              if (!isSendMessageClicked && !isInterviewComplete) {
                handleSendMessage();
                clearInput();
              }
            }
          }}
          newMessage={newMessage}
          handleSendMessage={handleSendMessage}
          textAreaRef={textAreaRef}
          disableSend={isSendMessageClicked || isInterviewComplete}
          width="80%"
        />
      </ChatInputWrapper>

      <WarningModal
          isOpen={showWarningModal}
          onClose={handleCloseWarningModal}
          subtitle={warningSubtitle}
          height="300px"
          width="400px"
      />
    </ChatBoxContainer>
  );
});

export default PersonaInterviewChat;
