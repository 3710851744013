import React, { useState } from "react";
import Widget from "./Widget";
import {
  SubTitle,
  InfoWrapper,
  InfoList,
  EditContainer,
} from "./Widget.styled";
import { useStore } from "../../../../../hooks";
import { observer } from "mobx-react-lite";
import Spinner from "../../../../../components/common/Spinner";
import CheckIcon from "./CheckIcon";
import NoDataAvaliable from "./NoDataAvaliable";
import { SaveSingleContactModal } from "../../../../ContactPage/components";

const GeneralInfo = observer(() => {
  const [initialData, setInitialData] = useState({});
  const [isOpeningEditModal, setIsOpeningEditModal] = useState(false);
  const { unifiedCustomerProfileStore, authStore, contactStore } = useStore();
  const [createContactModal, setCreateContactModal] = useState(false);

  const contactInfo = unifiedCustomerProfileStore.contactInfo[0] || {};

  const getSingleContactData = async () => {
    try {
      const { customer } = authStore.userData;
      const { contact_id } = contactInfo;
      const response = await contactStore.getSingleContact(
        customer,
        String(contact_id)
      );
      const data = JSON.parse(response.data.getSingleContact.body);
      const formattedData = {
        parameters: {
          contact_id: contact_id ? contact_id : "",
          full_name: data.full_name,
          company_name: data.company_name,
          email_addresses: JSON.parse(data.email_addresses),
          phone_numbers: JSON.parse(data.phone_numbers),
          addresses: JSON.parse(data.addresses),
        },
      };
      setInitialData(formattedData);
      setCreateContactModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  const openEditSingleContact = async () => {
    setIsOpeningEditModal(true);
    if (!contactStore.isLoading) {
      await getSingleContactData();
      setIsOpeningEditModal(false);
    }
  };

  return (
    <>

      <SaveSingleContactModal
        createContactModal={createContactModal}
        setCreateContactModal={setCreateContactModal}
        initialData={initialData}
      />
      <Widget
        title="Contact Info"
        height={Object.keys(contactInfo).length === 0 ? "auto" : "360px"}
        actions={
          <EditContainer isLoading={isOpeningEditModal} onClick={openEditSingleContact}>Edit</EditContainer>
        }
      >
        {unifiedCustomerProfileStore.isLoading ? (
          <Spinner />
        ) : (
          <InfoWrapper>
            {Object.keys(contactInfo).length === 0 ? (
              <NoDataAvaliable />
            ) : (
              <InfoList>
                <li>
                  <SubTitle>Name:</SubTitle>
                  <span>{contactInfo.contact_full_name}</span>
                </li>
                <li>
                  <SubTitle>Phone number:</SubTitle>
                  <span>{contactInfo.contact_phone_number}</span>
                </li>
                <li>
                  <SubTitle>Email:</SubTitle>
                  <span>{contactInfo.contact_email}</span>
                </li>
                <li>
                  <SubTitle>State:</SubTitle>
                  <span>{contactInfo.contact_state}</span>
                </li>
                <li>
                  <SubTitle>City:</SubTitle>
                  <span>{contactInfo.contact_city}</span>
                </li>
                <li>
                  <SubTitle>Address:</SubTitle>
                  <span>{contactInfo.contact_address}</span>
                </li>
                <li>
                  <SubTitle>Company name:</SubTitle>
                  <span>{contactInfo.contact_company_name}</span>
                </li>
                <li>
                  <SubTitle>Postal code:</SubTitle>
                  <span>{contactInfo.contact_postal_code}</span>
                </li>
                <li>
                  <SubTitle>Email Allow:</SubTitle>
                  <CheckIcon value={contactInfo.do_not_email} />
                </li>
                <li>
                  <SubTitle>Call Allow:</SubTitle>
                  <CheckIcon value={contactInfo.do_not_call} />
                </li>
                <li>
                  <SubTitle>Mail Allow:</SubTitle>
                  <CheckIcon value={contactInfo.do_not_mail} />
                </li>
              </InfoList>
            )}
          </InfoWrapper>
        )}
      </Widget>
    </>
  );
});

export default GeneralInfo;
