import { action, makeObservable, observable, flow } from "mobx";
import { API } from "aws-amplify";
import { getLogs } from "../../graphql/queries";

class AuditLogStore {
  auditLogs = [];
  totalRowCount = 0;
  isLoading = false;
  isError = false;

  constructor() {
    makeObservable(this, {
      auditLogs: observable,
      totalRowCount: observable,
      isLoading: observable,
      isError: observable,
      fetchAuditLogs: flow,
      setError: action,
      clearError: action,
    });
  }

  *fetchAuditLogs({ account, pageIndex, pageSize, sortBy, search }) {
    try {
      this.isLoading = true;
      this.isError = false;

      const validPageIndex = pageIndex !== null ? pageIndex : 0;

      const parameters = {
        count_records: false,
        page_size: pageSize || 10,
        page: validPageIndex + 1,
      };

      if (search && search.trim()) {
        parameters.search = search.trim();
      }

      if (sortBy && sortBy.length > 0) {
        parameters.order_by = {
          id: sortBy[0].id || "insert_date",
          desc: sortBy[0].desc || false,
        };
      }

      if (parameters.page === 1) {
        parameters.count_records = true;
      }

      const response = yield API.graphql({
        query: getLogs,
        variables: {
          input: {
            account: account,
            topic: "conversation_event",
            parameters: JSON.stringify(parameters),
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const responseBody = JSON.parse(response.data.getLogs.body);

      const logs = responseBody.items || [];

      this.auditLogs = logs.map((log) => ({
        id: log.id,
        topic: log.topic,
        status: log.status,
        action: log.action,
        user_id: log.user_id,
        description: log.description,
        insert_date: new Date(log?.insert_date).toISOString(),
        ...log,
      }));

      this.totalRowCount = responseBody.next_page === null ? logs.length : this.totalRowCount;

    } catch (error) {
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  }

  setError(isError) {
    this.isError = isError;
  }

  clearError() {
    this.isError = false;
  }
}

export default AuditLogStore;
