import { action, makeObservable, observable } from "mobx";
import { API } from "aws-amplify";
import { getUrlFile, getUsers, putUser } from "../../graphql/queries";
import { useUploadFile } from "../../components/app/hooks/useUploadFile";
import axios from "axios";
import { flow } from "lodash";

class ProfileStore {
    first_name = '';
    last_name = '';
    email = '';
    designation = '';
    phone = '';
    customPersonaKnowledgeDescription = '';
    customPersonaResponseDescription = '';
    userData = {};
    isUpdatingProfile = false;
    isLoading = false;
    changeDetected = false;
    displayPhotoFile = null;
    s3UserFilesRoute = "user_files";
    displayPhotoFileName = "display_photo";
    displayPhotoURL = null;
    selectedTab = 0;

    isSuccessfullModalOpen = false;

    constructor() {
        makeObservable(this, {
            first_name: observable,
            last_name: observable,
            email: observable,
            designation: observable,
            phone: observable,
            userData: observable,
            customPersonaKnowledgeDescription: observable,
            customPersonaResponseDescription: observable,
            isSuccessfullModalOpen: observable,
            isUpdatingProfile: observable,
            isLoading: observable,
            changeDetected: observable,
            displayPhotoURL: observable,
            selectedTab: observable,
            fetchUserData: action,
            loadFile: action,
            updateUser: action,
            onChangeFirstName: action,
            onChangeLastName: action,
            onChangeDesignation: action,
            onChangePhone: action,
            onChangeEmail: action,
            onChangePersonaKnowledgeDescription: action,
            onChangePersonaResponseDescription: action,
            onChangeDisplayPhotoURL: action,
            setIsSuccessfulModalOpen: action,
            setIsUpdatingProfile: action,
            updateUserData: action,
            setSelectedTab: action,
        });
    }

    fetchUserData = async (userId, customerId) => {
        try {
            this.isLoading = true;
            const response = await API.graphql({
                query: getUsers,
                variables: { input: { customer: customerId, id: userId } },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });

            const userData = JSON.parse(response.data?.getUsers?.body);
            this.userData = userData;
            this.first_name = userData.first_name;
            this.last_name = userData.last_name;
            this.email = userData.email;
            this.designation = userData.designation;
            this.phone = userData.phone;
            this.customPersonaKnowledgeDescription = userData.customPersonaKnowledgeDescription || 'My primary interests are [list key interests related to your field, e.g., improving user experience, optimizing workflows, etc.]. The context of my work involves [briefly describe the environment or context in which you work, e.g., remote team, agile methodology, etc.].';
            this.customPersonaResponseDescription = userData.customPersonaResponseDescription || 'Provide clear and concise responses that are easy to understand without unnecessary jargon.';
            this.isLoading = false;
            await this.loadFile();

        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    }

    loadFile = async () => {
        try {
            if (!this.userData.display_photo_url) return;
            this.isLoading = true;
            this.isError = false;
            const response = await API.graphql({
                query: getUrlFile,
                variables: {
                    input: {
                        customer: this.userData.customer,
                        file_name: this.displayPhotoFileName,
                        file_route: this.s3UserFilesRoute,
                        user: this.userData.id,
                    },
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
    
            const uploadUrl = JSON.parse(response.data.getUrlFile?.body);
            const res = await axios({ url: uploadUrl, method: 'GET', responseType: 'blob' })
            const blobRes = new Blob([res.data]);
            this.displayPhotoURL = URL.createObjectURL(blobRes);
        } catch (error) {
            console.error("Error getting user display photo:", error)
        } finally{
            this.isLoading = false;
        }
    }

    updateUser = async () => {
        try {
            if (!this.changeDetected && !this.displayPhotoFile) {
                return;
            }
            
            this.isUpdatingProfile = true;


            if (this.displayPhotoFile) {
                
                const { error, uploadUrl } = await useUploadFile(
                    this.displayPhotoFile,
                    this.userData.customer,
                    this.s3UserFilesRoute,
                    this.userData.id,
                    this.displayPhotoFileName,
                    true,
                )
                if (!error && uploadUrl) {
                    this.userData.display_photo_url = uploadUrl;
                    this.displayPhotoFile = null;
                } else {
                    return { error }
                }
            }

            const {
                id,
                first_name,
                last_name,
                email,
                phone,
                customer,
                role_group,
                title,
                union,
                user_persona,
                display_photo_url
            } = this.userData;

            const updatedUserData = {
                id,
                first_name,
                last_name,
                email,
                phone,
                customer,
                metadata: "{}",
                role_group,
                title: title ?? '',
                union: union ?? '',
                user_persona: user_persona ?? '',
                display_photo_url: display_photo_url ?? '',
            };

            await API.graphql({
                query: putUser,
                variables: { input: updatedUserData },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });

            this.changeDetected = false;
            this.isUpdatingProfile = false;
            this.isSuccessfullModalOpen = true;
            return true;
        } catch (error) {
            console.error("Error updating user data:", error);
            throw new Error("Error updating user data");
        }
    }

    updateUserData(key, value){
        this.changeDetected = true;
        this.userData[key] = value;
    }

    onChangeDisplayPhotoFile = action((value) => {
        this.displayPhotoFile = value;
    });

    onChangeEmail = action((value) => {
        this.email = value.trim();
    });

    onChangeFirstName = action((value) => {
        this.first_name = value.trim();
    });

    onChangeLastName = action((value) => {
        this.last_name = value.trim();
    });

    onChangeDesignation = action((value) => {
        this.designation = value.trim();
    });

    onChangePhone = action((value) => {
        this.phone = value.trim();
    });

    onChangePersonaKnowledgeDescription = action((value) => {
        this.customPersonaKnowledgeDescription = value;
    });

    onChangePersonaResponseDescription = action((value) => {
        this.customPersonaResponseDescription = value;
    });

    setIsSuccessfulModalOpen = action((value) => {
        this.isSuccessfullModalOpen = value;
    });

    setIsUpdatingProfile = action((value) => {
        this.isUpdatingProfile = value;
    });

    onChangeDisplayPhotoURL = action((value) => {
        this.displayPhotoURL = value;
    });

    setSelectedTab(index){
        this.selectedTab = index;
    }
}

export default ProfileStore;
