import React, { useRef } from "react";
import {
  WidgetContainer,
  WidgetTitle,
  WidgetContent,
  WidgetHeader,
  WidgetDetails,
  DataAvaliableWrapper,
} from "./Widget.styled";
import AISuggestionButton from "./AISuggestionButton";
import { useStore } from "../../../../../hooks";
import { Icon } from "../../../../../elements";
import styled from "styled-components";

const ChildrenWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const Widget = ({ 
  title, 
  height, 
  maxheight, 
  padding, 
  maxwidth, 
  children, 
  actions,
  cardType 
}) => {
  const { assistanceUCPStore } = useStore();
  const containerRef = useRef(null);

  const handleSuggestionClick = () => {
    assistanceUCPStore.setShowSuggestions(true);
    assistanceUCPStore.setCurrentCardType(cardType);
  };

  return (
    <WidgetContainer 
      height={height} 
      maxheight={maxheight} 
      maxwidth={maxwidth} 
      padding={padding}
      ref={containerRef}
    >
      <WidgetHeader>
        <WidgetTitle>{title}</WidgetTitle>
        <WidgetDetails>
          {actions}
          {cardType && <AISuggestionButton onClick={handleSuggestionClick} />}
        </WidgetDetails>
      </WidgetHeader>
      <WidgetContent>
        {children ? (
          <ChildrenWrapper>
            {children}
          </ChildrenWrapper>
        ) : (
          <DataAvaliableWrapper id="dataAvaliableWrapper">
            <p>No data available</p>
            <p>
              No data is available for this card at the moment. Please check
              again later.
            </p>
            <p>
              <Icon name={"noDataAvaliable"} width={"70px"} height={"68px"} />
            </p>
          </DataAvaliableWrapper>
        )}
      </WidgetContent>
    </WidgetContainer>
  );
};

export default Widget;
