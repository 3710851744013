import React from "react";
import Widget from "./Widget";
import {
  SubTitle,
  AppointmentsWrapper,
} from "./Widget.styled";
import { useStore } from "../../../../../hooks";
import { observer } from "mobx-react-lite";
import Spinner from "../../../../../components/common/Spinner";
import FormattedDate from "./FormattedDate";
import NoDataAvaliable from "./NoDataAvaliable";

const Appointments = observer(() => {
  const { unifiedCustomerProfileStore } = useStore();
  const appointments = unifiedCustomerProfileStore.appointmentsInfo || [];

  return (
    <Widget 
      title="Appointments" 
      height={appointments.length === 0 ? "auto" : appointments.length === 1 ? "230px" : "370px"}
      cardType="appointments"
    >
      {unifiedCustomerProfileStore.isLoading ? (
        <Spinner />
      ) : (
        <>
          {appointments.length === 0 ? (
            <NoDataAvaliable/>
          ) : (
            <AppointmentsWrapper>
              {appointments.map((apt, index) => (
                <ul key={index}>
                  <li>
                    <SubTitle>Appointment number:</SubTitle>
                    <span>{apt.apt_number}</span>
                  </li>
                  <li>
                    <SubTitle>Service type:</SubTitle>
                    <span>{apt.apt_name}</span>
                  </li>
                  <li>
                    <SubTitle>Appointment date:</SubTitle>
                     <FormattedDate date={apt.apt_date} />
                  </li>
                  <li>
                    <SubTitle>Appointment time:</SubTitle>
                    <span>{apt.apt_time}</span>
                  </li>
                  <li>
                    <SubTitle>Description:</SubTitle>
                    <span title={apt.apt_description}>
                      {apt.apt_description}
                    </span>
                  </li>
                </ul>
              ))}
            </AppointmentsWrapper>
          )}
        </>
      )}
    </Widget>
  );
});

export default Appointments;
