import React from 'react';
import { BadgeContainer } from './StatusColumn.styled';

const StatusColumn = ({ status, colors }) => {
    const getStatusColor = () => {
      switch (status) {
        case 'Open':
          return colors.greenTea;
        case 'Closed':
          return colors.jordyBlue;
        case 'In progress':
          return colors.greyEight;
        case 'COMPLETED':
          return colors.lightBlue;
        default:
          return colors.davysGrey;
      }
    };
  
    return <BadgeContainer color={getStatusColor()}>{status}</BadgeContainer>;
  };
  
  export default StatusColumn;
  