import React, { useRef } from 'react';
import { Tooltip } from '@mui/material';
import { DescriptionText } from './TooltipTable.styled';

const TooltipTable = ({ description }) => {
  const descriptionRef = useRef(null);

  return (
    <Tooltip title={description} placement="top">
      <DescriptionText ref={descriptionRef}>{description}</DescriptionText>
    </Tooltip>
  );
};

export default TooltipTable;
