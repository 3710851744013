import styled from "styled-components";
import { DefaultTheme as theme } from "../../../../../theme";

export const SegmentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const muiTablePaperProps = {
  sx: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: "100%",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
};

export const muiTableHeadCellProps = {
  sx: {
    backgroundColor: theme.colors.highClearGray,
    color: theme.colors.darkGray,
    minHeight: "15px",
    maxHeight: "15px",
    borderBottom: "none",
    fontFamily: "Inter",
    paddingInline: "10px",
    "& .MuiSvgIcon-root": {
      color: theme.colors.darkGray,
      disableRipple: true,
    },
    "& .MuiCheckbox-root ": {
      padding: "0",
    },
    "& .MuiCheckbox-root path": {
      strokeWidth: 2,
    },
  },
};

export const displayColumnDefOptions = {
  "mrt-row-select": {
    maxSize: 80,
    muiTableHeadCellProps: {
      sx: {
        paddingInlineStart: "20px",
        borderBottomWidth: 0,
        backgroundColor: theme.colors.highClearGray,
      },
    },
    muiTableBodyCellProps: {
      sx: {
        paddingInlineStart: "20px",
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.silverGrayTwo,
        borderBottomStyle: "solid",
      },
    },
  },
};

export const muiTableBodyCellProps = {
  sx: {
    padding: "10px",
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.silverGrayTwo,
    borderBottomStyle: "solid",
    fontFamily: "Inter",
    "& .MuiSvgIcon-root": {
      color: theme.colors.darkGray,
      padding: 0,
      disableRipple: true,
    },
    "& .MuiCheckbox-root ": {
      padding: "0",
    },
    "& .MuiCheckbox-root path": {
      strokeWidth: 2,
    },
  },
};

export const TopToolbarMarketingActions = styled.div`
  display:flex;
  gap: 5px;
  width: 400px;
  > button {
    font-weight: 400;
  }
`;
