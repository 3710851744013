import { useEffect, useState } from 'react';
import { 
    CustomTextArea,
    Wrapper,
    Header,
    Title,
    ExpandIcon, ExpandIconContent, CustomContent } from './InstructionsBox.styled';
import ExpandImage from '../../icon/assets/expand_content.svg'
import RoundedIconButton from '../Buttons/RoundedIconButton/RoundedIconButton';
import { useStore } from '../../../hooks';
import { InstructionsHelperComponent } from '../../../pages/AgentPage/PlaygroundPage/components/instructionsHelper';
import { observer } from 'mobx-react';

const InstructionsBox = observer(({value, onChange, placeholder, width, toggleModal, instructionsHelper=true, title, className}) => {

    const [ instructionsValue, setInstructionsValue ] = useState();
    const [showInstructionsModal, setShowInstructionsModal] = useState(false);
    const { authStore } = useStore();
    const updateInstructions = (value) => {
        onChange(value)
        setInstructionsValue(value)
    }

    useEffect(() => {
        setInstructionsValue(value);
    }, [value])

    return (
        <Wrapper>
            <Header>
                <Title>{title ?? "Instructions"}</Title>
                {instructionsHelper && <RoundedIconButton width='24px' height='24px' icon='instructionsEdit' onClick={() => setShowInstructionsModal(true)}/>}
            </Header>
            <CustomContent>
                <CustomTextArea className={className}
                    $width={width}
                    placeholder={placeholder ?? 'Feel free to enter the correct answer right here.' }
                    rows="4" 
                    value={instructionsValue}
                    onChange={(e) => updateInstructions(e.target.value)} 
                />
                <ExpandIconContent >
                    <ExpandIcon src={ExpandImage} onClick={toggleModal} />
                </ExpandIconContent>
            </CustomContent>

            {showInstructionsModal && <InstructionsHelperComponent
              isOpen={showInstructionsModal}
              initialTitle={instructionsValue}
              onClose={() => setShowInstructionsModal(false)}
              customer={authStore.selectedAccount.id}
            />}
        </Wrapper>
    );
});

export default InstructionsBox