import { API } from "aws-amplify";
import { getUploadUrlFile } from "../../../graphql/queries";
import axios from "axios";

export async function useUploadFile(file, customer, fileRoute, userId="", fileName, overwrite=false) {
    try {
        const response = await API.graphql({
            query: getUploadUrlFile,
            variables: {
                input: {
                    customer: customer,
                    file_name: fileName ?? file.name,
                    user: userId,
                    file_type: file.type,
                    file_route: fileRoute,
                    overwrite,
                },
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        });

        const uploadUrl = JSON.parse(
            response.data.getUploadUrlFile?.body
        );

        const formData = new FormData();
        formData.append("file", file);

        const config = {
            headers: {
                "Content-Type": file.type,
            },
        };

        await axios.put(uploadUrl, file, config);

        const result = {
            uploadUrl,
            fileName: file.name,
        };

        return result;
    } catch (error) {
        return { error: `Error uploading file, please try again later.` }
    }
}