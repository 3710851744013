import React, {useEffect, useState} from 'react';
import {
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalTitle,
    CloseButton,
    ChatBoxContainer
} from "../ProfilePage.styled"
import PersonaInterviewChat from "../../../../elements/ChatBox/PersonaInterviewChat";

import {observer} from "mobx-react";
import {useStore} from "../../../../hooks";

const UserPersonaModal = observer(({ isOpen, onClose, firstAnswer, secondAnswer }) => {
    const { agentStore } = useStore();
    const { conciergeAgent } = agentStore;

    if (!isOpen) return null;

    return (
        <ModalOverlay>
            <ModalContent>
                <ModalHeader>
                    <ModalTitle>Persona builder</ModalTitle>
                </ModalHeader>
                <ChatBoxContainer>
                    <PersonaInterviewChat onClose={onClose}/>
                </ChatBoxContainer>
            </ModalContent>
        </ModalOverlay>
    );
});

export default UserPersonaModal;
