import React from "react";
import { Route, Routes } from "react-router-dom";
import QuestionsForm from "./QuestionsForm";
import QuestionnairesList from "./QuestionnairesList"; // <-- Corrected import

const QuestionnairesPage = () => {
  return (
    <Routes>
      <Route index element={<QuestionnairesList />} />
      <Route path="/create" element={<QuestionsForm />} />
    </Routes>
  );
};

export default QuestionnairesPage;
