import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 10000;
  width: auto;
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  justify-content: flex-end;
`;

export const ModalBox = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 350px;
  border-left: 5px solid ${(props) => props.theme.colors.activeBlue};
  position: relative;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const IconWrapper = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

export const CheckMark = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CheckIcon = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
`;

export const Title = styled.h2`
  margin: 0;
  font-size: 18px;
  color: ${(props) => props.theme.colors.activeBlue};
`;

export const Message = styled.p`
  color: ${(props) => props.theme.colors.gray};
  text-align: left;
  margin: 0;
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
`;