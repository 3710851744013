export const useTabs = () => {
    return [
        {
            label: "Profile",
        },
        {
            label: "User persona",
        },
    ];
};
