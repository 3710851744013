import React, { useState, useMemo } from "react";
import { observer } from 'mobx-react';
import { useStore } from "../../../../../hooks";
import { CardContainer, LoadMoreContainer } from "./AgentTabs.styled";
import { Loading } from "../../../../../components/loading";
import ModalAgentsDetail from "../../components/ModalAgentsDetail/ModalAgentsDetail";
import LogoAnn from "../../../../../elements/icon/assets/logo_ann_customer.svg";
import AgentCard from "../../../AgentsList/components/AgentCard";
import { useNavigate } from "react-router-dom";
import { RoundedButton } from "../../../../../elements";

const AgentsTabs = observer(() => {
    const navigate = useNavigate();
    const { agentStore } = useStore();
    const { viewAgentStore } = agentStore;
    
    const agentsFiltered = useMemo(() => {
        let filteredAgents = agentStore.agents
            .filter(assistant => assistant.domain !== 'concierge' && assistant.provider === 'openai');

        if (viewAgentStore.searchValue) {
            filteredAgents = filteredAgents.filter(assistant => 
                assistant.name.toLowerCase().includes(viewAgentStore.searchValue.toLowerCase())
            );
        }

        return filteredAgents;
    }, [agentStore.agents, viewAgentStore.searchValue]);

    const [isModalOpen, setModalOpen] = useState(false);

    const handleOpenModal = (assistant) => {
        const starters = assistant.conversation_starters.filter((starter)=>starter);
        const content = {
            id: assistant.id,
            image: assistant?.photo_url || LogoAnn,
            name: assistant.name,
            type: "Agent",
            description1: assistant.job_title,
            description2: assistant.prompt,
            conversations: 4,
            members: 1,
            starters: [],
            knowledgeBase: assistant.knowledge_base.map((filePath) =>
                filePath.replace("s3://", "").split("/").splice(-1).pop()),
            conversation_starters: starters || [],
            visibility: "draft",
            actions: assistant.action || [],
            original: assistant
        }
        agentStore.onSelectedAgent(content);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleEdit = (assistant) => {
        navigate({
          pathname: "/v2/assistants/playground",
          search: `agent=${assistant.id}&customer=${assistant.customer_id}`,
        });
      };

      const handleUse = (assistant) => {
        navigate({
          pathname: "/home",
          search: `agent=${assistant.id}&customer=${assistant.customer_id}`,
        });
      };

    return (
        <>
            {viewAgentStore.isLoading && <Loading />}
            {!viewAgentStore.isLoading && (  
                <CardContainer>
                {agentsFiltered.slice(0, viewAgentStore.currentAgentPaginator).map((assistant, index) => (
                  <AgentCard
                    key={index}
                    avatar={assistant?.photo_url || LogoAnn}
                    title={assistant.name}
                    description={assistant.prompt || "..."}
                    updatedBy={"System"}
                    date={assistant.insert_date || "Unknown"}
                    onClick={() => handleOpenModal(assistant)}
                    onEdit={() => handleEdit(assistant)}
                    onUse={() => handleUse(assistant)}
                  />
                ))}
              </CardContainer>
            )}
            {agentStore.selectedAgent && (
                <ModalAgentsDetail open={isModalOpen} onClose={handleCloseModal} />
            )}     
            {agentsFiltered.length > viewAgentStore.currentAgentPaginator && (
                <LoadMoreContainer>
                    <RoundedButton width={'200px'} onClick={viewAgentStore.onHandleLoadMore}>
                        Load More
                    </RoundedButton>
                </LoadMoreContainer>
            )}
        </>
    );
});

export default AgentsTabs;