import React from 'react';
import { observer } from "mobx-react-lite";
import ChatBox from "./components/ChatBox";

const ChatWidgetComponent = observer(() => {
    const agentInfo = {
        id:"asst_b3OY6YrWPqJrtRPBj8HwyBh7",
        customer_id:"iv6wyozw",
        job_title:"",
        extended_answer:false,
        name:"Test Agent",
        prompt:"Info about any car",
        human_instructions:"Info about any car",
        add_to_concierge:true,
        insert_date:"2024-11-14 17:04:55.084781",
        last_modified:"2024-11-14 17:04:55.084781",
        active:true,
        provider:"openai",
        status:"DRAFT"
    };
    return (
        <ChatBox
            customerSelected={"iv6wyozw"}
            agentId={"asst_b3OY6YrWPqJrtRPBj8HwyBh7"}
            agentDomain={false}
            agentData={agentInfo}
        />
    );
});

export default ChatWidgetComponent;
