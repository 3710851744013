import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import dayjs from "dayjs";
import StatusBadge from "../../../../../elements/StatusBadge";
import { observer } from "mobx-react";
import { useStore } from "../../../../../hooks";
import { Icon, RoundedButton, SuccessfulModal } from "../../../../../elements";
import {
  muiTableBodyCellProps,
  displayColumnDefOptions,
  muiTableHeadCellProps,
  muiTablePaperProps,
  TopToolbarMarketingActions,
} from "./MarketingTab.styled";
import { TopToolbar } from "../../../../../elements/sharedStyles/TopToolbar";
import { MediaQuery } from "../../../../../elements/sharedStyles/MediaQuery";
import { useMediaQueries } from "../../../../../utils/mediaQueries";
import { getDensity } from "../../../../../utils/GetDensity/GetDensity";
import { createSearchParams, useNavigate } from "react-router-dom";
import { DefaultTheme } from "../../../../../theme";
import { ConfirmationModal } from "../../../../../elements/v2/modals";
import SuccessModal from "../../../../../elements/v2/SuccessModal/SuccessModal";

const actionOptionNames = {
  DUPLICATE: "Duplicate",
  ARCHIVE: "Archive"
}

const MarketingTab = observer(() => {
  const navigate = useNavigate();
  const { authStore, marketingStore } = useStore();
  const { isMobile, isTablet, isDesktop } = useMediaQueries();
  const density = useMemo(
    () => getDensity(isMobile, isTablet, isDesktop),
    [isMobile, isTablet, isDesktop]
  );
  const [sorting] = useState([{ id: "contact_name", desc: false }]);
  const [clickedOption, setClickedOption] = useState(null);

  const actionsOptions = useMemo(
    () => [
      {
        name: actionOptionNames.DUPLICATE,
        title: "Duplicate Conversation",
        icon: "duplicateOutline",
        onClick: () => {
          marketingStore.setMarketingAttribute(
            "confirmationDialogMessage",
            `You are about to duplicate this conversation. This action will create an exact copy of the conversation. Are you sure you want to proceed?`
          );
        },
        onConfirm: async () => {
          await marketingStore.duplicate(
            marketingStore.selectedRows[0].original
          );
          marketingStore.getMarketingConversations(
            authStore.selectedAccount.id
          );
        },
      },
      {
        name: actionOptionNames.ARCHIVE,
        title: "Archive",
        icon: "deleteGray",
        onClick: () => {
          marketingStore.setMarketingAttribute(
            "confirmationDialogMessage",
            `You are about to archive this set of conversations. Once archived, it cannot be restored. Are you sure you want to proceed?`
          );
        },
        onConfirm: async () => {
          const archivedRowsSelected = marketingStore.selectedRows.map((row) => {
            const { status, id } = row.original;
            if (status === "DRAFT") return id;
          })
          await marketingStore.deleteCampaignMarketing(authStore.selectedAccount.id, archivedRowsSelected[0])
          marketingStore.getMarketingConversations(authStore.selectedAccount.id);
          table.toggleAllRowsSelected(false);
        },
      },
    ],
    [marketingStore]
  );

  useEffect(() => {
    if (authStore.selectedAccount) {
      marketingStore.getMarketingConversations(authStore.selectedAccount.id);
    }
  }, [authStore.selectedAccount]);

  const navigateToMarketingView = (id) => {
    navigate({
      pathname: "/v2/conversations/marketing",
      search: createSearchParams({
        id: id,
      }).toString(),
    });
  };

  const onCloseConfirmationModal = () => {
    marketingStore.clearConfirmationDialog();
    setClickedOption(null);
  };

  useEffect(() => {
    let alertTimeout;
    if (marketingStore.successfulAlert.isOpen) {
      alertTimeout = setTimeout(() => {
        marketingStore.setMarketingAttribute("successfulAlert", {
          isOpen: false,
          title: "",
          message: "",
        });
      }, 3000);
    }

    return () => {
      clearTimeout(alertTimeout);
    };
  }, [marketingStore.successfulAlert.isOpen]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ renderedCellValue }) => {
          return (
            <StatusBadge status={renderedCellValue}>
              {renderedCellValue}
            </StatusBadge>
          );
        },
      },
      {
        accessorKey: "name",
        header: "Marketing Name",
      },
      {
        accessorKey: "metadata",
        header: "Contacts",
        Cell: ({ cell }) => cell.getValue()?.segment_count || 0,
      },
      {
        accessorKey: "sent",
        header: "Sent",
      },
      {
        accessorKey: "reply",
        header: "Reply",
      },
      {
        accessorKey: "start_datetime",
        header: "Start date",
        filterVariant: "date",
        sortingFn: "datetime",
        Cell: ({ cell }) =>
          cell.getValue()
            ? dayjs(cell.getValue()).format("MM/DD/YYYY - HH:mm")
            : "---",
      },
      {
        accessorKey: "end_datetime",
        header: "End date",
        filterVariant: "date",
        sortingFn: "datetime",
        Cell: ({ cell }) =>
          cell.getValue()
            ? dayjs(cell.getValue()).format("MM/DD/YYYY - HH:mm")
            : "---",
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    muiTopToolbarProps: {
      component: TopToolbar,
    },
    muiTableContainerProps: {
      sx: MediaQuery,
    },
    data: marketingStore.marketingConversations,
    enableRowSelection: true,
    enableSorting: false,
    enableColumnFilters: false,
    showGlobalFilter: false,
    enableHiding: true,
    enableColumnActions: false,
    enableColumnResizing: false,
    enableFullScreenToggle: true,
    enableDensityToggle: true,
    enableTopToolbar: true,
    positionToolbarAlertBanner: "none",
    initialState: {
      sorting,
      density: density,
    },
    renderTopToolbarCustomActions: ({ table }) => {
      const rows = table.getSelectedRowModel()?.rows;
      const someStatusIsNotDraft = rows.some((row) => {
        const { status } = row._valuesCache;
        return status !== "DRAFT";
      });

      return (
        <TopToolbarMarketingActions>
        {actionsOptions.map((action) => {
          const shouldRender = 
            (action.name === actionOptionNames.DUPLICATE && rows.length === 1) ||
            (action.name === actionOptionNames.ARCHIVE && rows.length === 1 && !someStatusIsNotDraft);
          
          if (!shouldRender) return null;

          return (
            <RoundedButton
              key={action.name}
              width="123px"
              height="40px"
              kind="actions"
              fontSize="1em"
              onClick={() => {
                setClickedOption(action);
                marketingStore.setMarketingAttribute("selectedRows", rows);
                action.onClick();
              }}
            >
              <Icon name={action.icon} height="16px" width="16px" />
              {action.name}
            </RoundedButton>
          );
        })}
      </TopToolbarMarketingActions>
  
      );
    },
    muiTablePaperProps,
    displayColumnDefOptions,
    muiTableHeadCellProps,
    muiTableBodyCellProps,
    positionActionsColumn: "last",
    paginationDisplayMode: "pages",
    state: {
      isLoading: marketingStore.isLoading,
    },
    muiPaginationProps: {
      rowsPerPageOptions: [10, 13, 15, 17, 19, 21, 30, 40],
      showFirstButton: false,
      showLastButton: false,
      labelRowsPerPage: "Show",
      showRowsPerPage: true,
      variant: "outlined",
      shape: "rounded",
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => navigateToMarketingView(row.original.id),
      sx: {
        cursor: "pointer",
      },
    }),
  });

  return (
    <>
      <MaterialReactTable table={table} />
      {marketingStore.isError && (
        <SuccessfulModal
          isOpen={marketingStore.isError}
          onClose={() => marketingStore.onClearError()}
          title="Oops!"
          subtitle="An error occurred."
          subtitle2="Please try again later."
          zIndex={true}
        />
      )}
      <ConfirmationModal
        isOpen={clickedOption}
        title={clickedOption?.title}
        message={marketingStore.confirmationDialogMessage}
        icon={clickedOption?.icon}
        onConfirm={() => {
          clickedOption?.onConfirm?.();
          setClickedOption(null);
        }}
        onClose={onCloseConfirmationModal}
        onCancel={onCloseConfirmationModal}
        onConfirmLabel="Continue"
      />
      <SuccessModal
        isOpen={marketingStore.successfulAlert.isOpen}
        icon="check_circle"
        onClose={() =>
          marketingStore.setMarketingAttribute("successfulAlert", {
            isOpen: false,
            title: "",
            message: "",
          })
        }
        title={marketingStore.successfulAlert.title}
        message={marketingStore.successfulAlert.message}
      />
    </>
  );
});

export default MarketingTab;
