import { useState, useEffect, useCallback } from 'react';
import RuleChoice from './RuleChoice';

import {
  RuleChoiceGroupWrapper
} from './RuleChoiceGroup.styled';

const RuleChoiceGroup = ({ dataTestId, defaultValue = '', options, onChange, disable }) => {
  const [value, setValue] = useState();

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const onHandleSelect = useCallback((option) => {
    setValue(option.value);
    onChange && onChange(option);
  }, [onChange]);

  return (
    <RuleChoiceGroupWrapper data-testid={dataTestId}>
      {options.map(option => (
        <RuleChoice
          key={'choice-' + option.value}
          label={option.name}
          value={option.value}
          onChoice={() => onHandleSelect(option)}
          selected={value === option.value}
          disable={disable}
        />
      ))}
    </RuleChoiceGroupWrapper>
  );
};

export default RuleChoiceGroup;
