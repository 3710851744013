import React, { useCallback, useState, useMemo, useEffect } from "react";
import { observer } from "mobx-react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useStore } from "../../../hooks";
import { useMediaQueries } from "../../../utils/mediaQueries";
import {
  StyledContainer,
  TableWrapper,
  headCellStyle,
  bodyCellStyle,
  toolbarStyle,
  tableContainerStyle,
  tablePaperStyle,
} from "../../../elements/v2/Tables/Table.styled";
import { SuccessfulModal } from "../../../elements";
import { IconButton, Tooltip, Box } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Spinner from "../../../components/common/Spinner";
import {getDensity} from "../../../utils/GetDensity/GetDensity";
import { DefaultTheme as theme } from "../../../theme";

const InventoryPage = observer(() => {
  const {
    changeTitle,
    dataStore,
    authStore,
    annUnifiedCustomerProfileStore,
  } = useStore();
  const navigate = useNavigate();
  changeTitle("Inventory");
  const { selectedAccount } = authStore;
  const { isMobile, isTablet, isDesktop } = useMediaQueries();

  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([{ id: "Year", desc: true }]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [isLoading, setIsLoading] = useState(false);
  const density = useMemo(() => getDensity(isMobile, isTablet, isDesktop), [isMobile, isTablet, isDesktop]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                await dataStore.getData({
                    customer: selectedAccount.id,
                    pageIndex: pagination.pageIndex,
                    pageSize: pagination.pageSize,
                    sortBy: sorting,
                    search: globalFilter,
                    source: "INVENTORY",
                });
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [selectedAccount, pagination.pageIndex, pagination.pageSize, sorting, globalFilter, dataStore]);


  const center = {
    align: "center",
    sx: {
      ...bodyCellStyle,
      textAlign: "center",
    },
  };

  const columns = useMemo(
    () => [
      { accessorKey: "VIN", header: "VIN", size: 150 },
      { accessorKey: "Stock", header: "Stock #", size: 50 },
      {
        accessorKey: "NewUsed",
        header: "New/Used",
        size: 40,
        muiTableBodyCellProps: center,
      },
      { accessorKey: "Year", header: "Year", size: 40 },
      { accessorKey: "Make", header: "Make", size: 40 },
      { accessorKey: "Model", header: "Model", size: 50 },
      { accessorKey: "ModelNumber", header: "Model Number", size: 50 },
      { accessorKey: "Body", header: "Body", size: 150 },
      { accessorKey: "Transmission", header: "Transmission", size: 200 },
      { accessorKey: "Series", header: "Series", size: 50 },
      {
        accessorKey: "BodyDoorCt",
        header: "Body Door Ct",
        size: 40,
        muiTableBodyCellProps: center,
      },
      { accessorKey: "Odometer", header: "Odometer", size: 60 },
      {
        accessorKey: "EngineCylinderCt",
        header: "Engine Cylinder Ct",
        size: 40,
        muiTableBodyCellProps: center,
      },
      {
        accessorKey: "DrivetrainDesc",
        header: "Drivetrain Desc",
        size: 40,
        muiTableBodyCellProps: center,
      },
      { accessorKey: "Colour", header: "Colour", size: 200 },
      { accessorKey: "InteriorColor", header: "Interior Color", size: 150 },
      { accessorKey: "MSRP", header: "MSRP", size: 50 },
      { accessorKey: "Price", header: "Price", size: 50 },
      {
        accessorKey: "CityMPG",
        header: "City MPG",
        size: 40,
        muiTableBodyCellProps: center,
      },
      {
        accessorKey: "HighwayMPG",
        header: "HighwayMPG",
        size: 40,
        muiTableBodyCellProps: center,
      },
    ],
    [],
  );

  const onHandleUnifiedCustomerProfile = useCallback(
    (vin) => {
      navigate({
        pathname: "/unified-profile",
        search: createSearchParams({ id: vin }).toString(),
      });
    },
    [authStore.selectedAccount, navigate],
  );

  const table = useMaterialReactTable({
      columns,
      data: dataStore.data !== null ? dataStore.data : [],
      manualSorting: true,
      manualGlobalFilter: true,
      rowCount: dataStore.totalRowCount || 0,
      initialState: {
          sorting,
          density: density,
      },
      muiTableContainerProps: { sx: tableContainerStyle },
      muiTablePaperProps: { elevation: 0, sx: tablePaperStyle },
      muiTableHeadCellProps: { sx: headCellStyle },
      muiTableBodyCellProps: { sx: bodyCellStyle },
      manualPagination: true,
      enableRowSelection: false,
      enableGrouping: false,
      enableTopToolbar: true,
      muiCircularProgressProps: {
          color: 'primary',
          thickness: 5,
          size: 55,
          sx: {
              color: theme.colors.activeBlue
          }
      },
      state: {
          pagination,
          globalFilter,
          sorting,
          isLoading,
      },

    onGlobalFilterChange: (filter) => {
        setGlobalFilter(filter);
        setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    },
    onPaginationChange: setPagination,

    onSortingChange: (sorting) => {
        setSorting(sorting);
        setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    },
    onPageSizeChange: (newPageSize) => {
        setPagination((prev) => ({ ...prev, pageSize: newPageSize, pageIndex: 0 }));
    },
    getRowId: (row) => row.id,

    muiTableBodyRowProps: ({ row }) => ({
      style: { cursor: "pointer" },
    }),

    renderRowActions: ({ row }) => (
      <Box>
        <Tooltip title="View">
          <IconButton
            onClick={() => onHandleUnifiedCustomerProfile(row.original.VIN)}
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),

    muiTopToolbarProps: { sx: toolbarStyle },
    muiSearchTextFieldProps: {
      variant: "outlined",
      placeholder: "Search",
      InputProps: { style: { fontSize: "12px" } },
    },
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnResizing: false,
  });

  return (
    <StyledContainer
      isMobile={isMobile}
      isTablet={isTablet}
      isDesktop={isDesktop}
    >
      <TableWrapper>
        <MaterialReactTable table={table} />
      </TableWrapper>
      {dataStore.isError && (
        <SuccessfulModal
          isOpen={dataStore.isError}
          onClose={dataStore.clearError}
          title="Oops!"
          subtitle="An error occurred."
          subtitle2="Please try again later."
        />
      )}
    </StyledContainer>
  );
});

export default InventoryPage;