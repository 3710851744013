import { styled } from "styled-components";

const CreateContactModalContiner = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100vh;
  font-size: 14px;

  p {
    font-weight: bold;
  }
`;

const CreateContactModal = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 500px;
  padding-block-start: 10px;
  padding-inline: 20px;
  max-height: 800px;
  overflow: scroll;
  border-radius: 5px;
  border: 1px solid black;
  p {
    margin-block: 10px;
  }
`;

const EmailAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddMoreContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PhoneDivision = styled.div`
  display: grid;
  gap: 5px;
  grid-template-columns: 10% auto;
`;

const ContactModalFooter = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  background-color: white;
  width: 100%;
  gap: 5px;
  height: 100%;
  z-index: 1000;
`;

const IconDeleteButton = styled.div`
  position: relative;
  text-align: right;
  cursor: pointer;
  font-size: 12px;
`

export {
    CreateContactModalContiner,
    CreateContactModal,
    EmailAddressContainer,
    AddMoreContainer,
    AddressContainer,
    PhoneDivision,
    ContactModalFooter,
    IconDeleteButton
};
