import styled, { css } from "styled-components";

const DropDownContainer = styled.div`
    position: relative;
    height: 27px;
    border-radius: 8px;
    border: 1px solid ${props => props.theme.colors.clearGray};
    text-align: left;
    font-weight: 400;
    background-color: ${(props) => props.theme.colors.silverGrayThree};
    font-size: 0.75rem;
    min-width: 150px;
`;

const DropDownHeader = styled.div`
    position: relative;
    cursor: pointer;
    padding: 3px 6px;
    text-transform: ${props => props.$textTransform || 'none'};
    z-index: 2;

    &:after {
        content: "";
        position: absolute;
        right: 1rem;
        width: 30px;
        height: 30px;
        background-image: url('${(props) => props.arrow}');
        background-repeat: no-repeat;
        top: -3px;
        right: 6px;
        pointer-events: none;
    }
`;

const DropDownList = styled.ul`
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    z-index: 1001;
    border-radius: 8px;
    border: 1px solid ${props => props.theme.colors.clearGray};
    padding: 0;
    margin-top: 5px;
    background: white;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
`;

const ListItem = styled.li`
    background-color: ${(props) => props.theme.colors.silverGrayThree};
    z-index: 1001;
    list-style: none;
    height: 27px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid ${(props) => props.theme.colors.silverGrayTwo};
    font-size: 0.75rem;
    color: ${(props) => props.theme.colors.greyThirteen};
    padding: 3px 6px;

    &:last-child {
        border: none;
    }
`;

const ListInput = styled.input`
    position: relative;
    padding: 3px 6px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    outline: none;
    border: 1px solid ${props => props.theme.colors.clearGray};
    color: ${(props) => props.theme.colors.greyThirteen};
    border-radius: 8px;
`;

const DropDownBody = styled.div`

    ${(props) => props.$searchBar && css`
        background-color: ${props => props.theme.colors.highClearGray};
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding: 10px;
        margin-top: 10px;
        z-index: 9998;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
    `}
`;

const SimpleSelectContainer = styled.div`
    display: flex;
    align-items: center;
`;

export {
    SimpleSelectContainer,
    DropDownContainer,
    DropDownHeader,
    DropDownList,
    ListItem,
    ListInput,
    DropDownBody
};
