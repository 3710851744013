import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";

import { useStore } from "../../../../hooks";
import { Icon, RoundedButton } from "../../../../elements";
import {
    UserWrapper,
    UserDetails,
    UserContent,
    UserLabel,
    UserColumnContainer,
    UserPhone,
    UserColumn,
    ProfileInputText,
    DisplayPhotoInput,
    DisplayPhotoLabel,
    DisplayPhotoTitle,
    DisplayPhotoSubtitle,
    DisplayPhotoContainer,
    DisplayPhotoImageContainer,
    SuccessModal,
    CustomAvatar,
    PhoneInput,
} from "../ProfilePage.styled";
import Spinner from "../../../../components/common/Spinner";
import { Auth } from "aws-amplify";


const ProfileTab = observer(() => {
    const { authStore, profileStore } = useStore();
    const [successModalContent, setSuccessModalContent] = useState({ isOpen: false, title: '', message: '' });
    const displayPhotoRef = useRef();


    useEffect(() => {
        profileStore.fetchUserData(authStore.userId, authStore.customerId);
    }, [profileStore, authStore.userId, authStore.customerId]);

    const handleFileChange = (event) => {
        const file = event.target.files[0]
        if (file) {
            const objectUrl = URL.createObjectURL(file);
            profileStore.onChangeDisplayPhotoURL(objectUrl)
            profileStore.onChangeDisplayPhotoFile(file)
        }
    }

    const handleResetPassword = async () => {
        try {
            await Auth.forgotPassword(profileStore.userData.email);
            showSuccessMessage(
                'Instructions Sent',
                'We’ve sent a link to your email to reset your password. Please check your inbox'
            );
        } catch (error) {
            showErrorMessage("Error sending email to reset password");
        }
    };

    const showSuccessMessage = (title, message) => {
        setSuccessModalContent({
            isOpen: true,
            title,
            message,
        });

        setTimeout(() => {
            setSuccessModalContent({ isOpen: false, title: '', message: '' });
        }, 5000);
    };

    const showErrorMessage = (message) => {
        setSuccessModalContent({
            isOpen: true,
            title: 'Error',
            message,
        });

        setTimeout(() => {
            setSuccessModalContent({ isOpen: false, title: '', message: '' });
        }, 5000);
    };

    return (
        <UserContent>
            {!profileStore.isLoading && Object.keys(profileStore.userData).length ?
                <>
                    <UserWrapper>
                        <SuccessModal
                            isOpen={successModalContent.isOpen}
                            onClose={() => setSuccessModalContent({ isOpen: false, title: '', message: '' })}
                            title={successModalContent.title}
                            message={successModalContent.message}
                        />
                        <DisplayPhotoContainer>
                            <DisplayPhotoImageContainer>
                                {!profileStore.displayPhotoURL ?
                                    <DisplayPhotoLabel for="display-photo">
                                        <Icon name="plus" />
                                    </DisplayPhotoLabel> :
                                    <CustomAvatar
                                        alt="Display photo"
                                        src={profileStore.displayPhotoURL}
                                        sx={{ width: 80, height: 80 }}
                                        onClick={() => displayPhotoRef.current.click()}
                                    />
                                }
                                <DisplayPhotoInput
                                    id="display-photo"
                                    type="file"
                                    accept="image/*"
                                    ref={displayPhotoRef}
                                    onChange={handleFileChange} />
                            </DisplayPhotoImageContainer>
                            <DisplayPhotoTitle>Edit display photo</DisplayPhotoTitle>
                            <DisplayPhotoSubtitle>Your account will see this photo</DisplayPhotoSubtitle>
                        </DisplayPhotoContainer>
                        <UserDetails>
                            <UserLabel>First Name</UserLabel>
                            <ProfileInputText
                                placeholder="First Name"
                                value={profileStore.userData.first_name || ''}
                                onChange={(e) =>
                                    profileStore.updateUserData("first_name", e.target.value)
                                }
                                maxWidth="800px"
                                displayError={!profileStore.userData.first_name}
                                validationError="First name is required."
                            />
                            <UserLabel>Last Name</UserLabel>
                            <ProfileInputText
                                placeholder="Last Name"
                                value={profileStore.userData.last_name || ''}
                                onChange={(e) =>
                                    profileStore.updateUserData("last_name", e.target.value)
                                }
                                maxWidth="800px"
                                displayError={!profileStore.userData.last_name}
                                validationError="Last name is required."
                            />
                            <UserLabel>Email</UserLabel>
                            <ProfileInputText
                                placeholder="Email"
                                value={profileStore.userData.email || ''}
                                onChange={(e) =>
                                    profileStore.updateUserData("email", e.target.value)
                                }
                                maxWidth="800px"
                                backgroundColor="#ECECEC"
                                disabled
                            />
                            <UserLabel>Phone Number (Optional)</UserLabel>
                            <UserColumnContainer>
                                <PhoneInput
                                    placeholder="+1"
                                    value="+1"
                                    readOnly
                                    width='60px'
                                />
                                <UserPhone>
                                    <PhoneInput
                                        placeholder="Phone number"
                                        value={profileStore.userData.phone || ''}
                                        onChange={(e) =>
                                            profileStore.updateUserData("phone", e.target.value)
                                        }
                                        maxWidth="730px"
                                    />
                                </UserPhone>
                            </UserColumnContainer>
                            <UserLabel>Title</UserLabel>
                            <ProfileInputText
                                placeholder=""
                                value={profileStore.userData.title || ''}
                                onChange={(e) =>
                                    profileStore.updateUserData("title", e.target.value)
                                }
                                maxWidth="800px"
                            />
                            <UserLabel>Union</UserLabel>
                            <ProfileInputText
                                placeholder=""
                                value={profileStore.userData.union || ''}
                                onChange={(e) =>
                                    profileStore.updateUserData("union", e.target.value)
                                }
                                maxWidth="800px"
                            />

                        </UserDetails>
                        <RoundedButton
                            color="#2173FF"
                            width="9.5rem"
                            margin="2rem 0"
                            onClick={handleResetPassword}
                        >
                            Reset Password
                        </RoundedButton>
                    </UserWrapper>
                </>
                :
                <UserColumn>
                    <Spinner />
                </UserColumn>
            }
        </UserContent>
    );
});

export default ProfileTab;
