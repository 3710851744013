import React, { useState } from "react";
import { observer } from "mobx-react";
import { ContactsCSVReader, RoundedButton } from "../../../../../elements";
import { HeaderContainer } from "./SpreadsheetContactsMapping.styled";
import { useStore } from "../../../../../hooks";

const SpreadsheetContactsMapping = observer(({showSendButton=false, onFileUpload}) => {
  const [isSaved, setIsSaved] = useState(false);
  const { contactSegmentStore } = useStore();

  const onSave = () => {
    if (!isSaved) {
      contactSegmentStore.setHeaderMapping();
      return setIsSaved(true);
    }
  };

  const onSend = async () => {
    await contactSegmentStore.uploadFileToS3()
    onFileUpload?.()
  }

  return (
    <>
      <HeaderContainer>
        <b>Make sure your files are ready to upload before importing</b>

        { !isSaved && <RoundedButton onClick={onSave} width="200px" disabled={!contactSegmentStore?.parsedContactList.length}>
          Save
        </RoundedButton>}

       { showSendButton && isSaved && <RoundedButton onClick={onSend} width="200px">
          Send
        </RoundedButton> }
      </HeaderContainer>

      <ContactsCSVReader isSaved={isSaved} setIsSaved={setIsSaved} />
      
    </>
  );
});

export default SpreadsheetContactsMapping;