import React, { useState, useRef, useEffect } from "react";
import { observer } from "mobx-react";
import {
  FieldMapperContainer,
  FileSelectContainer,
  SelectedFileContainer,
  FieldMapperGroup,
  Titles,
  CSVTableContainer,
  SimpleSelectContainer,
} from "./ContactsCSVReader.styled";
import { IconButton, SuccessfulModal, Icon } from "../..";
import { useStore } from "../../../hooks";
import CSVTable from "../CSVTable/CSVTable";
import SimpleSelect from "../Selects/SimpleSelect";
import { Add, Check, Close } from "@mui/icons-material";
import * as Material from "@mui/material";

const MAPPINGS = [
  { name: "First Name", mapping: "first_name", custom: false },
  { name: "Last Name", mapping: "last_name", custom: false },
  { name: "Email", mapping: "email", custom: false },
  { name: "Company Name", mapping: "company_name", custom: false },
  { name: "Address", mapping: "address", custom: false },
  { name: "City", mapping: "city", custom: false },
  { name: "State", mapping: "state", custom: false },
  { name: "Postal Code", mapping: "postal_code", custom: false },
  { name: "Phone", mapping: "phone", custom: false },
];

const FieldMapperElement = observer(({ index, setNewSystemField, newSystemField }) => {
  const [newSystemFieldValue, setNewSystemFieldValue] = useState(null);
  const [newSystemFieldSaved, setNewSystemFieldSaved] = useState(false);

  const { contactSegmentStore } = useStore();
  const { defaultSystemFields, defaultFileFields} = contactSegmentStore;

  useEffect(() => {
    if (!contactSegmentStore?.dynamicColumns?.length) {
      contactSegmentStore.setDynamicColumns(MAPPINGS);
    }  
  }, [])

  const onChangeSelect = (name, value) => {
    contactSegmentStore.setIndividualDefaultFileFields(name, value, index);
  };

  const systemFieldsMapped = () => {
    const systemFieldsSelected = defaultSystemFields.map((item) => item.value);
    const newMapping = contactSegmentStore?.dynamicColumns?.filter(
      (field) => !systemFieldsSelected.includes(field.mapping) && !field?.custom
    ).map((field) => ({
      name: field.name,
      value: field.mapping,
    }));
    return newMapping;
  };

  const fileFieldsMapped = () => {
    const fileFieldsSelected = defaultFileFields.map((item) => item.value);
    const newMapping = contactSegmentStore.fileUploadedHeaders
      .filter((field) => !fileFieldsSelected.includes(field.value))
      .map((field) => ({
        name: field.name,
        value: field.value,
      }));
    return newMapping;
  };

  const onChangeSystemFields = (newName, newValue, oldName) => {
    let systemFieldsSelected = [...defaultSystemFields];
    if (oldName) {
      systemFieldsSelected = systemFieldsSelected.filter(
        (item) => item.name !== oldName
      );
    }
    
    const existThisFieldInSystemField = systemFieldsSelected.find(
      (item) => item.name === newName
    );

    if (!existThisFieldInSystemField) {
      const selectedItem = {
        name: newName,
        value: newValue,
        mapping: newValue,
        index
      };
      systemFieldsSelected = [...systemFieldsSelected, selectedItem];
    }
    contactSegmentStore.setDefaultSystemFields(systemFieldsSelected);
  };

  const onClearSystemOptions = (selectedOption) => {
    let systemFieldsSelected = [...contactSegmentStore.defaultSystemFields];
    const fieldsWithoutCurrentOption = systemFieldsSelected.filter(
      (item) => item.name !== selectedOption
    );
    contactSegmentStore.setDefaultSystemFields(fieldsWithoutCurrentOption);
  };

  const onClearFileOptions = (selectedOption) => {
    let fileFieldsSelected = [...defaultFileFields];
    const fieldsWithoutCurrentOption = fileFieldsSelected.filter(
      (item) => item.mapping !== selectedOption
    );
    contactSegmentStore.setDefaultFileFields(fieldsWithoutCurrentOption);
  };

  const onChangeNewSystemFieldValue = (e) => {
    setNewSystemFieldValue(e.target.value);
  }

  const onCreateNewSystemField = () => {
    const currentSystemFieldSelected = index;
    const selectedField = contactSegmentStore.defaultSystemFields.find(
      (field) => field.index === index
    );
  
    if (selectedField) {
      const updatedSystemFields = contactSegmentStore.defaultSystemFields.filter(
        (field) => field.index !== index
      );
      contactSegmentStore.setDefaultSystemFields(updatedSystemFields);
    }
    if (!newSystemField.includes(currentSystemFieldSelected)) {
      const newSystemFieldIndexAdded = [...newSystemField, currentSystemFieldSelected];
      setNewSystemField(newSystemFieldIndexAdded);
    }
  };

  const onSaveNewSystemField = () => {
    if (!newSystemFieldValue || newSystemFieldValue.length === 0) return false;
  
    const formattedSystemFieldValue = newSystemFieldValue.replace(/[^a-zA-Z]/g, "_").toLowerCase();
    
    const systemFieldsSelected = [...contactSegmentStore.defaultSystemFields];
    const allFields = [...contactSegmentStore.dynamicColumns, ...systemFieldsSelected];

    const existThisFieldInSystemField = allFields.find(
      (item) => item.value === formattedSystemFieldValue
    );
    if (existThisFieldInSystemField) return false;
  
    const newSystemFieldObject = {
      name: newSystemFieldValue,
      mapping: formattedSystemFieldValue,
      value: formattedSystemFieldValue,
      custom: true,
      index,
    };

    const updatedDynamicColumns = [...contactSegmentStore.dynamicColumns, newSystemFieldObject];
    const updatedDefaultSystemFields = [...systemFieldsSelected, newSystemFieldObject];

    contactSegmentStore.setDynamicColumns(updatedDynamicColumns);
    contactSegmentStore.setDefaultSystemFields(updatedDefaultSystemFields);

    setNewSystemFieldValue(null);
    setNewSystemFieldSaved(true);
  };
  

  const onDeleteSystemField = () => {
    const updatedSystemField = newSystemField.filter(
      (field) => field !== index
    );
    setNewSystemField(updatedSystemField);
    setNewSystemFieldSaved(false);
  };

  return (
    <FieldMapperContainer>
      <SimpleSelect
        optionCleaner={true}
        options={fileFieldsMapped()}
        handleSelect={onChangeSelect}
        onClear={onClearFileOptions}
      />
      <Icon name="blueArrow" />
      {!newSystemField.includes(index) ? (
        <SimpleSelectContainer>
          
          <Material.Icon
            onClick={onCreateNewSystemField}
            component={Add}
            width="10px"
            height="10px"
          />
          <SimpleSelect
            optionCleaner={true}
            options={systemFieldsMapped()}
            handleSelect={onChangeSystemFields}
            onClear={onClearSystemOptions}
          />
        </SimpleSelectContainer>
      ) : (
        <SimpleSelectContainer>
          <Material.Input disabled={newSystemFieldSaved} error={!newSystemFieldValue} onChange={onChangeNewSystemFieldValue} placeholder="New Field" />
            {!newSystemFieldSaved && (
              <Material.Icon
                onClick={onSaveNewSystemField}
                component={Check}
                width="10px"
                height="10px"
              />
            )}
          <Material.Icon
            onClick={onDeleteSystemField}
            component={Close}
            width="10px"
            height="10px"
          />
        </SimpleSelectContainer>
      )}
    </FieldMapperContainer>
  );
});

const ContactsCSVReader = observer(({ isSaved, setIsSaved }) => {
  const { contactSegmentStore } = useStore();
  const [newSystemField, setNewSystemField] = useState([]);
  const [errorPopup, setErrorPopup] = useState("");
  const fileInputRef = useRef();

  const handleFile = (files) => {
    if (files.length) {
      const fileToUpload = files[0];

      if (!["text/csv"].includes(fileToUpload.type)) {
        return setErrorPopup("Please upload a valid format file: *.csv.");
      }

      if (fileToUpload.size / 1e6 > 100) {
        return setErrorPopup(`File exceeds the 100 mb file size limit.`);
      }

      contactSegmentStore.setFileUploaded(fileToUpload);
    }
  };

  const fileFieldToMapperGroup = () => {
    return contactSegmentStore.fileUploadedHeaders.map((header) => {
      return {
        name: header.name,
        mapping: header.value,
      };
    });
  };

  const removeFile = () => {
    contactSegmentStore.setFileUploaded(null);
    if (fileInputRef.current) fileInputRef.current.value = "";
    setIsSaved(false);
    contactSegmentStore.clear();
    contactSegmentStore.setDynamicParsedContactList([]);
    setNewSystemField([]);
  };

  return (
    <>
      {!contactSegmentStore.fileUploaded && (
        <FileSelectContainer
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onDragLeave={(e) => {
            e.preventDefault();
          }}
          onDrop={(e) => {
            e.preventDefault();
            handleFile(e.dataTransfer.files);
          }}
        >
          <input
            id="contacts-file-input"
            ref={fileInputRef}
            type="file"
            style={{ display: "none" }}
            onChange={(e) => handleFile(e.target.files)}
          />
          <label htmlFor="contacts-file-input">
            <Icon name="paperUpload" width="20px" height="20px" />
            Drag and drop or choose a file to upload
          </label>
        </FileSelectContainer>
      )}

      {contactSegmentStore.fileUploaded && (
        <SelectedFileContainer>
          <div>
            <span>
              <Icon name="excelLogo" width="20px" height="20px" />
            </span>
            <div>
              <p>{contactSegmentStore.fileUploaded?.name}</p>
              <span>
                {contactSegmentStore.fileUploaded?.size && (
                  <p>{contactSegmentStore.fileUploaded?.size / 1000} kb</p>
                )}
              </span>
            </div>
          </div>
          <IconButton
            icon="close"
            width="10px"
            height="10px"
            onClick={removeFile}
          />
        </SelectedFileContainer>
      )}

      {isSaved && contactSegmentStore?.fileUploaded ? (
        <CSVTableContainer>
          <CSVTable />
        </CSVTableContainer>
      ) : (
        <>
          {contactSegmentStore.fileUploaded?.size && (
            <>
              <Titles>
                <b>File fields</b>
                <b>System fields</b>
              </Titles>
              <FieldMapperGroup>
                {fileFieldToMapperGroup().map(({ mapping }, key) => (
                  <FieldMapperElement
                    titles={MAPPINGS}
                    mapping={mapping}
                    index={key}
                    setNewSystemField={setNewSystemField}
                    newSystemField={newSystemField}
                  />
                ))}
              </FieldMapperGroup>
            </>
          )}
        </>
      )}

      <SuccessfulModal
        isOpen={!!errorPopup || contactSegmentStore.error}
        onClose={() => {
          contactSegmentStore.onClearError();
          setErrorPopup("");
        }}
        title="Oops!"
        subtitle="An error occurred."
        subtitle2={errorPopup || contactSegmentStore.error}
        height="300px"
        width="500px"
        zIndex={true}
      />
    </>
  );
});

export default ContactsCSVReader;
