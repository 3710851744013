import React, { useState, useEffect } from 'react';
import { IconButton } from '../../../../../elements';

import { 
  TagInputWrapper,
  Tag,
  TagInput
} from './RuleTagsInput.styled';

const RulesTagsInput = ({defaultValue=[], onChange, dataTestId}) => {
  const [tags, setTags] = useState([]);
  const [input, setInput] = useState('');

  useEffect(() => {
    if(Array.isArray(defaultValue)) setTags(defaultValue);
  }, [defaultValue]);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && input.trim() !== '') {
      const newTags = [...tags, input.trim()]
      setTags(newTags);
      onChange(newTags)
      setInput('');
    }
  };

  const handleRemoveTag = (index) => {
    const newTags = tags.filter((_, i) => i !== index)
    setTags(newTags);
    onChange(newTags)
  };

  return (
    <TagInputWrapper 
>
      {tags && tags.map((tag, index) => (
        <Tag key={index}>
          {tag}
          <IconButton icon="close" width="8px" height="8px" onClick={() => handleRemoveTag(index)} />
        </Tag>
      ))}
      <TagInput
        type="text"
        value={input}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Add a value"
        data-testid={dataTestId}
      />
    </TagInputWrapper>
  );
};

export default RulesTagsInput;
