/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSingleContact = /* GraphQL */ `
  query GetSingleContact($input: GetSingleContactInput!) {
    getSingleContact(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const putSingleContact = /* GraphQL */ `
  query PutSingleContact($input: PutSingleContact!) {
    putSingleContact(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const deleteMarketingCampaign = /* GraphQL */ `
  query DeleteMarketingCampaign($input: DeleteMarketingCampaignInput!) {
    deleteMarketingCampaign(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getUsers = /* GraphQL */ `
  query GetUsers($input: GetUserInput!) {
    getUsers(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const putUser = /* GraphQL */ `
  query PutUser($input: PutUserInput!) {
    putUser(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  query DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getChannels = /* GraphQL */ `
  query GetChannels($input: getChannelInput!) {
    getChannels(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getKeys = /* GraphQL */ `
  query GetKeys($input: getKeyInput!) {
    getKeys(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const putKey = /* GraphQL */ `
  query PutKey($input: PutKeyInput!) {
    putKey(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const deleteKey = /* GraphQL */ `
  query DeleteKey($input: DeleteKeyInput!) {
    deleteKey(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getCustomers = /* GraphQL */ `
  query GetCustomers($input: GetCustomerInput) {
    getCustomers(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const putCustomer = /* GraphQL */ `
  query PutCustomer($input: PutCustomerInput!) {
    putCustomer(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  query DeleteCustomer($input: DeleteAccountInput!) {
    deleteCustomer(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const putChannel = /* GraphQL */ `
  query PutChannel($input: PutChannelInput!) {
    putChannel(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const deleteChannel = /* GraphQL */ `
  query DeleteChannel($input: DeleteChannelInput!) {
    deleteChannel(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getUploadUrlFile = /* GraphQL */ `
  query GetUploadUrlFile($input: PutFileInput!) {
    getUploadUrlFile(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getUrlFile = /* GraphQL */ `
  query GetUrlFile($input: GetFileInput!) {
    getUrlFile(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const putCampaigns = /* GraphQL */ `
  query PutCampaigns($input: putCampaignsInput!) {
    putCampaigns(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const putCampaignMarketing = /* GraphQL */ `
  query PutCampaignMarketing($input: putCampaignMarketingInput!) {
    putCampaignMarketing(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const deleteCampaign = /* GraphQL */ `
  query DeleteCampaign($input: deleteCampignsInput!) {
    deleteCampaign(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getCampaigns = /* GraphQL */ `
  query GetCampaigns($input: getCampignsInput!) {
    getCampaigns(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getDefaultProjects = /* GraphQL */ `
  query GetDefaultProjects($input: getCampignsDefaultProjectsInput!) {
    getDefaultProjects(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getContactFiles = /* GraphQL */ `
  query GetContactFiles($input: getContactFilesInput!) {
    getContactFiles(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const putAgent = /* GraphQL */ `
  query PutAgent($input: PutAgentInput!) {
    putAgent(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getAgent = /* GraphQL */ `
  query GetAgent($input: GetAgentInput!) {
    getAgent(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const deleteAgent = /* GraphQL */ `
  query DeleteAgent($input: DeleteAgentInput!) {
    deleteAgent(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getConversationChatAssistant = /* GraphQL */ `
  query GetConversationChatAssistant($input: AgentConversationInput!) {
    getConversationChatAssistant(input: $input) {
      id
      statusCode
      status
      body
      __typename
    }
  }
`;
export const getCampaignJourneys = /* GraphQL */ `
  query GetCampaignJourneys($input: GetJourneysInput!) {
    getCampaignJourneys(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const putCampaignJourneys = /* GraphQL */ `
  query PutCampaignJourneys($input: PutJourneyInput!) {
    putCampaignJourneys(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getConversations = /* GraphQL */ `
  query GetConversations($input: GetConversationsInput!) {
    getConversations(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const putScrapping = /* GraphQL */ `
  query PutScrapping($input: PutScrappingInput!) {
    putScrapping(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const deleteAgentFile = /* GraphQL */ `
  query DeleteAgentFile($input: DeleteAgentFileInput!) {
    deleteAgentFile(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getContacts = /* GraphQL */ `
  query GetContacts($input: getContactsInput!) {
    getContacts(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getContactsAnn = /* GraphQL */ `
  query GetContactsAnn($input: getContactsInput!) {
    getContactsAnn(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getChromeYears = /* GraphQL */ `
  query GetChromeYears($input: getChromeYearsInput!) {
    getChromeYears(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getChromeMakes = /* GraphQL */ `
  query GetChromeMakes($input: getChromeMakesInput!) {
    getChromeMakes(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getChromeModel = /* GraphQL */ `
  query GetChromeModel($input: getChromeModelInput!) {
    getChromeModel(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getChromeStyle = /* GraphQL */ `
  query GetChromeStyle($input: getChromeStyleInput!) {
    getChromeStyle(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getChromeStyleDetails = /* GraphQL */ `
  query GetChromeStyleDetails($input: getChromeStyleDetailsInput!) {
    getChromeStyleDetails(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getChromeVin = /* GraphQL */ `
  query GetChromeVin($input: getChromeVinInput!) {
    getChromeVin(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getQuicksightEmbedUrl = /* GraphQL */ `
  query GetQuicksightEmbedUrl($input: getQuicksightEmbedUrlInput!) {
    getQuicksightEmbedUrl(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getMessages = /* GraphQL */ `
  query GetMessages($input: GetMessagesInput!) {
    getMessages(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getConversationsHistory = /* GraphQL */ `
  query GetConversationsHistory($input: GetConversationsHistory!) {
    getConversationsHistory(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const createAgentTeam = /* GraphQL */ `
  query CreateAgentTeam($input: PutAgentTeamInput!) {
    createAgentTeam(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const updateAgentTeam = /* GraphQL */ `
  query UpdateAgentTeam($input: PutAgentTeamInput!) {
    updateAgentTeam(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getAgentTeams = /* GraphQL */ `
  query GetAgentTeams($input: GetAgentTeamsInput!) {
    getAgentTeams(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const publishAgentTeam = /* GraphQL */ `
  query PublishAgentTeam($input: PublishTeamInput) {
    publishAgentTeam(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getPermissionCatalogUser = /* GraphQL */ `
  query GetPermissionCatalogUser($input: GetUserPermissionsInput!) {
    getPermissionCatalogUser(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getUserPermissions = /* GraphQL */ `
  query GetUserPermissions($input: GetUserPermissionsInput!) {
    getUserPermissions(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getRoles = /* GraphQL */ `
  query GetRoles($input: GetRolesInput!) {
    getRoles(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getRolePermissions = /* GraphQL */ `
  query GetRolePermissions($input: GetRolePermissionsInput!) {
    getRolePermissions(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const putRole = /* GraphQL */ `
  query PutRole($input: PutRoleInput!) {
    putRole(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const putUserPermissions = /* GraphQL */ `
  query PutUserPermissions($input: PutUserPermissionsInput!) {
    putUserPermissions(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const putPermissionsRole = /* GraphQL */ `
  query PutPermissionsRole($input: PutPermissionsRoleInput!) {
    putPermissionsRole(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const putUserRole = /* GraphQL */ `
  query PutUserRole($input: PutUserRoleInput!) {
    putUserRole(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getTrigger = /* GraphQL */ `
  query GetTrigger($input: getTriggersInput!) {
    getTrigger(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const putTrigger = /* GraphQL */ `
  query PutTrigger($input: putTriggerInput!) {
    putTrigger(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($input: AWSJSON!) {
    getEvent(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getAction = /* GraphQL */ `
  query GetAction($input: getActionsInput!) {
    getAction(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getUCPData = /* GraphQL */ `
  query GetUCPData($input: GetUCPDataInput!) {
    getUCPData(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const putEmailBlast = /* GraphQL */ `
  query PutEmailBlast($input: PutEmailBlastInput!) {
    putEmailBlast(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getContactsSegment = /* GraphQL */ `
  query GetContactsSegment($input: GetContactsSegmentInput!) {
    getContactsSegment(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getAlert = /* GraphQL */ `
  query GetAlert($input: GetAlertInput!) {
    getAlert(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const putAlert = /* GraphQL */ `
  query PutAlert($input: PutAlertInput!) {
    putAlert(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const cancelRun = /* GraphQL */ `
  query CancelRun($input: cancelRunInput) {
    cancelRun(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const putUserResources = /* GraphQL */ `
  query PutUserResources($input: PutUserResourcesInput!) {
    putUserResources(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getResources = /* GraphQL */ `
  query GetResources($input: GetResourcesInput!) {
    getResources(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getVehicleVinPictures = /* GraphQL */ `
  query GetVehicleVinPictures($input: GetVehicleVinPicturesInput!) {
    getVehicleVinPictures(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getVehicleStockImages = /* GraphQL */ `
  query GetVehicleStockImages($input: GetVehicleStockImagesInput!) {
    getVehicleStockImages(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getLogs = /* GraphQL */ `
  query GetLogs($input: GetLogsInput!) {
    getLogs(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getData = /* GraphQL */ `
  query GetData($input: getDataInput!) {
    getData(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getInterview = /* GraphQL */ `
  query GetInterview($input: GetInterviewInput!) {
    getInterview(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const putInterview = /* GraphQL */ `
  query PutInterview($input: PutInterviewInput!) {
    putInterview(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const delInterview = /* GraphQL */ `
  query DelInterview($input: DeleteInterviewInput!) {
    delInterview(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getInvokePersona = /* GraphQL */ `
  query GetInvokePersona($input: GetInvokePersonaInput!) {
    getInvokePersona(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getInvokeInterview = /* GraphQL */ `
  query GetInvokeInterview($input: GetInvokeInterviewInput!) {
    getInvokeInterview(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
