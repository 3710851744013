import { useCallback, useMemo, useState } from "react";
import AgentTeamListItem from "./AgentTeamListItem";

import {
  SelectorListWrapper,
  SelectorListItems,
  AgentTeamListItemGroup,
  Overlay,
  CenteredModal,
} from "./AgentTeamSelector.styled";
import SearchInput from "./SearchInput";
import { Icon } from "../../../../../elements";
import { useNavigate } from "react-router-dom";
import { useMediaQueries } from "../../../../../utils/mediaQueries";
import { Avatar } from "@mui/material";

const AgentTeamList = ({ items, isVisible, onSelect, clickAddNew, onClose }) => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const { isMobile, isTablet } = useMediaQueries();

  const onHandleSearch = useCallback((value) => {
    setSearchValue(value);
  }, []);

  const onHandleSelectItem = useCallback(
    (item) => {
      setSearchValue("");
      onSelect && onSelect(item);
    },
    [onSelect],
  );

  const onHandleSelectAddNew = () => {
    if (clickAddNew) {
      clickAddNew();
    } else {
      navigate({
        pathname: "/v2/assistants/wizard/new-agent",
      });
    }
  };

  const listItems = useMemo(() => {
    return items.filter((item) =>
      item.name.toLowerCase().includes(searchValue.toLowerCase()),
    );
  }, [items, searchValue]);

  const handleOverlayClick = (e) => {
     if (e.target === e.currentTarget) {
       onClose();
     }
   };

  const content = (
    <>
      <SearchInput defaultValue={searchValue} onChange={onHandleSearch} />
      <SelectorListItems>
        <h3>Agents</h3>
        <AgentTeamListItemGroup>
          <AgentTeamListItem
            name={"Create new"}
            onClick={onHandleSelectAddNew}
            id="addNewAgentButton"
            avatar={<Icon name="circleAdd" width="24px" height="24px" />}
          />
          {listItems.length === 0 && <p>No agents found</p>}
          {listItems.map((item) => (
            <AgentTeamListItem
              name={item.name}
              onClick={onHandleSelectItem}
              id={item.value}
              avatar={item.photo_url ? <Avatar src={item.photo_url}/> : undefined}
            />
          ))}
        </AgentTeamListItemGroup>
      </SelectorListItems>
    </>
  );

  if (isMobile) {
    return (
      isVisible && (
        <Overlay onClick={handleOverlayClick}>
          <CenteredModal>
            { content }
          </CenteredModal>
        </Overlay>
      )
    );
  }

  return <SelectorListWrapper isVisible={isVisible}>{ content }</SelectorListWrapper>;
};

export default AgentTeamList;
