import styled from 'styled-components';

export const PopupContainer = styled.div`
  position: absolute;
  bottom: 100%;
  right: 0px;
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.clearGray};
  border-radius: 8px;
  padding: 0;
  width: 300px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  margin-bottom: 8px;

  @media (max-width: 1024px) {
    width: 250px;
    position: absolute;
    bottom: 100%;
    right: 0;
  }

  @media (max-width: 768px) {
    width: 90%;
    max-width: 300px;
    max-height: 80vh;
    position: absolute;
    bottom: 100%;
    right: 0;
  }
`;

export const SuggestionList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
`;

export const SuggestionItem = styled.li`
  padding: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid ${props => props.theme.colors.clearGray};
  transition: background-color 0.2s ease;
  
  &:last-child {
    border-bottom: none;
  }
  
  &:hover {
    background: ${props => props.theme.colors.aliceBlue};
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 4px;
  opacity: 0.7;
  color: ${props => props.theme.colors.darkCharcoal};
  
  &:hover {
    opacity: 1;
  }
`; 