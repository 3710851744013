import { action, flow, makeObservable, observable } from "mobx";
import { API } from "aws-amplify";
import { getData } from "../../graphql/queries";

class DataStore {
    data = [];
    filteredData = [];
    totalRowCount = 0;
    isLoading = false;
    isError = false;
    currentPage = 1;
    pageSize = 10;

    constructor() {
        makeObservable(this, {
            data: observable,
            filteredData: observable,
            totalRowCount: observable,
            isLoading: observable,
            isError: observable,
            currentPage: observable,
            pageSize: observable,
            getData: flow,
            setError: action,
            clearError: action,
            setPage: action,
            filterData: action,
        });
    }

    vehiclesMap = (data) => {
        this.data = data.map(vehicle => ({
            id: vehicle.id || '',
            make: vehicle.make || '',
            model: vehicle.model || '',
            year: vehicle.year || '',
            vin: vehicle.vin || '',
            owner: vehicle.owner || '',
            color: vehicle.color || '',
            ...vehicle, // Include any additional fields from API
        }));
    }

    repairsMap = (data) => {
        this.data = data.map(order => ({
            id: order.id || '',
            roNumber: order.ro_number || '',
            status: order.status || '',
            description: order.description || '',
            appointmentDay: order.appointment_day || '',
            bookDay: order.book_day || '',
            ...order,
        }));
    }

    appointmentsMap = (data) => {
        this.data = data.map(appointment => ({
            id: appointment.id || '',
            serviceType: appointment.service_type || '',
            appointmentDay: appointment.appointment_day || '',
            appointmentTime: appointment.appointment_time || '',
            description: appointment.description || '',
            customer: appointment.customer || '',
            ...appointment,
        }));
    }

    inventoryMap = (data) => {
        this.data = data.map(item => ({
            VIN: item.vin || '',
            Stock: item.stock || '',
            NewUsed: item.new_used || '',
            Year: item.year || '',
            Make: item.make || '',
            Model: item.model || '',
            ModelNumber: item.model_number || '',
            Body: item.body || '',
            Transmission: item.transmission || '',
            Series: item.series || '',
            BodyDoorCt: item.body_door_ct || '',
            Odometer: item.odometer || '',
            EngineCylinderCt: item.engine_cylinder_ct || '',
            DrivetrainDesc: item.drivetrain_desc || '',
            Colour: item.colour || '',
            InteriorColor: item.interior_color || '',
            MSRP: item.msrp || '',
            Price: item.price || '',
            CityMPG: item.city_mpg || '',
            HighwayMPG: item.highway_mpg || '',
            ...item,
        }));
    }



    
    *getData({ customer, pageIndex, pageSize, sortBy, search, source }) {
        try {
            this.isLoading = true;
            this.isError = false;

            const validPageIndex = pageIndex !== null ? pageIndex : 0;
            const queryForData = getData;
            const mapFunctionName = `${source.toLowerCase()}Map`;

            const parameters = {
                count_records: false,
                page_size: pageSize || 10,
                page: validPageIndex + 1,
            };

            if (search && search.trim()) {
                parameters.search = search.trim();
            }

            if (sortBy && sortBy.length > 0) {
                parameters.order_by = sortBy[0];
            }

            if (parameters.page === 1) {
                parameters.count_records = true;
            }

            const variables = {
                input: {
                    customer: customer,
                    parameters: JSON.stringify(parameters),
                    source
                },
            };

            const response = yield API.graphql({
                query: queryForData,
                variables: variables,
                authMode: 'AMAZON_COGNITO_USER_POOLS',
            });

            // Parse the response data
            const body = JSON.parse(response.data.getData.body);
            const data = JSON.parse(body.data);

            // Map data to match mock data structure

            if (mapFunctionName && typeof this[mapFunctionName] === 'function') {
                this[mapFunctionName](data); // Invoke the mapping function dynamically
            } else {
                this.isError = true;
            }

            this.totalRowCount = parameters.count_records ? (body.totalRowCount || data.length) : this.totalRowCount;
            this.currentPage = validPageIndex + 1;

        } catch (error) {
            this.isError = true;
            this.errorMessage = error.message || "An unexpected error occurred";
            throw error;
        } finally {
            this.isLoading = false;
        }
    }


    setError(isError) {
        this.isError = isError;
    }

    clearError() {
        this.isError = false;
    }

    setPage(page) {
        this.currentPage = page;
    }

    filterData(filters) {
        if (!filters || filters.length === 0) {
            this.filteredData = this.data;
        } else {
            this.filteredData = this.data.filter(item =>
                filters.every(filter => item[filter.key] === filter.value)
            );
        }
    }
}

export default DataStore;
