import React from 'react';
import styled from 'styled-components';
import { ReactComponent as AiSuggestionsIcon } from '../../../../../elements/icon/assets/ai_icon_suggestions.svg';

const ButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  transition: opacity 0.2s ease;
  padding: 5px;
  border-radius: 4px;
  margin-left: 8px;
  margin-bottom: 3px;
  color: ${props => props.theme.colors.darkCharcoal};
  
  &:hover {
    opacity: 1;
  }
`;

const AISuggestionButton = ({ onClick }) => {
  return (
    <ButtonContainer onClick={onClick}>
      <AiSuggestionsIcon width="20px" height="23px" />
    </ButtonContainer>
  );
};

export default AISuggestionButton; 