import { DropDownContainer, DropDownList, ListItem, ListInput, DropDownBody } from "./IconSelect.styled";
import { useState, useEffect, useMemo } from "react"
import { RoundedButton } from "../../../button";
import { Icon } from "../../../icon";

const IconSelect = ({ kind="actions",placeholder = 'Choose', options=[{name: 'Option 1', onClick: () => null}], hideOnBlur=false, searchBar=false, setClickedOption }) => {
    const [isOpen, setIsOpen] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');
  
    const toggling = () => setIsOpen(!isOpen);
  
    const onOptionClicked = option => () => {
        setIsOpen(false);
        setClickedOption?.(option)
        option.onClick?.();
    };

    const handleBlur = () => { 
        hideOnBlur && setIsOpen(false) 
    }; 

    const listSorted = useMemo(() => options.filter(option =>  (new RegExp(searchTerm, "i")).test(option.name)), [searchTerm, options.length])

  return (
      <DropDownContainer onBlur={handleBlur} tabIndex={0}>
        <RoundedButton
                    width="103px"
                    height="40px"
                    fontSize="0.875rem"
                    kind={kind}
                    icon={{ element:  <Icon name={"arrowDown"} height={"5.7px"} width={"8.49px"} />, position: "right" }}
                    onClick={toggling}
                  >
                    {placeholder}
                  </RoundedButton> 
        {isOpen && options.length > 0 && (
            <DropDownBody $searchBar={searchBar}>
                {searchBar && <ListInput value={searchTerm} onChange={e => setSearchTerm(e.target.value)} placeholder="Search..."/>}
                <DropDownList>
                    {listSorted.map( option => 
                        <ListItem onClick={onOptionClicked(option)} key={Math.random()}> <Icon name={option.icon} height={"11.5px"} width={"11.5px"} /> <span>{option.name}</span></ListItem>
                    )}
                </DropDownList>
            </DropDownBody>
        )}
      </DropDownContainer>
  );
}

export default IconSelect;
