import styled from "styled-components";

const CarouselContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  width: ${(props) => props.carouselWidth || '100%'}; 
`;

const SliderContainer = styled.div`
  display: block;
  margin: auto;
`;

const SliderPrincipalPanel = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 410px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;

  & > div > div > span.flicking-arrow-prev.is-thin::after,
  & > div > div > span.flicking-arrow-prev.is-thin::before,
  & > div > div > span.flicking-arrow-next.is-thin::after,
  & > div > div > span.flicking-arrow-next.is-thin::before {
    background: black;
    width: 1rem;
  }

  & > div > div > div:nth-child(2) > .flicking-pagination-bullet-active {
    background-color: ${(props) => props.theme.colors.black};
  }

  &.flicking-pagination {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }

  &.flicking-pagination-bullet {
    width: 8px;
    height: 8px;
    margin: 0 4px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }

  &.flicking-pagination-bullet-active {
    background-color: ${(props) => props.theme.colors.black};
  }
`;

const NestedSlider = styled.div`
  height: 200px;
  display: flex;
  gap: 10px;
`;

const Panel = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.darkCharcoal};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-size: 1.2rem;
`;

const PanelLarge = styled(Panel)`
  width: 300px;
  height: 200px;
`;

const PanelSmall = styled(Panel)`
  width: 150px;
  height: 150px;
`;

const PanelMedium = styled(Panel)`
  width: 200px;
  height: 180px;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VehicleImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const FlickingArrow = styled.span`
  &.flicking-arrow-prev::after,
  &.flicking-arrow-prev::before,
  &.flicking-arrow-next::after,
  &.flicking-arrow-next::before {
    background: black !important;
  }

  &.flicking-arrow-next.is-thin::after,
  &.flicking-arrow-next.is-thin::before {
    right: 0;
    width: 12px;
  }

  &.flicking-arrow-prev.is-thin::after,
  &.flicking-arrow-prev.is-thin::before {
    left: 0;
    width: 12px;
  }
`;

const FlickingPagination = styled.div`
  span.flicking-pagination-bullet.flicking-pagination-bullet-active {
    background: ${(props) => props.theme.colors.black};
  }
`

export {
  CarouselContainer,
  SliderContainer,
  SliderPrincipalPanel,
  NestedSlider,
  Panel,
  PanelLarge,
  PanelSmall,
  PanelMedium,
  ImageContainer,
  VehicleImage,
  FlickingArrow,
  FlickingPagination
};
