import React, { useEffect, useState } from 'react';
import { useStore } from "../../../../hooks";
import { observer } from "mobx-react";
import { TemplateContainer } from "./Marketing.styled";
import TemplateEditor from "../../../../elements/v2/TemplateEditor/TemplateEditor";
import { EmailTemplateWrapper } from "../../BlastCampaigns/BlastCampaignConfiguration.styled";

const Message = observer(() => {
  const { authStore, templateEditorStore, marketingStore, contactSegmentStore } = useStore();
  const [exportTemplate, setExportTemplate] = useState();

  useEffect(() => {
    if(exportTemplate){
      marketingStore.setEmailTemplateExportAction(exportTemplate)
    }
  }, [exportTemplate])

  const onExportEditor = () => {
    marketingStore.updateBindingsAndPayload(
      templateEditorStore.payload.payload,
      templateEditorStore.payload.binding,
    );
    marketingStore.save(authStore.selectedAccount.id, contactSegmentStore.advancedFilters, contactSegmentStore.totalRowCount)
  };

  return (
    <TemplateContainer>
      <EmailTemplateWrapper>
      <TemplateEditor
        isPopup={false}
        onExport={onExportEditor}
        exportAction={setExportTemplate}
      />
      </EmailTemplateWrapper>
    </TemplateContainer>
  );
});

export default Message;
