const actions = {
    appointments_schedule: {icon: 'schedule', title: 'Schedule appointment', description: 'Schedule a date, coordinate schedules.', superAdminOnly: true},
    send_sms: {icon: 'sms', title: 'Send sms', description: 'Send messages to communicate information or interact with others.'},
    send_email: {icon: 'email', title: 'Send email', description: 'Send emails to communicate information or interact with other people.'},
    get_inventory: {icon: 'inventory', title: 'Inventory', description: 'Collect and organize relevant information in a clear way for the audience.'}
};


export {
    actions
}