import React, { useEffect, useState } from "react";
import {
  UserPersonaContainer,
  TextArea,
  Button,
} from "../ProfilePage.styled";
import UserPersonaModal from "../components/UserPersonaModal";
import { observer } from "mobx-react";
import { useMediaQueries } from "../../../../utils/mediaQueries";
import { useStore } from "../../../../hooks";
import {PermissionCodes, PermissionLevels } from "../../../../utils/enums";


const UserPersonaTab = observer(() => {
  const [agentAnswer, setAgentAnswer] = useState("");
  const [userPersona, setUserPersona] = useState("");
  const [agentAnswerLength, setAgentAnswerLength] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isMobile, isTablet } = useMediaQueries();
  const { authStore, profileStore } = useStore();

  useEffect(() => {
    setAgentAnswerLength(profileStore.userData.user_persona?.length || 0);
    setUserPersona(profileStore.userData.user_persona);
  }, [profileStore.userData.user_persona]);



  const handleAgentAnswerChange = (e) => {
    const text = e.target.value;
    if (text.length <= 1500) {
      setAgentAnswer(text);
      setAgentAnswerLength(text.length);
      profileStore.updateUserData("user_persona", text);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <UserPersonaContainer>
      <form onSubmit={handleSubmit}>
        <label htmlFor="agentAnswer">
          What do you want the agents to know about you to provide better
          answers?
        </label>

        {authStore.hasPermission(PermissionCodes['USER_PERSONA_FIELD'], PermissionLevels['VIEW']) && (
          <>
            <TextArea
              onChange={handleAgentAnswerChange}
              maxLength={1500}
              rows={4}
              readOnly
              isMobile={isMobile}
              isTablet={isTablet}
              value={userPersona || agentAnswer}
            />
            <div>{agentAnswerLength}/1500</div>
          </>
        )}

        {authStore.hasPermission(PermissionCodes['USER_PERSONA_BUILDER'], PermissionLevels['VIEW']) && (
          <Button type="submit">Persona builder</Button>
        )}

      </form>
      <UserPersonaModal
        isOpen={isModalOpen}
        onClose={closeModal}
        agentAnswer={agentAnswer}
      />
    </UserPersonaContainer>
  );
});

export default UserPersonaTab;
