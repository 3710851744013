import React, { useState, useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import { useNavigate, createSearchParams } from 'react-router-dom';
import {
  ModalOverlay,
  ModalContent,
  ModalWrapper,
  ModalImage,
  ModalTitle,
  ModalDescription,
  ModalDescriptionContainer,
  ModalStarters,
  ModalActions,
  CustomGenericListStyle,
  StartButtonText,
  KnowledgeBaseTableContainer,
  StartChatButtonContainer,
  ModalAccessHeaderTitle,
  VerticalButtonsContainer,
  VerticalButtonsContainer2,
  ModalFooter
} from "./ModalAgentsDetail.styled";
import { RoundedButton, Icon, IconButton } from "../../../../../elements";
import GenericList from "../../../../../elements/v2/GenericList/GenericList";
import ActionsList from "../../../../../elements/v2/ActionsCatalog/ActionsList";
import KnowledgeBaseTable from "../../../../../elements/v2/KnowledgeBaseTable/KnowledgeBaseTable";
import AccessTabs from "../AccessTabs/AccessTabs";
import VerticalButton from "../../../../../elements/verticalButton/VerticalButton";
import { getDummyContentTabs } from "../../consts";
import ConfirmationWrapper from "../../../AgentsTeam/Team/Components/ConfirmationWrapper/ConfirmationWrapper";
import { useStore } from "../../../../../hooks";
import { CloseButtonWrapper } from "../../../../../elements/v2/modals/Modals.styled";

const actionConfigs = {
  duplicate: {
    title: "Duplicate",
    message: "Caution: You are about to duplicate the agent. Are you sure you want to continue?",
    icon: "duplicate",
  },
  share: {
    title: "Share",
    message: "You are about to share this agent. Are you sure you want to continue?",
    icon: "Archive",
  },
  archive: {
    title: "Archive",
    message: "Caution: You are about to archive the agent. Are you sure you want to continue?",
    icon: "deleteOutline",
  },
};

const ModalAgentsDetail = observer(({ open, onClose, width, showGearIcon = false, dataTestId }) => {
  const navigate = useNavigate();
  const { agentStore } = useStore();
  const { selectedAgent } = agentStore
  const [agentActions, setAgentActions] = useState(selectedAgent?.actions || []);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);

  useEffect(() => {
    if (agentStore.account) {
      agentStore.onChangeAccount(agentStore.account);
    }
  }, [agentStore.account, agentStore]);

  const handleSaveAction = (newActions) => {
    setAgentActions(newActions);
  };

  const handleStartChat = () => {
    navigate({
      pathname: "/home",
      search: createSearchParams({
        agent: selectedAgent.id,
        customer: agentStore.account,
      }).toString(),
    });
  };

  const handleConfirmAction = () => {
    if(confirmAction === 'duplicate'){
      agentStore.duplicateAgent()
      onClose()
    }
    setShowConfirmModal(false);
  };

  const handleVerticalButtonClick = (action) => {
    setConfirmAction(action);
    setShowConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };

  if (!open || !selectedAgent) return null;

  return (
    <ModalOverlay>
      <ModalWrapper width={width}>
        <CloseButtonWrapper>
          <IconButton icon="close" width="16px" height="16px" onClick={onClose} dataTestId="close-modal-button" />
        </CloseButtonWrapper>
        <ModalContent onClick={(e) => e.stopPropagation()}>
          <VerticalButtonsContainer id="vertical-button-container">
            <VerticalButton dataTestId={"vertical-button-menu-modal"} options={[
              { title: 'Duplicate', onClick: () => handleVerticalButtonClick('duplicate') },
              // { title: "Archive", onClick: () => handleVerticalButtonClick('archive') },
              // { title: "Share", onClick: () => handleVerticalButtonClick('share') },
              {
                title: 'Playground',
                onClick: () => {
                  onClose && onClose();
                  navigate({
                    pathname: "/v2/assistants/playground",
                    search: createSearchParams({ agent: selectedAgent.id }).toString(),
                  });
                }
              },
            ]} />
          </VerticalButtonsContainer>
          {showGearIcon && (
            <VerticalButtonsContainer2 id="vertical-button-container2">
              <Icon name="gearBlue" width="20px" height="20px" />
            </VerticalButtonsContainer2>
          )}

          <ModalImage src={selectedAgent.image} alt="Modal Image" sx={{ width: 100, height: 100 }}/>
          <ModalTitle>{selectedAgent.name}</ModalTitle>
          <ModalDescriptionContainer>
            <ModalDescription>{selectedAgent.description1}</ModalDescription>
          </ModalDescriptionContainer>
          {/* <ModalConversationsAndMembersContainer>
            <div>
              <ModalConversations>
                <ModalConversationsAndMembersTitle>Conversations</ModalConversationsAndMembersTitle>
                <ModalConversationsAndMembersDescription>
                  {selectedAgent.conversations || 0} conversations
                </ModalConversationsAndMembersDescription>
              </ModalConversations>
            </div>
            <ModalMembersContainer>
              <ModalConversations>
                <ModalConversationsAndMembersTitle>Members</ModalConversationsAndMembersTitle>
                <ModalConversationsAndMembersDescription>
                  {selectedAgent.members || 0} members
                </ModalConversationsAndMembersDescription>
              </ModalConversations>
            </ModalMembersContainer>
          </ModalConversationsAndMembersContainer> */}
          {/* <AccessTabs access={selectedAgent.access || []} tabsNames={getDummyContentTabs().names} /> */}
          <ModalStarters id="starters">
            {selectedAgent.conversation_starters && selectedAgent.conversation_starters.length > 0 && (
              <>
                <CustomGenericListStyle />
                <GenericList title="Starters" listItems={selectedAgent.conversation_starters} />
              </>
            )}
          </ModalStarters>
          <ModalActions id="agent-actions">
            <CustomGenericListStyle />
            {selectedAgent.actions && selectedAgent.actions.length > 0 && (
              <ActionsList agentActions={selectedAgent.actions} onSave={handleSaveAction} />
            )}
          </ModalActions>
          <ModalAccessHeaderTitle>Knowledge base</ModalAccessHeaderTitle>
          <KnowledgeBaseTableContainer id="knowledge-base">
            <KnowledgeBaseTable dataTestId="knowledge-base-header" fileNames={selectedAgent.knowledgeBase} showAddButton={false} vaultName={selectedAgent.original?.customer_id}/>
          </KnowledgeBaseTableContainer>
        </ModalContent>
        <ModalFooter>
          <StartChatButtonContainer id="startchat-container">
            <RoundedButton onClick={handleStartChat} icon={{ position: 'left', element: <Icon name="conversation" /> }} width={'500px'} height={'48px'} dataTestId="start-chat-button"
            >
              <StartButtonText>Start chat</StartButtonText>
            </RoundedButton>
          </StartChatButtonContainer>
        </ModalFooter>
        <ConfirmationWrapper
          isOpen={showConfirmModal}
          actionConfig={actionConfigs[confirmAction]}
          onConfirm={handleConfirmAction}
          onClose={handleCloseConfirmModal}
          dataTestId={"confirmation-modal"}
        />
      </ModalWrapper>
    </ModalOverlay>
  );
});

export default ModalAgentsDetail;
