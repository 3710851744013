import React, { useState } from "react";
import AnnAvatar from "../../../../../images/AnnAvatar.svg";
import UserAvatar from "../../../../../images/UserAvatar.svg";
import Markdown from "react-markdown";
import {
  MessageContainer,
  MessageWrapper,
  ChatWindowAvatar,
  MessageButtonsWrapper,
  EditMessageWrapper,
  EditMessageText,
  EditMessageInput,
  EditMessageInputWrapper,
} from "./ChatBox.styled";
import { Icon, RoundedButton } from "../../../../../elements";

const Message = ({ messageItem, key, displayButtons, width, agentAvatar }) => {
  const [displayMessageButtons, setDisplayMessageButtons] =
    useState(displayButtons);
  const [displayMessageView, setDisplayMessageView] = useState(null);

  return (
    <MessageContainer key={key} itemType={messageItem.type} width={width}>
      {messageItem.type === "sent" ? (
        <ChatWindowAvatar src={UserAvatar} alt="Agent Avatar" />
      ) : (
        <ChatWindowAvatar src={agentAvatar || AnnAvatar} alt="Agent Avatar" />
      )}

      {!displayMessageView && (
        <MessageWrapper itemType={messageItem.type}>
          <Markdown>{messageItem.message}</Markdown>
          {/* {messageItem.type !== 'sent' && displayMessageButtons &&
                    <MessageButtonsWrapper>
                        <a onClick={() => setDisplayMessageButtons(false)}>
                            <Icon name={"thumbsUp"} height={'15px'} width={'15px'} />
                        </a>
                        <a onClick={() => setDisplayMessageView('initial')}>
                            <Icon name={"thumbsDown"} height={'15px'} width={'15px'} />
                        </a>
                    </MessageButtonsWrapper>
                } */}
        </MessageWrapper>
      )}

      {messageItem.type !== "sent" && displayMessageView === "initial" && (
        <EditMessageWrapper>
          <Icon name={"head1"} height={"120px"} width={"120px"} />
          <EditMessageText>Help me to improve!</EditMessageText>
          <RoundedButton
            width="20%"
            onClick={() => setDisplayMessageView("edit")}
          >
            Train me
          </RoundedButton>
        </EditMessageWrapper>
      )}

      {messageItem.type !== "sent" && displayMessageView === "edit" && (
        <EditMessageInputWrapper>
          <EditMessageInput
            placeholder="Feel free to enter the correct answer right here."
            rows="10"
            cols="50"
          ></EditMessageInput>

          <MessageButtonsWrapper mt="5px">
            <Icon name={"thumbsDown"} height={"15px"} width={"15px"} />
            <RoundedButton
              width="150px"
              height="40px"
              fontSize="15px"
              onClick={() => {
                setDisplayMessageView(null);
                setDisplayMessageButtons(false);
              }}
            >
              Train
            </RoundedButton>
          </MessageButtonsWrapper>
        </EditMessageInputWrapper>
      )}
    </MessageContainer>
  );
};

export default Message;
