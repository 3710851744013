import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useStore } from "../../../../hooks";
import {
  TableWrapper,
  headCellStyle,
  bodyCellStyle,
  toolbarStyle,
  tableContainerStyle,
  tablePaperStyle
} from "../../../../elements/v2/Tables/Table.styled";
import { useMediaQueries } from "../../../../utils/mediaQueries";
import TooltipTable from "../../../../elements/v2/TooltipTable/TooltipTable";
import { ContainerLog, HeadCellLog } from "./Marketing.styled";
import Spinner from "../../../../components/common/Spinner";
import { SuccessfulModal } from "../../../../elements";
import { auditLogColumns } from "./AuditLogColumns";

const AuditLogs = observer(() => {
  const { auditLogStore, authStore } = useStore();
  const { isMobile, isTablet, isDesktop } = useMediaQueries();

  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [isLoading, setIsLoading] = useState(false);

  

  useEffect(() => {
    auditLogStore.fetchAuditLogs({
        account: authStore.selectedAccount.id,
        topic: 'conversation_event',
        parameters: {
          count_records: true,
          page_size: pagination.pageSize,
          page: pagination.pageIndex + 1,
          order_by: { id: 'insert_date', desc: sorting[0]?.desc || false },
          search: globalFilter,
        },
      });
    }, [auditLogStore, globalFilter, pagination, sorting]);

  const table = useMaterialReactTable({
    columns: auditLogColumns,
    data: auditLogStore.auditLogs,
    manualSorting: true,
    manualGlobalFilter: true,
    rowCount: auditLogStore.totalRowCount || 0,
    initialState: { sorting },
    muiTableContainerProps: { sx: tableContainerStyle },
    muiTablePaperProps: { elevation: 0, sx: tablePaperStyle },
    muiTableHeadCellProps: { sx: HeadCellLog },
    muiTableBodyCellProps: { sx: bodyCellStyle },

    muiTopToolbarProps: { sx: toolbarStyle },
    muiSearchTextFieldProps: {
      variant: 'outlined',
      placeholder: 'Search',
      InputProps: { style: { fontSize: '12px' } },
    },
    showGlobalFilter: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableGrouping: false,
    enableTopToolbar: true,
  });

  return (
    <ContainerLog>
        {auditLogStore.isLoading ? (
            <Spinner />
        ) : (
            <TableWrapper>
                <MaterialReactTable table={table} />
            </TableWrapper>
        )}
        {auditLogStore.isError && (
        <SuccessfulModal
          isOpen={auditLogStore.isError}
          onClose={() => auditLogStore.clearError()}
          title="Oops!"
          subtitle="An error occurred."
          subtitle2={auditLogStore.errorMessage || "Please try again later."}
          zIndex={true}
        />
      )}
    </ContainerLog>
  );
});

export default AuditLogs;
