import styled, { createGlobalStyle } from "styled-components";
import { Fab } from "@mui/material";
import { DefaultTheme as theme } from "../../theme";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-template-rows: ${(props) =>
    props.title ? "48px 20px 1fr 50px" : "48px 0px 1fr 50px"};
  grid-template-areas: "top top" "menu header" "menu content";
  height: 100vh;
  overflow: hidden;
`;

const MenuWrapper = styled.div`
  grid-area: menu;
  overflow-x: hidden;
  width: ${(props) => (props.isCollapsed ? "60px" : "250px")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.1s ease-in-out;
  z-index: 99;
`;

const TopMenu = styled.div`
  grid-area: top;
  background: linear-gradient(
    166deg,
    rgba(4, 79, 97, 1) 10%,
    rgba(18, 26, 41, 1) 50%,
    rgba(4, 36, 90, 1) 100%
  );
  width: 100%;
  border-radius: 0;
  display: flex;
  justify-content: space-between;
  padding: 8px 24px 8px 14px;
  max-height: 48px;
`;

const TopMenuAccountInfo = styled.div`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
  gap: 16px;

  & > div:first-child {
    background-color: ${(props) => props.theme.colors.davysGrey};
    font-weight: normal;
  }
`;

const TopNotification = styled.div`
  order: 1;
  flex: 0 1 auto;
  align-self: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
`;

const FloatingChatWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  overflow-x: hidden;
  overflow-y: hidden;
  width: ${(props) => (props.isCollapsed ? "0px" : "25rem")};
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1502;
  box-shadow: -9px 0px 9.100000381469727px 0px #0000001a;
`;

const OpenConciergeButton = styled(Fab)`
  && {
    position: fixed;
    right: 0;
    top: 5%;
  }
`;

const CloseConciergeButton = styled(Fab)`
  && {
    position: fixed;
    display: block;
    margin-left: -2rem;
    top: 50%;
  }
`;

const Menu = styled.div`
  left: 0;
  top: 0;
  bottom: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  width: ${(props) => (props.isCollapsed ? "60px" : "250px")};
  transition: background 0.1s;
  background: linear-gradient(
    166deg,
    rgba(4, 79, 97, 1) 10%,
    rgba(18, 26, 41, 1) 50%,
    rgba(4, 36, 90, 1) 100%
  );
  color: ${(props) => props.theme.colors.white};
  gap: ${(props) => (props.isCollapsed ? "0" : "24px")};
  border: none;
  ul {
    width: 100%;
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    gap: 16px;
  }

  li {
    display: flex;
    box-sizing: border-box;

    a {
      width: 100%;
      display: flex;
      flex-direction: row;
      text-decoration: none;
      color: white;
      padding: 0 15px;

      & .link-menu {
        padding: 0 25px 0 15px;
        line-height: 2;
        ${(props) =>
          props.isMenuCollapsed ? "display: none" : "display: inherit"};
        transition: display 0.1s ease-in-out;
      }
    }
  }
`;

const AccountSelectorWrapper = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  flex-direction: row;

  &:hover {
    background: ${(props) => props.theme.colors.activeBlue};
    border-radius: 10px;
    cursor: pointer;
  }
`;

const AccountSelectorInitials = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(42, 42, 42, 1);
  margin: 3px 10px;
  border-radius: 6px;
  ${(props) => props.theme.fonts.variant.extraBold}
`;

const AccountSelectorContent = styled.div`
  display: ${(props) => (props.isMenuCollapsed ? "none" : "flex")};
  flex-direction: row;
  width: calc(100% - 70px);
  margin-right: 10px;
  align-items: center;
  justify-content: space-between;
`;

const AccountSelectorContentData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  font-size: ${(props) => props.theme.fonts.size.small};
  box-sizing: border-box;
  width: calc(100% - 15px);

  & div {
    ${(props) => props.theme.fonts.variant.regular};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const SubMenu = styled.div`
  position: absolute;
  width: 250px;
  height: 100vh;
  top: 0;
  right: -250px;
  display: none;
  background-color: ${(props) => props.theme.colors.greyNine};
  overflow-x: hidden;
  overflow-y: scroll;

  ul {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    padding: 0 20px;
    height: 50px;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: ${(props) => props.theme.colors.primary};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.grey};
    border-radius: 5px;
    pointer-events: none;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${(props) => props.theme.colors.grey};
  }

  ${AccountSelectorWrapper}:hover & {
    display: block;
  }
`;

const MenuFooter = styled.div`
  background: linear-gradient(
    166deg,
    rgba(5, 74, 92, 1) 0%,
    rgba(18, 26, 41, 1) 30%,
    rgba(4, 37, 91, 1) 100%
  );
  position: fixed;
  left: 0;
  bottom: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-end;
  cursor: pointer;
  width: ${(props) => (props.isMenuCollapsed ? "60px" : "250px")};

  ul {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    display: flex;
    padding: 7px 0;
    box-sizing: border-box;
    height: 50px;
    align-items: center;
    width: 100%;

    a {
      width: 100%;
      display: flex;
      flex-direction: row;
      text-decoration: none;
      color: white;
      padding: 0 15px;
      margin: 0;

      & .link-menu {
        padding: 0 15px;
        line-height: 2;
        ${(props) =>
          props.isMenuCollapsed ? "display: none" : "display: inherit"};
        transition: display 0.1s ease-in-out;
      }
    }
    transition: background 0.1s ease-in-out;
    &:hover {
      background: #585858;
    }
  }
`;

const Content = styled.div`
  grid-area: content;
  overflow-y: auto;
  height: calc(100vh - 48px);
`;

const Header = styled.div`
  grid-area: header;
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.primary};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 20px;
`;

const TitlePage = styled.div`
  ${(props) => props.theme.fonts.variant.bold}
  font-size: ${(props) => props.theme.fonts.size.medium};
  line-height: 23px;
  letter-spacing: -0.57px;
  margin-left: 20px;
  font-size: 14px;
`;

const MenuHeaderContainer = styled.ul`
  margin-top: 50%;
`;

const Logo = styled.div`
  display: flex;
  padding: 10px;
  cursor: pointer;
`;

const Arrow = styled.div`
  display: flex;
  padding: 15px;
  position: absolute;
  right: 10px;
`;

const FooterContainer = styled.div`
  width: 100%;

  &.footer-container {
    height: 300px;
  }

  &.footer-container ul {
    display: flex;
    justify-content: flex-start;
  }
`;

const SettingsUl = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  gap: 16px;

  & > li {
    cursor: pointer;
  }
`;

const PoweredBy = styled.div`
    font-size: 0.7em;
    padding: 2px 10px 2px 30px;
    color: ${(props) => props.theme.colors.white};
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: ${(props) =>
    props.isMenuCollapsed ? "center" : "flex-start"};
  align-items: center;
  height: 100%;
`;

const SubMenuHeader = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.isMenuCollapsed ? "center" : "flex-start"};
  align-items: center;
  padding: ${(props) => (props.isMenuCollapsed ? "0" : "0 30px")};
  cursor: pointer;
  height: 50px;
  &:hover {
    background: ${(props) => props.theme.colors.celticBlue};
    border-radius: 10px;
  }
`;

const OpenCloseSidebarWrapper = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.isCollapsed ? "center" : "space-between"};
  flex-direction: row;
  width: 100%;
  padding: 0 24px;
  margin-top: 15px;
  align-items: center;
`;

const ModalSubMenuContainer = styled.div`
  position: absolute;
  background: linear-gradient(
    45deg,
    rgba(18, 25, 40, 1) 30%,
    rgba(13, 33, 55, 1) 100%
  );
  border: 1px solid ${(props) => props.theme.colors.black};
  padding: ${(props) => (props.avatar ? `16px` : `20px`)};
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: ${(props) => props.width || (props.avatar ? `157px` : `250px`)};
  top: ${(props) =>
    props.avatar ? `${props.position.top + 20}px` : `${props.position.top}px`};
  left: ${(props) =>
    props.avatar ? "auto" : `calc(${props.position.left}px + 10px)`};
  right: ${(props) =>
    props.avatar ? `calc(100% - ${props.position.left}px - 20px)` : "auto"};
  color: ${(props) => props.theme.colors.white};
`;

const ModalSubMenuHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  border-bottom: 3px solid ${(props) => props.theme.colors.white};
  font-weight: bold;
  margin-bottom: 1rem;
  padding: 5px 0;
  flex-direction: column;
`;

const ModalSubMenuSubtitle = styled.div`
    font-weight: 400;
`;

const ModalSubMenuBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;

  & div {
    display: flex;
    cursor: pointer;
  }
`;
const ModalSubMenuItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const CustomAccountModalContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  min-width: 541px;
  min-height: 449px;
  border: 1px solid ${(props) => props.theme.colors.highClearGray};
`;

const CustomAccountModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  margin-bottom: 1rem;
`;

const CustomAccountModalHeaderTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  width: 100%;
`;

const CustomAccountModalHeaderTitle = styled.p`
  order: 0;
  font-size: 16px;
  font-weight: bold;
  flex: 1 1 auto;
`;

const CustomAccountModalHeaderClose = styled.p`
  order: 1;
  flex: 0 1 auto;
  cursor: pointer;
`;

const CustomAccountModalBody = styled.div`
  max-height: 321px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & ul {
    list-style: none;
    padding: 0;
    margin: 0;
    overflow-y: scroll;
    border: 1px solid ${(props) => props.theme.colors.highClearGray};
    border-radius: 8px;
  }

  & ul li {
    min-height: 50px;
    border-bottom: 1px solid ${(props) => props.theme.colors.highClearGray};
    color: ${(props) => props.theme.colors.davysGreyAlternative};
    display: flex;
    align-items: center;
  }
`;
const CustomAccountModalBodyListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  width: 100%;
`;

const CustomAccountModalBodyListContainerIcon = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.black};
  color: ${(props) => props.theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  cursor: ${(props) => props.isSuperAdmin ? 'pointer' : 'default'};
`;

const CustomAccountModalBodyListContainerSwitchButton = styled.div`
  height: 32px;
  width: 86px;
  border-radius: 8px;
  border: 1px solid
    ${(props) =>
      props.inUse === true
        ? props.theme.colors.veroneseGreen
        : props.theme.colors.activeBlue};
  background: ${(props) => props.theme.colors.white};
  color: ${(props) =>
    props.inUse === true
      ? props.theme.colors.caribbeanGreen
      : props.theme.colors.activeBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const CustomAccountModalBodyListContainerText = styled.div`
  display: flex;
  align-items: center;
`;

const InputSearchContainer = styled.div`
  position: relative;
  width: 100%;
`;

const InputSearchField = styled.input`
  width: 100%;
  padding: 10px 40px 10px 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.colors.highClearGray};

  &:focus {
    outline: none;
    border: 1px solid ${(props) => props.theme.colors.highClearGray} !important;
  }
`;

const SearchIcon = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

const CollapsedLogoWrapper = styled.div`
  cursor: pointer;
`;

const CollapsedCloseWrapper = styled.div`
  cursor: pointer;
`;

const ToogleIconWrapper = styled.div`
  max-height: 1rem;
  max-width: 1rem;
  cursor: pointer;
  margin: 12px 0;
`;


export {
  Wrapper,
  MenuWrapper,
  Menu,
  SubMenu,
  MenuFooter,
  Content,
  Header,
  TitlePage,
  FloatingChatWrapper,
  OpenConciergeButton,
  CloseConciergeButton,
  AccountSelectorWrapper,
  AccountSelectorInitials,
  AccountSelectorContent,
  AccountSelectorContentData,
  MenuHeaderContainer,
  Logo,
  Arrow,
  FooterContainer,
  SettingsUl,
  PoweredBy,
  LinkContainer,
  SubMenuHeader,
  OpenCloseSidebarWrapper,
  ModalSubMenuContainer,
  ModalSubMenuHeader,
  ModalSubMenuBody,
  ModalSubMenuItems,
  CustomAccountModalContainer,
  Overlay,
  CustomAccountModalHeader,
  CustomAccountModalBody,
  CustomAccountModalHeaderTitle,
  CustomAccountModalHeaderTitleWrapper,
  CustomAccountModalHeaderClose,
  CustomAccountModalBodyListContainer,
  CustomAccountModalBodyListContainerIcon,
  CustomAccountModalBodyListContainerSwitchButton,
  CustomAccountModalBodyListContainerText,
  InputSearchContainer,
  InputSearchField,
  SearchIcon,
  TopMenu,
  TopMenuAccountInfo,
  TopNotification,
  CollapsedLogoWrapper,
  CollapsedCloseWrapper,
  ToogleIconWrapper,
  ModalSubMenuSubtitle,
};
