export const AgentDomain = {
    CONCIERGE: "concierge"
};

export const ApiResponseStatus = {
    DONE: "done",
    GUARDRAIL: "guardrail",
    STREAM: "stream",
    METADATA: "metadata"
}

export const MessaggeType = {
    SENT: "sent",
    RECEIVED: "received"
}

export const MarketingStatus = {
    ACTIVE: "ACTIVE",
    DRAFT: "DRAFT",
    CANCELLED: "CANCELLED",
}


export const PermissionCodes = {
    USER_PERSONA_TAB: "USER_PERSONA_TAB",
    USER_PERSONA_FIELD: "USER_PERSONA_FIELD",
    USER_PERSONA_BUILDER: "USER_PERSONA_BUILDER"
}

export const PermissionLevels = {
    VIEW: "v",
    CREATE: "c",
    UPDATE: "u",
    DELETE: "d"
}