import React from "react";
import dayjs from "dayjs";
import TooltipTable from "../../../../elements/v2/TooltipTable/TooltipTable";

export const auditLogColumns = [
  { accessorKey: "user_id", header: "User" },
  { accessorKey: "action", header: "Action" },
  {
    accessorKey: "description",
    header: "Description",
    size: 600,
    Cell: ({ cell }) => <TooltipTable description={cell.getValue()} />,
  },
  {
    accessorKey: "insert_date",
    header: "Timestamp",
    Cell: ({ cell }) =>
      dayjs(cell.getValue()).format("MM/DD/YYYY - HH:mm"),
  },
];
