import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../hooks";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  QuestionsContainer,
  ScrollableContent,
  QuestionInput,
  NameInput,
  AddQuestionButton,
  SaveButton,
  BackButton,
  QuestionLabel,
  HeaderTitle,
  QuestionCard,
  QuestionHeader,
  DeleteButton,
  TopBar,
  ButtonsContainer,
} from "./QuestionsForm.styled";
import { ConfirmationModal } from "../../../elements/v2/modals";

const QuestionsForm = observer(() => {
  const navigate = useNavigate();
  const { questionsStore, authStore } = useStore();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const scrollableRef = useRef(null);

  useEffect(() => {
    if (authStore.selectedAccount?.id) {
      questionsStore.setCustomer(authStore.selectedAccount.id);
    }

    if (questionsStore.currentQuestionnaire) {
      setName(questionsStore.currentQuestionnaire.name || "");
      setDescription(questionsStore.currentQuestionnaire.description || "");
      questionsStore.setQuestions(
        questionsStore.currentQuestionnaire.questions || [{ text: "" }]
      );
    } else {
      setName("");
      setDescription("");
      questionsStore.setQuestions([{ text: "" }]);
    }
  }, [authStore.selectedAccount?.id, questionsStore.currentQuestionnaire]);

  const handleAddQuestion = () => {
    questionsStore.addQuestion();
    setTimeout(() => {
      scrollableRef.current?.scrollTo({
        top: scrollableRef.current.scrollHeight,
        behavior: "smooth",
      });
    }, 100);
  };

  const handleRemoveQuestion = (index) => {
    questionsStore.removeQuestion(index);
  };

  const handleSave = async () => {
    if (!name.trim()) {
      questionsStore.setError("The questionnaire name is required.");
      return;
    }

    const questions = questionsStore.questions.map((q) => q.text.trim()).filter(Boolean);

    if (questions.length === 0) {
      questionsStore.setError("At least one valid question is required.");
      return;
    }

    try {
      questionsStore.isSaving = true;
      const questionnaireId = questionsStore.currentQuestionnaire?.id || "";
      await questionsStore.saveQuestionnaire(name, questions, questionnaireId, description);

      navigate("/v2/questions");
    } catch (error) {
      questionsStore.setError("Failed to save the questionnaire. Please try again.");
    } finally {
      questionsStore.isSaving = false;
    }
  };

  const handleBack = () => {
    navigate("/v2/questions");
  };

  const adjustHeight = (element) => {
    element.style.height = "auto";
    element.style.height = `${Math.max(element.scrollHeight, 48)}px`;
  };

  const handleTextareaChange = (e, index) => {
    adjustHeight(e.target);
    questionsStore.updateQuestion(index, e.target.value);
  };

  return (
    <>
      <TopBar>
        <ButtonsContainer>
          <BackButton onClick={handleBack}>Back</BackButton>
          <SaveButton
            onClick={handleSave}
            disabled={
              questionsStore.isSaving ||
              !name.trim() ||
              questionsStore.questions.some((q) => !q.text.trim())
            }
          >
            {questionsStore.isSaving ? "Loading..." : "Save"}
          </SaveButton>
        </ButtonsContainer>
      </TopBar>

      <QuestionsContainer>
        <ScrollableContent ref={scrollableRef}>
          <QuestionCard>
            <QuestionLabel>Name *</QuestionLabel>
            <NameInput
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter questionnaire name"
              type="text"
            />
          </QuestionCard>
          <QuestionCard>
            <QuestionLabel>Description</QuestionLabel>
            <NameInput
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter questionnaire description"
              type="text"
            />
          </QuestionCard>

          {questionsStore.questions.map((question, index) => (
            <QuestionCard key={index}>
              <QuestionHeader>
                <QuestionLabel>Question {index + 1} *</QuestionLabel>
                <DeleteButton
                  onClick={() => handleRemoveQuestion(index)}
                  aria-label={`Remove Question ${index + 1}`}
                  disabled={questionsStore.questions.length === 1}
                >
                  <CloseIcon />
                </DeleteButton>
              </QuestionHeader>
              <QuestionInput
                value={question.text}
                onChange={(e) => handleTextareaChange(e, index)}
                placeholder={`Type your question here`}
                rows={1}
                onInput={(e) => adjustHeight(e.target)}
              />
              {index === questionsStore.questions.length - 1 && (
                <AddQuestionButton onClick={handleAddQuestion} aria-label="Add Question">
                  <span>
                    <AddIcon />
                  </span>
                </AddQuestionButton>
              )}
            </QuestionCard>
          ))}
        </ScrollableContent>
      </QuestionsContainer>

      {questionsStore.isError && (
        <ConfirmationModal
          isOpen={questionsStore.isError}
          icon="error"
          onClose={() => questionsStore.resetErrorState()}
          title="Error"
          message={questionsStore.errorMessage}
        />
      )}
    </>
  );
});

export default QuestionsForm;
