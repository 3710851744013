import React, { useState, useEffect } from "react";
import { useStore } from "../../hooks";
import { observer } from "mobx-react";
import { RoundedButton, SuccessfulModal } from "../../elements";

import { useMediaQueries } from "../../utils/mediaQueries";

import {
  CustomGrid,
  CustomGenericListStyle,
  MobileHiddenRoundedButton,
} from "./ContactList.styled";

import ContactForm from "./contactInformation";
import IconSelect from "../../elements/v2/Selects/IconSelect/IconSelect";
import { SaveSingleContactModal } from "./components";

const ContactList = observer(() => {
  const { userStore, changeTitle, authStore, contactStore } = useStore();
  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");
  const { isMobile } = useMediaQueries();
  const [createContactModal, setCreateContactModal] = useState(false);

  userStore.setPage("list");

  changeTitle("Contacts");

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  useEffect(() => {
    if (isMobile) {
      authStore.setNavbarActionElement(
        <RoundedButton
          kind="blue"
          width={"100px"}
          height={"30px"}
          fontSize={"12px"}
        >
          Add New
        </RoundedButton>
      );
    }
  });

  return (
    <>
      <SuccessfulModal
        isOpen={contactStore.isError}
        title="Oops!"
        subtitle="An error occurred."
        subtitle2="Please try again later."
      />
      
      <SaveSingleContactModal
        createContactModal={createContactModal}
        setCreateContactModal={setCreateContactModal}
      />

      {
        <CustomGrid>
          <MobileHiddenRoundedButton>
            <IconSelect
              placeholder="Add New"
              kind="blue"
              options={[{ name: "Single Contact" }]}
              setClickedOption={() => setCreateContactModal(true)}
            />
          </MobileHiddenRoundedButton>

          <CustomGenericListStyle />
        </CustomGrid>
      }
      <ContactForm searchValue={debouncedSearchValue} />
    </>
  );
});

export default ContactList;
