import { action, makeObservable, observable, flow } from "mobx";
import { API } from "aws-amplify";
import {
	deleteMarketingCampaign,
  getCampaigns,
  getUploadUrlFile,
  getUrlFile,
  putCampaignMarketing
} from "../../graphql/queries";
import _ from "lodash";
import dayjs from "dayjs";
import {v4 as uuidv4} from 'uuid';
import axios from "axios";
import { MarketingStatus } from "../../utils/enums";

class MarketingStore {
	isLoading = false;
	isError = false;
	isSuccessful = false;
	emailTemplatePayload = [];
	emailTemplateBinding = [];
	selectedAgent = null;
	marketingConversations = [];
	marketingConversation = {};
	emailTemplateExportAction = null;
	name = "";
	description = "";
	startDate = null;
    endDate = null;
    startTime = null;
    endTime = null;
    minEndTime = null;
	nameRead = "";
	descriptionRead = "";
    startDateRead = null;
    endDateRead = null;
    startTimeRead = null;
    endTimeRead = null;
	activeCampaign = true;
	status = MarketingStatus.DRAFT;
	confirmationDialogMessage = null;
	contactFile = {};
	numberSegments = 0;
	contactCSVFile = null;
	successfulAlert = { isOpen: false, title: '', message: '' };
	selectedRows = [];

	constructor() {
		makeObservable(this, {
			isLoading: observable,
			isError: observable,
			isSuccessful: observable,
			selectedAgent: observable,
			marketingConversations: observable,
			marketingConversation: observable,
			emailTemplateExportAction: observable,
			name: observable,
			description: observable,
			nameRead: observable,
			descriptionRead: observable,
			startDateRead: observable,
            endDateRead: observable,
            startTimeRead: observable,
            endTimeRead: observable,
			startDate: observable,
            endDate: observable,
            startTime: observable,
            endTime: observable,
			activeCampaign: observable,
			status: observable,
			confirmationDialogMessage: observable,
			contactFile: observable,
			numberSegments: observable,
			contactCSVFile: observable,
			successfulAlert: observable,
			selectedRows: observable,
			setMarketingAttribute: action,
			clear: action,
			onClearError: action,
			setEmailTemplateExportAction: action,
			setSelectedAgent: action,
			update: action,
			clearConfirmationDialog: action,
			getMarketingConversations: flow,
			newMarketingConversation: flow,
			save: flow,
			cancel: flow,
			duplicate: flow,
			duplicateS3File: flow,
			deleteCampaignMarketing: flow
		});
	}

	clearConfirmationDialog(){
		this.confirmationDialogMessage = null;
	}

	update(){
        this.nameRead = this.name;
        this.descriptionRead = this.description;
        this.startDateRead = this.startDate.set('hour', this.startTime?.get('hour') || 0 ).set('minute', this.startTime?.get('minute') || 0);
        this.endDateRead = this.endDate && this.endTime ? this.endDate.set('hour', this.endTime?.get('hour')).set('minute', this.endTime.get('minute')) : "";
    }

	*deleteCampaignMarketing(customer, id){
		try {
			this.isLoading = true;
			this.isError = false;

			return yield API.graphql({
				query: deleteMarketingCampaign,
				variables: { 
					input: { 
						customer,
						id
					} 
				},
				authMode :'AMAZON_COGNITO_USER_POOLS'
			});
		} catch (error) {
			this.isError = true;
		} finally {
			this.isLoading = false;
		}
	}

	*newMarketingConversation(customer){
		try {
			this.isLoading = true;
			this.isError = false;

			const response = yield API.graphql({
				query: putCampaignMarketing,
				variables: { 
					input: { 
						customer, 
						name: "New Marketing Campaign",
						start_datetime: dayjs(),
						end_datetime: dayjs().add(1, 'day'),
						status: MarketingStatus.DRAFT,
						metadata: JSON.stringify({
							description: "This is a marketing campaign"
						}),
						contact_file: JSON.stringify({}), 
						agent_id: ""
					} 
				},
				authMode :'AMAZON_COGNITO_USER_POOLS'
			});
			const newConversation = JSON.parse(response.data?.putCampaignMarketing?.body);
			return newConversation.id

		} catch (error) {
			this.isError = true;
		} finally {
			this.isLoading = false;
		}
	}

	*getMarketingConversations(customer, id=""){
		try {
			this.isLoading = true;
			this.isError = false;

			const response = yield API.graphql({
				query: getCampaigns,
				variables: { input: { customer, id} },
				authMode :'AMAZON_COGNITO_USER_POOLS'
			});
			const conversations = JSON.parse(response.data?.getCampaigns?.body);

			if(id){
				this.marketingConversation = conversations;
				this.selectedAgent = conversations.agent_id;
				this.name = this.nameRead = conversations.name;
				this.description = this.descriptionRead = conversations.metadata?.description;
				this.startDateRead = this.startDate = this.startTime = conversations.start_datetime ? dayjs(conversations.start_datetime) : null;
				this.endDateRead = this.endDate = this.endTime = conversations.end_datetime ? dayjs(conversations.end_datetime) : null;
				this.activeCampaign = conversations.status === MarketingStatus.ACTIVE;
				this.contactFile = conversations.contact_file;
				this.status = conversations.status;
				this.contactCSVFile = conversations.metadata?.uploaded_contact_file;
			}else{
				this.marketingConversations = conversations
			}

		} catch (error) {
			this.isError = true;
		} finally {
			this.isLoading = false;
		}
	}

	updateBindingsAndPayload(payload = [], bindings = []) {
		this.emailTemplatePayload = _.cloneDeep(payload);
		this.emailTemplateBinding = _.cloneDeep(bindings);
	}

	setMarketingAttribute(attribute, value) {
		this[attribute] = value;
	}

	clear() {
		this.isLoading = false;
		this.isError = false;
		this.isSuccessful = false;
		this.emailTemplatePayload = [];
		this.emailTemplateBinding = [];
		this.selectedAgent = null;
		this.marketingConversations = [];
		this.marketingConversation = {};
		this.emailTemplateExportAction = null;
		this.name = "";
		this.description = "";
		this.startDate = null;
		this.endDate = null;
		this.startTime = null;
		this.endTime = null;
		this.minEndTime = null;
		this.nameRead = "";
		this.descriptionRead = "";
		this.startDateRead = null;
		this.endDateRead = null;
		this.startTimeRead = null;
		this.endTimeRead = null;
		this.activeCampaign = true;
		this.status = MarketingStatus.DRAFT;
		this.confirmationDialogMessage = null;
		this.contactFile = {};
		this.numberSegments = 0;
		this.contactCSVFile = null;
		this.successfulAlert = { isOpen: false, title: '', message: '' };
		this.selectedRows = [];
	}

	onClearError() {
		this.isError = false;
	}

	setSelectedAgent(id){
		this.selectedAgent = id;
	}

	setEmailTemplateExportAction(action){
		this.emailTemplateExportAction = action;
	}

	*save(customer, filters, segment_count){
		try {
			this.isLoading = true;
			this.isError = false;

			const response = yield API.graphql({
				query: putCampaignMarketing,
				variables: { 
					input: {
						name: this.nameRead,
						id: this.marketingConversation.id,
						customer, 
						start_datetime: this.startDate.set('hour', this.startTime?.get('hour') || 0 ).set('minute', this.startTime?.get('minute') || 0),
						end_datetime: this.endDate.set('hour', this.endTime?.get('hour') || 0).set('minute', this.endTime.get('minute') || 0),
						status: this.status,
						metadata: JSON.stringify({
							description: this.descriptionRead,
							segment_count: filters.length ? segment_count : (this.numberSegments || this.marketingConversation.metadata?.segment_count),
							filters: filters,
							actions: [{
								action: "send_email",
								id:  this.marketingConversation.metadata?.actions?.[0]?.id || uuidv4(),
								binding: this.emailTemplateBinding,
								payload: this.emailTemplatePayload
							}],
							uploaded_contact_file: filters.length ? null : this.contactCSVFile
						}),
						contact_file: filters.length ? JSON.stringify({}) : JSON.stringify(this.contactFile), 
						agent_id: this.selectedAgent
					} 
				},
				authMode :'AMAZON_COGNITO_USER_POOLS'
			});

			const updatedConversation = JSON.parse(response.data?.putCampaignMarketing?.body);

			this.isSuccessful = true;
			this.marketingConversation = updatedConversation;
			this.status = updatedConversation.status
			this.activeCampaign = this.status === MarketingStatus.ACTIVE

		} catch (error) {
			this.isError = true;
		} finally {
			this.isLoading = false;
		}

	}

	*cancel(customer){
		try {
			this.isLoading = true;
			this.isError = false;

			const response = yield API.graphql({
				query: putCampaignMarketing,
				variables: { 
					input: {
						id: this.marketingConversation.id,
						customer, 
						status: MarketingStatus.CANCELLED,
					} 
				},
				authMode :'AMAZON_COGNITO_USER_POOLS'
			});

			const updatedConversation = JSON.parse(response.data?.putCampaignMarketing?.body);
			
			this.isSuccessful = true;
			this.marketingConversation = updatedConversation;
			this.status = updatedConversation.status
			this.activeCampaign = this.status === MarketingStatus.ACTIVE

		} catch (error) {
			this.isError = true;
		} finally {
			this.isLoading = false;
		}

	}

	*duplicate(conversation){
		try {
			this.isLoading = true;
			this.isError = false;
			
			// 1. Create the new campaign based on another one, including the same metadata
			const response = yield API.graphql({
				query: putCampaignMarketing,
				variables: { 
					input: { 
						customer: conversation.customer, 
						name: `Copy of ${conversation.name}`,
						start_datetime: conversation.start_datetime,
						end_datetime: conversation.end_datetime,
						status: MarketingStatus.DRAFT,
						metadata: JSON.stringify(conversation.metadata),
						contact_file: JSON.stringify(conversation.contact_file), 
						agent_id: conversation.agent_id
					} 
				},
				authMode :'AMAZON_COGNITO_USER_POOLS'
			});
			const newConversation = JSON.parse(response.data?.putCampaignMarketing?.body);
			
			// 2. Find if the campaign to be duplicated contains actions, and pick the first action that is of send_email type
			const action = newConversation.metadata.actions?.filter(action => action.action === 'send_email')[0];

			// 3. If an action is found, will make sure to duplicated its content from the original campaign
			//    instead of using the same exact info such as filenames. Otherwise, duplicated operation will
			//    be done.
			if(action){
				// 4. Find body and template parameters which contains the file name for the email template used by the original campaign
				const bodyFilePath = action.payload.filter(field => field.field === 'body').pop();
				const templateFilePath = action.payload.filter(field => field.field === 'template').pop();
				// 5. Generate a new file name for the soon to be duplicated files
				const filePrefix = `${newConversation.id}/${newConversation.id}`.replaceAll('#', '');

				if(bodyFilePath.value && templateFilePath.value){
					//  6. Duplicate the html and json email template files for the new duplicated campaign
					yield this.duplicateS3File(conversation.customer, bodyFilePath.value, `${filePrefix}.html`, 'text/html');
					yield this.duplicateS3File(conversation.customer, templateFilePath.value, `${filePrefix}.json`, 'application/json');
					
					// 7. Generate and replace new id for the duplicated action
					action.id = uuidv4();
					// 8. Replace the "old" template files with the newly duplicated ones
					action.payload = [
						...action.payload.filter(field => !['body', 'template'].includes(field.field)),
						{field: 'body', value: `${filePrefix}.html` },
						{field: 'template', value: `${filePrefix}.json` },
					]
				}

				// 9. Update the duplicated campaign with the new data
				yield API.graphql({
					query: putCampaignMarketing,
					variables: { 
						input: {
							id: newConversation.id,
							customer: newConversation.customer,
							metadata: JSON.stringify(newConversation.metadata),
						} 
					},
					authMode :'AMAZON_COGNITO_USER_POOLS'
				});

			}

			this.successfulAlert = {
				isOpen: true,
				title: 'Great!',
				message: 'The conversation has been duplicated and is now available as a separate copy!'
			}

		} catch (error) {
			this.successfulAlert = {
				isOpen: true,
				title: 'Oops!',
				message: 'An error occurred while duplicating the campaign!'
			}
		} finally {
			this.isLoading = false;
		}
	}

	*duplicateS3File(customer, file_name, new_file_name, type){
		const response = yield API.graphql({
		  query: getUrlFile,
		  variables: {
			input: {
			  customer,
			  file_name,
			  file_route: 'marketing_conversations',
			},
		  },
		  authMode: 'AMAZON_COGNITO_USER_POOLS'
		});
	
		const getUrl = JSON.parse(response.data.getUrlFile?.body);
		const res = yield axios({ url: getUrl, method: 'GET', responseType: 'blob' })
		const blobFile = new Blob([res.data], {type});

		const responsePut = yield API.graphql({
		  query: getUploadUrlFile,
		  variables: {
			input: {
			  customer,
			  file_name: new_file_name,
			  file_type: type,
			  file_route: 'marketing_conversations',
			  overwrite: true
			},
		  },
		  authMode: 'AMAZON_COGNITO_USER_POOLS'
		});
	
		const uploadUrl = JSON.parse(responsePut.data.getUploadUrlFile?.body);
		const config = {
		  headers: {
			"Content-Type": type,
		  },
		};
	
		yield axios.put(uploadUrl, blobFile, config);

	}

}

export default MarketingStore;
