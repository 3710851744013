import styled from 'styled-components';

const ThreeColumnGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin-bottom: 20px;
    padding: 20px 60px;
    width: 100%;
`;

const AgentsContainer = styled.div`
    margin-bottom: 4%
`;

const InfoBoxWrapper = styled.div`
    margin-left: 10px;
    min-height: 100px;
    align-items: flex-start;
`;

const Title = styled.h3`
    font-size: 18px;
    font-weight: bold;
    margin:0;
`;

const AgentViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 40px;
`;

const DividerLine = styled.div`
    width: 95%;
    height: 1px;
    background-color: ${props => props.theme.colors.lighterGray};
    margin-bottom: 20px;
`;

const DividerLine1 = styled.div`
    width: 95%;
    height: 1px;
    background-color: ${props => props.theme.colors.lighterGray};
    margin: 0 0 3% 0;
`;

const TruncatedDescription = styled.p`
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14px;
    max-height: 60px;
    margin: 3px 0;
`;

const CustomImage = styled.img`
    width: 100px;
    height: auto;
`;

const CustomComponent1 = styled.div`
    position: relative;
    display: flex;
    justify-content: start;
    min-width: 400px;
    background-color: ${(props) => props.theme.colors.primaryWhite};
    padding: 10px;
    cursor: pointer;
    border-radius: 8px;
`;

const LoadMoreContainer = styled.div`
    text-align: -webkit-center;
    margin: 20px 0;
    display: flex;
    justify-content: center;
`;

const TabsContainer = styled.div`
    width: 95%;
    display: flex;
    justify-content: flex-start;
`;

const CardContainer = styled.div`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        margin-top:10px;
    `;


export {
    ThreeColumnGrid, InfoBoxWrapper, Title, AgentViewContainer,
    DividerLine, TruncatedDescription, CustomImage, CustomComponent1, LoadMoreContainer,
    DividerLine1, TabsContainer, AgentsContainer, CardContainer,
};